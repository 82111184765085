<mat-toolbar>
  <form [formGroup]="loggingFiltreForm" novalidate>
    <mat-form-field class="user-form-field" [subscriptSizing]="'dynamic'">
      <mat-chip-grid class="chips-list" formControlName="users" #usersList aria-label="Users selection">
        @for (user of loggingFiltreForm.get('users').value; track user) {
          <mat-chip-row class="chip" [value]="user" (removed)="remove(user)">
            {{ user.fullName }}
            <button matChipRemove>
              <mat-icon svgIcon="ic_clear_24px"></mat-icon>
            </button>
          </mat-chip-row>
        }
        <input
          matInput
          #userInput
          formControlName="userSearch"
          [placeholder]="'users' | translate"
          [matAutocomplete]="auto"
          [matChipInputFor]="usersList"
        />
      </mat-chip-grid>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        @for (user of users; track user) {
          <mat-option [value]="user">
            {{ user.fullName }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field [subscriptSizing]="'dynamic'">
      <input
        matInput
        name="fDate"
        [max]="maxFirstDate"
        formControlName="startDate"
        [matDatepicker]="sDate"
        [placeholder]="'start-date' | translate"
        #firstDate
      />
      @if (loggingFiltreForm.controls['startDate'].hasError('date')) {
        <mat-error> {{ 'date' | translate }} </mat-error>
      }
      <mat-datepicker-toggle matSuffix [for]="sDate"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #sDate></mat-datepicker>

    <mat-form-field [subscriptSizing]="'dynamic'">
      <input
        matInput
        name="lDate"
        [min]="minLastDate"
        [max]="maxLastDate"
        formControlName="endDate"
        [matDatepicker]="eDate"
        [placeholder]="'end-date' | translate"
        #lastDate
      />
      @if (loggingFiltreForm.controls['endDate'].hasError('date')) {
        <mat-error> {{ 'date' | translate }} </mat-error>
      }
      <mat-datepicker-toggle matSuffix [for]="eDate"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #eDate></mat-datepicker>

    <button mat-mini-fab color="accent" (click)="search(loggingFiltreForm.value)">
      <mat-icon svgIcon="ic_search_24px"></mat-icon>
    </button>
  </form>
</mat-toolbar>
