import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';

import { AuxiliaryRouteClass } from '../app-interfaces/close-dialog.interface';
import { AdminService, HandleErrorsService } from '../app-services';
import { Admin } from '../models/admin.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'add-admin-dialog',
  templateUrl: './add-admin-dialog.component.html',
  styleUrls: ['./add-admin-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddAdminDialogComponent extends AuxiliaryRouteClass implements OnInit {
  adminInfo: Admin = null;
  queryParams: any;
  loading = new ReplaySubject<boolean>(1);

  constructor(
    protected snackBar: MatSnackBar,
    protected handleErrors: HandleErrorsService,
    protected dialogRef: MatDialogRef<AddAdminDialogComponent>,
    protected adminService: AdminService,
    protected translateService: TranslateService,
    public router: Router,
    protected route: ActivatedRoute,
  ) {
    super(router);
  }

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;
  }

  getAdminInfo(admin: Admin) {
    this.adminInfo = admin;
  }

  addAdmin() {
    this.loading.next(true);
    this.adminService.addAdmin(this.adminInfo).subscribe({
      next: () => {
        this.translateService.get('admin-message-success').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
        this.leaveDialog(this.queryParams);
      },
      error: err => {
        this.loading.next(false);
        this.handleErrors.handleErrors(err);
      },
    });
  }
}
