import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { isEmpty, omitBy } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { Admin } from '../models/admin.model';
import { QueryEvent } from 'app/models/search-event.model';

@Component({
  selector: 'admin-management-filter',
  templateUrl: './admin-management-filter.component.html',
  styleUrls: ['./admin-management-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminManagementFilterComponent implements OnChanges, OnDestroy {
  @Input() admins: Admin[];
  @Input() searchedAdmins: Admin[];
  @Output() filterAdmins = new EventEmitter();
  @Output() queryAdmins = new EventEmitter<QueryEvent>();
  @Output() addAdmin = new EventEmitter<void>();

  filteredAdmins: Admin[];
  adminFilterForm: FormGroup;

  @ViewChild('adminInput') adminInput: ElementRef<HTMLInputElement>;
  private readonly unsubscribeSubject: Subject<void>;
  constructor() {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.adminFilterForm) {
      this.buildForm();
    }

    if (changes.admins?.currentValue) {
      this.filteredAdmins = this.admins;
    }

    if (changes['searchedAdmins']?.currentValue) {
      this.adminFilterForm.get('admins').setValue(this.searchedAdmins);
      this.searchedAdmins = null;
    }
  }

  private buildForm() {
    this.adminFilterForm = new FormGroup({
      admins: new FormControl(null),
      adminSearch: new FormControl(null),
    });
    this.adminFilterForm
      .get('adminSearch')
      .valueChanges.pipe(
        takeUntil(this.unsubscribeSubject),
        debounceTime(400),
        distinctUntilChanged(),
        filter(search => search?.length > 2),
      )
      .subscribe(search => {
        this.getAdmins(search);
      });
  }

  getAdmins(adminName: string) {
    const admins = this.adminFilterForm.controls['admins'].value || [];
    this.queryAdmins.emit(
      omitBy(
        {
          like: adminName,
          exclude: admins.map(m => m.id),
        },
        isEmpty,
      ),
    );
  }

  search(form: { admins: { id: number; value: string }[] }) {
    this.filterAdmins.emit(form.admins && form.admins.length ? form.admins.map(a => a.id).join(',') : null);
  }

  onAddAdmin() {
    this.addAdmin.emit();
  }
  remove(admin: Admin): void {
    const admins: Admin[] = this.adminFilterForm.get('admins').value;
    this.adminFilterForm.get('admins').setValue(admins.filter(item => item.id !== admin.id));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value: Admin[] = this.adminFilterForm.get('admins').value ?? [];
    this.adminFilterForm.get('admins').setValue([...value, event.option.value]);

    this.adminInput.nativeElement.value = '';
    this.adminFilterForm.get('adminSearch').setValue(null);
    this.filteredAdmins = undefined;
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
