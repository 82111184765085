<mat-toolbar>
  <form [formGroup]="printingFilterForm" novalidate>
    <mat-form-field [subscriptSizing]="'dynamic'">
      <input
        matInput
        [max]="maxFirstDate"
        formControlName="startInterval"
        [matDatepicker]="pickerStart"
        [placeholder]="'start-date' | translate"
      />
      <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #pickerStart></mat-datepicker>

    <mat-form-field [subscriptSizing]="'dynamic'">
      <input
        matInput
        [min]="minLastDate"
        [max]="maxLastDate"
        formControlName="endInterval"
        [matDatepicker]="pickerEnd"
        [placeholder]="'end-date' | translate"
      />
      <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #pickerEnd></mat-datepicker>

    <mat-form-field class="model-form-field" [subscriptSizing]="'dynamic'">
      <mat-chip-grid class="chips-list" formControlName="models" #modelsList aria-label="Models selection">
        @for (model of printingFilterForm.get('models').value; track model) {
          <mat-chip-row class="chip" [value]="model" (removed)="removeModel(model)">
            {{ model.name }}
            <button matChipRemove>
              <mat-icon svgIcon="ic_clear_24px"></mat-icon>
            </button>
          </mat-chip-row>
        }
        <input
          matInput
          [placeholder]="'model' | translate"
          #modelInput
          formControlName="modelSearch"
          [matAutocomplete]="modelAuto"
          [matChipInputFor]="modelsList"
        />
      </mat-chip-grid>
      <mat-autocomplete #modelAuto="matAutocomplete" (optionSelected)="selectedModel($event)">
        @for (model of models; track model) {
          <mat-option [value]="model">
            {{ model.name }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    <button mat-mini-fab color="accent" (click)="search()">
      <mat-icon svgIcon="ic_search_24px"></mat-icon>
    </button>
  </form>
</mat-toolbar>
