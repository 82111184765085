import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '../app-services';
import { Admin } from '../models/admin.model';
import { AvailableLanguages } from '../models/available-languages.const';
import { phoneValidator } from '../validators/phone.validator';
import { Role } from 'app/models/loggin.model';
import { Address } from 'app/models/address.model';

@Component({
  selector: 'admin-detail-profile-form',
  templateUrl: './admin-detail-profile-form.component.html',
  styleUrls: ['./admin-detail-profile-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminDetailProfileFormComponent implements OnChanges, OnDestroy {
  @Input() admin: Admin;
  @Input() canEdit: boolean;
  @Output() adminIsActive = new EventEmitter<Partial<Admin>>();

  adminForm: FormGroup;
  isActiveMessage: string;
  availableLanguages = AvailableLanguages;

  constructor(protected authService: AuthService) {
    this.unsubscribeSubject = new Subject<void>();
  }
  private readonly unsubscribeSubject: Subject<void>;

  ngOnChanges(changes: SimpleChanges) {
    if (!this.adminForm) {
      this.buildForm();
    }

    if (changes.admin?.currentValue) {
      this.adminForm.patchValue(changes.admin.currentValue);
    }

    this.toggleForm();

    this.isActiveMessage = this.admin.isActive ? 'admin-enabled' : 'admin-disabled';
  }

  private buildForm() {
    this.adminForm = new FormGroup({
      fullName: new FormControl(null, Validators.required),
      email: new FormControl(
        null,
        [Validators.required, Validators.email],
        this.authService.isUniqueEmailValidator(Role.ADMIN, this.admin.email),
      ),
      phone: new FormControl(null, [Validators.required, phoneValidator]),
      address: new FormControl(null, Validators.required),
      country: new FormControl(null, Validators.required),
      isActive: new FormControl(null),
      language: new FormControl(null, Validators.required),
      allowedPPF: new FormControl({ value: null, disabled: true }),
      allowedWindows: new FormControl({ value: null, disabled: true }),
    });

    this.adminForm.valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe({
      next: formValue => {
        this.isActiveMessage = this.admin.isActive ? 'administrateur activé' : 'administrateur désactivé';
        this.adminIsActive.emit(formValue);
      },
    });
  }

  private toggleForm() {
    if (this.canEdit) {
      this.adminForm.enable();
      this.adminForm.get('allowedPPF').disable();
      this.adminForm.get('allowedWindows').disable();
    } else {
      this.adminForm.disable();
    }
  }

  updateAddressValue(value: Address) {
    this.adminForm.patchValue(value);
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
