import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelQuery } from 'app/app-services/model.service';
import { UserQuery } from 'app/app-services/users.service';
import { EntityList } from 'app/models/entity-list.model';
import { EMPTY, Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HandleErrorsService, ModelService, PrintingService, UsersService } from '../app-services';
import { Model } from '../models/model.model';
import { ExtendedPrinting, FilterQuery } from '../models/printing.model';
import { User } from '../models/user.model';
import { SearchEvent } from 'app/models/search-event.model';

@Component({
  selector: 'admin-printing-log',
  templateUrl: './admin-printing-log.component.html',
  styleUrls: ['./admin-printing-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminPrintingLogComponent implements OnInit {
  printings = new ReplaySubject<EntityList<ExtendedPrinting>>(1);
  models = new ReplaySubject<Model[]>(1);
  users = new ReplaySubject<User[]>(1);
  offset$ = of(0);
  searchedDates = { endDate: null, startDate: null };
  searchedModels$: Observable<Model[]>;
  searchedUsers$: Observable<User[]>;

  constructor(
    protected printingService: PrintingService,
    protected usersService: UsersService,
    protected modelService: ModelService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected handleErrors: HandleErrorsService,
  ) {}

  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(
        switchMap(([params, queryParams]) => {
          const query = {};

          if (params['page']) {
            query['offset'] = (+params['page'] - 1) * 10;
            this.offset$ = of(+params['page'] - 1);
          }

          Object.keys(queryParams).forEach(k => {
            switch (k) {
              case 'modelIds':
                query['modelIds'] = queryParams['modelIds'].split(',');

                this.searchedModels$ = this.modelService.getModelList({ showParents: true, ids: [queryParams['modelIds']] }).pipe(
                  map(result => result.data.listing),
                  catchError(error => {
                    this.handleErrors.handleErrors(error);

                    return EMPTY;
                  }),
                );
                break;
              case 'userIds':
                query['userIds'] = queryParams['userIds'].split(',');

                this.searchedUsers$ = this.usersService.getUserList({ userIds: query['userIds'] }).pipe(
                  map(result => result.data.listing),
                  catchError(error => {
                    this.handleErrors.handleErrors(error);

                    return EMPTY;
                  }),
                );
                break;

              default:
                query[k] = queryParams[k];
                this.searchedDates[k] = queryParams[k];
                break;
            }
          });

          return this.printingService.getPrintingList(query);
        }),
      )
      .subscribe({
        next: response => {
          this.printings.next(response.data);
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }

  fetchModelList(query: ModelQuery) {
    this.modelService.getModelList(query).subscribe({
      next: result => {
        this.models.next(result.data.listing);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  fetchUserList(query: UserQuery) {
    this.usersService.getUserList(query).subscribe({
      next: res => {
        this.users.next(res.data.listing);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  filter(query: FilterQuery) {
    this.router.navigate(['admin-printing-log'], { queryParams: query });
  }

  pageNavigation(event: SearchEvent) {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate(['admin-printing-log', event.offset + 1], { queryParams });
  }
}
