<form [formGroup]="plotterForm">
  <mat-form-field>
    <mat-label translate="name" />
    <input matInput type="text" formControlName="name" updateOnBlur />
    @if (plotterForm.controls['name'].hasError('required')) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="vendor-Id" />
    <input matInput type="text" formControlName="vendorId" updateOnBlur />
    @if (plotterForm.controls['vendorId'].hasError('required')) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
    @if (plotterForm.controls['vendorId'].hasError('invalidVid')) {
      <mat-error>{{ 'invalid_vid' | translate }} </mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="product-Id" />
    <input matInput type="text" formControlName="productId" updateOnBlur />
    @if (plotterForm.controls['productId'].hasError('required')) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
    @if (plotterForm.controls['productId'].hasError('invalidVid')) {
      <mat-error>{{ 'invalid_pid' | translate }} </mat-error>
    }
  </mat-form-field>
</form>
