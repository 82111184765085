import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { User, UserInfo } from '../models/user.model';

@Component({
  selector: 'editable-user-profile',
  templateUrl: './editable-user-profile.component.html',
  styleUrls: ['./editable-user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableUserProfileComponent {
  @Input() user: User;
  @Input() canEdit: boolean;
  @Output() userUpdate = new EventEmitter<any>();
  @Output() userEdit = new EventEmitter<any>();
  @Output() changeCanceling = new EventEmitter<void>();
  userInfo: UserInfo;

  allowEdit() {
    this.userEdit.emit(true);
  }

  cancelUpdate() {
    this.changeCanceling.emit();
  }

  handleUserInfo(userInfo: UserInfo) {
    this.userInfo = userInfo;
  }

  emitUserUpdate() {
    this.userUpdate.emit(this.userInfo);
  }
}
