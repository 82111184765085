<h2>{{ 'cut-log' | translate }}</h2>
<user-printing-filter
  class="toolbar-body"
  [models]="models | async"
  [searchedDates]="searchedDates"
  [searchedModels]="searchedModels$ | async"
  (filterPrintings)="filterPrintings($event)"
  (queryModels)="fetchModelList($event)"
></user-printing-filter>
<printing-list [printings]="printings | async" [offset]="offset$ | async" (tablePagesNavigation)="pageNavigation($event)"></printing-list>
