import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EntityList } from 'app/models/entity-list.model';
import { ReplaySubject, Subject, EMPTY } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HandleErrorsService, ModelAdminService } from '../app-services';
import { ConfirmDeletionDialogComponent } from '../confirm-deletion-dialog/confirm-deletion-dialog.component';
import { ModelAdmin } from '../models/model-admin.model';

@Component({
  selector: 'model-admin-management',
  templateUrl: './model-admin-management.component.html',
  styleUrls: ['./model-admin-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelAdminManagementComponent implements OnInit, OnDestroy {
  modelAdmins = new ReplaySubject<EntityList<ModelAdmin>>(1);

  private readonly unsubscribeSubject: Subject<void>;

  constructor(
    protected modelAdminService: ModelAdminService,
    protected dialog: MatDialog,
    protected handleErrors: HandleErrorsService,
  ) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
  ngOnInit() {
    this.modelAdminService.update
      .pipe(
        startWith(null),
        switchMap(() => {
          return this.modelAdminService.getModelAdminList().pipe(
            map(result => this.modelAdmins.next(result.data)),
            catchError(err => {
              this.handleErrors.handleErrors(err);

              return EMPTY;
            }),
          );
        }),
      )
      .subscribe();
  }

  deleteModelAdmin(modelAdmin: ModelAdmin) {
    const config: MatDialogConfig = {
      data: { id: modelAdmin.id, type: 'model-admin' },
      width: '50%',
      disableClose: true,
    };
    this.dialog.open(ConfirmDeletionDialogComponent, config);
  }
}
