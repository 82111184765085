import { throwError as observableThrowError } from 'rxjs';

import { Injectable } from '@angular/core';

import { catchError, map } from 'rxjs/operators';
import { AuthService, HandleErrorsService, ModelAdminService } from '../app-services';

@Injectable()
export class CurrentModelAdminResolver {
  constructor(
    protected modelAdminService: ModelAdminService,
    protected authService: AuthService,
    protected handleErrors: HandleErrorsService,
  ) {}

  resolve() {
    const credentials = this.authService.credentials;

    return this.modelAdminService.getModelAdmin(credentials.payload.id).pipe(
      map(result => result.data),
      catchError(err => {
        this.handleErrors.handleErrors(err);

        return observableThrowError(err);
      }),
    );
  }
}
