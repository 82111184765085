import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { AddBrandDialogComponent } from '../add-brand-dialog/add-brand-dialog.component';
import { CloseDialogInterface } from '../app-interfaces/close-dialog.interface';

@Component({
  selector: 'add-brand',
  templateUrl: './add-brand.component.html',
  styleUrls: ['./add-brand.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddBrandComponent implements OnInit, CloseDialogInterface {
  dialogRef: any;

  constructor(protected dialog: MatDialog) {}

  ngOnInit() {
    const config: MatDialogConfig = { width: '60%', disableClose: true };
    this.dialogRef = this.dialog.open(AddBrandDialogComponent, config);
  }

  closeDialog() {
    this.dialogRef.close();

    return true;
  }
}
