<h2>{{ 'brand-management' | translate }}</h2>
<brand-filter
  [brands]="brandsAutoComplete | async"
  [brandName]="brandName$ | async"
  (filterBrands)="filterBrands($event)"
  (fetchBrands)="fetchBrands($event)"
  (addBrand)="navigate()"
></brand-filter>
<brand-list
  [brands]="brands | async"
  [offset]="offset$ | async"
  (brandDelete)="deleteBrand($event)"
  (tablePagesNavigation)="pageNavigation($event)"
  (routeNavigate)="navigate($event)"
></brand-list>
