<mat-toolbar>
  <form [formGroup]="form" novalidate>
    <mat-form-field class="search-form-field" [subscriptSizing]="'dynamic'">
      <mat-chip-grid class="chips-list" formControlName="users" #usersList aria-label="Users selection">
        @for (user of form.get('users').value; track user) {
          <mat-chip-row class="chip" [value]="user" (removed)="removeUser(user)">
            {{ user.fullName }}
            <button matChipRemove>
              <mat-icon svgIcon="ic_clear_24px"></mat-icon>
            </button>
          </mat-chip-row>
        }
        <input
          matInput
          #userInput
          formControlName="userSearch"
          [placeholder]="'users' | translate"
          [matAutocomplete]="userAuto"
          [matChipInputFor]="usersList"
        />
      </mat-chip-grid>
      <mat-autocomplete #userAuto="matAutocomplete" (optionSelected)="selectedUser($event)">
        @for (user of userList; track user) {
          <mat-option [value]="user">
            {{ user.fullName }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="search-form-field" [subscriptSizing]="'dynamic'">
      <mat-chip-grid class="chips-list" formControlName="models" #modelsList aria-label="Models selection">
        @for (model of form.get('models').value; track model) {
          <mat-chip-row class="chip" [value]="model" (removed)="removeModel(model)">
            {{ model.name }}
            <button matChipRemove>
              <mat-icon svgIcon="ic_clear_24px"></mat-icon>
            </button>
          </mat-chip-row>
        }
        <input
          matInput
          [placeholder]="'models' | translate"
          #modelInput
          formControlName="modelSearch"
          [matAutocomplete]="modelauto"
          [matChipInputFor]="modelsList"
        />
      </mat-chip-grid>
      <mat-autocomplete #modelauto="matAutocomplete" (optionSelected)="selectedModel($event)">
        @for (model of modelList; track model) {
          <mat-option [value]="model">
            {{ model.name }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field [subscriptSizing]="'dynamic'">
      <input
        matInput
        name="fDate"
        [max]="maxFirstDate"
        formControlName="startDate"
        [matDatepicker]="sDate"
        [placeholder]="'start-date' | translate"
        #firstDate
      />
      @if (form.controls['startDate'].hasError('date')) {
        <mat-error> {{ 'date' | translate }} </mat-error>
      }
      <mat-datepicker-toggle matSuffix [for]="sDate"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #sDate></mat-datepicker>

    <mat-form-field [subscriptSizing]="'dynamic'">
      <input
        matInput
        name="lDate"
        [min]="minLastDate"
        [max]="maxLastDate"
        formControlName="endDate"
        [matDatepicker]="eDate"
        [placeholder]="'end-date' | translate"
        #lastDate
      />
      @if (form.controls['endDate'].hasError('date')) {
        <mat-error> {{ 'date' | translate }} </mat-error>
      }
      <mat-datepicker-toggle matSuffix [for]="eDate"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #eDate></mat-datepicker>

    <button mat-mini-fab color="accent" (click)="search()">
      <mat-icon svgIcon="ic_search_24px"></mat-icon>
    </button></form
></mat-toolbar>
