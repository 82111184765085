@if (!disabled) {
  <h1 mat-dialog-title>{{ 'delete-serie-error' | translate }}:</h1>
} @else {
  <h1 mat-dialog-title>{{ 'confirm-delete-serie' | translate }}?</h1>
}

<mat-dialog-content>
  <delete-serie-dialog-dumb [modelList]="modelList | async"></delete-serie-dialog-dumb>
</mat-dialog-content>
<mat-dialog-actions>
  @if (loading | async) {
    <mat-spinner></mat-spinner>
  }
  <button mat-raised-button color="warn" [disabled]="!disabled || (loading | async)" (click)="deleteSerie()">
    {{ 'delete' | translate }}
  </button>
  <button mat-raised-button color="accent" (click)="cancel()">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
