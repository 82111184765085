<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  <a mat-tab-link [routerLink]="['admin-profile']" routerLinkActive #profileLink="routerLinkActive" [active]="profileLink.isActive">{{
    'profile' | translate
  }}</a>
  <spinner [route]="'/admin-details/' + adminDetails.id + '/admin-profile'"></spinner>
  <a mat-tab-link [routerLink]="['admin-users-list']" routerLinkActive #usersLink="routerLinkActive" [active]="usersLink.isActive">{{
    'users-list' | translate
  }}</a>
  <spinner [route]="'/admin-details/' + adminDetails.id + '/admin-profileadmin-users-list'"></spinner>
  <a
    [disabled]="adminDetails.country !== 'DZ'"
    mat-tab-link
    [routerLink]="['credit']"
    routerLinkActive
    #creditLink="routerLinkActive"
    [active]="creditLink.isActive"
    >{{ 'credit' | translate }}</a
  >
  <spinner [route]="'/admin-details/' + adminDetails.id + '/credit'"></spinner>
  <a mat-tab-link [routerLink]="['banner']" routerLinkActive #bannerLink="routerLinkActive" [active]="bannerLink.isActive">{{
    'banner' | translate
  }}</a>
  <spinner [route]="'/admin-details/' + adminDetails.id + '/banner'"></spinner>
</nav>

<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>
