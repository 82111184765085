import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { AddSerieDialogComponent } from '../add-serie-dialog/add-serie-dialog.component';
import { CloseDialogInterface } from '../app-interfaces/close-dialog.interface';

@Component({
  selector: 'add-serie',
  templateUrl: './add-serie.component.html',
  styleUrls: ['./add-serie.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSerieComponent implements OnInit, CloseDialogInterface {
  dialogRef: any;

  constructor(protected dialog: MatDialog) {}

  ngOnInit() {
    const config: MatDialogConfig = { width: '60%', disableClose: true };
    this.dialogRef = this.dialog.open(AddSerieDialogComponent, config);
  }

  closeDialog() {
    this.dialogRef.close();

    return true;
  }
}
