import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FilterQuery } from 'app/models/loggin.model';
import { QueryEvent } from 'app/models/search-event.model';
import { User } from 'app/models/user.model';
import { isEmpty, omitBy } from 'lodash';
import { Subject, debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'admin-credit-log-filter',
  templateUrl: './admin-credit-log-filter.component.html',
  styleUrls: ['./admin-credit-log-filter.component.scss'],
})
export class AdminCreditLogFilterComponent implements OnChanges, OnDestroy {
  @Input() searchedDatas: FilterQuery;
  @Input() users: User[];
  @Input() searchedUsers: User[];
  @Output() filterQuery = new EventEmitter<FilterQuery>();
  @Output() queryUsers = new EventEmitter<QueryEvent>();
  loggingFiltreForm: FormGroup;
  maxFirstDate = new Date();
  minLastDate: Date = null;
  maxLastDate = new Date();

  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  private readonly unsubscribeSubject: Subject<void>;
  constructor() {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.loggingFiltreForm) {
      this.buildForm();
    }

    if (changes['searchedUsers'] && changes['searchedUsers'].previousValue === null && changes['searchedUsers'].currentValue) {
      this.loggingFiltreForm.get('users').setValue(this.searchedUsers);
      this.searchedUsers = null;
    }

    if (!isEmpty(changes['searchedDatas']?.currentValue)) {
      this.loggingFiltreForm.get('endDate').setValue(this.searchedDatas['endDate'] ? new Date(+this.searchedDatas['endDate']) : null);
      this.loggingFiltreForm.get('startDate').setValue(this.searchedDatas['startDate'] ? new Date(+this.searchedDatas['startDate']) : null);
    }
  }

  private buildForm() {
    this.loggingFiltreForm = new FormGroup({
      users: new FormControl(null),
      userSearch: new FormControl(null),
      startDate: new FormControl(null),
      endDate: new FormControl(null),
    });

    this.loggingFiltreForm
      .get('userSearch')
      .valueChanges.pipe(
        takeUntil(this.unsubscribeSubject),
        debounceTime(400),
        distinctUntilChanged(),
        filter(user => user?.length > 2),
      )
      .subscribe(userSearch => {
        this.getUsers(userSearch);
      });

    this.loggingFiltreForm.controls['startDate'].valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe({
      next: fDate => {
        this.minLastDate = fDate;
      },
    });

    this.loggingFiltreForm.controls['endDate'].valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe({
      next: lDate => {
        this.maxFirstDate = lDate;
      },
    });
  }

  getUsers(userName: string) {
    const users = this.loggingFiltreForm.controls['users'].value || [];
    this.queryUsers.emit(
      omitBy(
        {
          like: userName,
          exclude: users.map(m => m.id),
        },
        isEmpty,
      ),
    );
  }

  search(form: { users: { id: number; value: string }[] }) {
    const users = form.users?.length > 0 ? form.users.map(a => a.id).join(',') : null;
    const startDate = this.loggingFiltreForm.controls['startDate'].value;
    const endDate = this.loggingFiltreForm.controls['endDate'].value;
    this.filterQuery.emit({
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
      userIds: users,
    });
  }

  remove(user: User): void {
    const users: User[] = this.loggingFiltreForm.get('users').value;
    this.loggingFiltreForm.get('users').setValue(users.filter(item => item.id !== user.id));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value: User[] = this.loggingFiltreForm.get('users').value ?? [];
    this.loggingFiltreForm.get('users').setValue([...value, event.option.value]);
    this.userInput.nativeElement.value = '';
    this.loggingFiltreForm.get('userSearch').setValue(null);
    this.users = undefined;
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
