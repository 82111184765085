import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EntityList, Messages } from 'app/models/entity-list.model';
import { ExtendedPrintableData, PrintableData } from '../models/model.model';
import { MagicTranslateService } from 'app/app-services';
import { SearchEvent } from 'app/models/search-event.model';

@Component({
  selector: 'printable-data-list',
  templateUrl: './printable-data-list.component.html',
  styleUrls: ['./printable-data-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintableDataListComponent implements OnInit {
  @Input() printableDataList: EntityList<ExtendedPrintableData>;
  @Input() offset: number;

  @Output() tablePagesNavigation = new EventEmitter();
  @Output() routeNavigate = new EventEmitter();
  @Output() openDeletePrintableDataDialog = new EventEmitter();

  messages$: Messages;

  constructor(private readonly magicTranslateService: MagicTranslateService) {}

  ngOnInit() {
    this.messages$ = this.magicTranslateService.getMessage();
  }

  handlePages(event: SearchEvent) {
    this.tablePagesNavigation.emit(event);
  }

  navigate(printableDataId: number) {
    this.routeNavigate.emit(printableDataId);
  }

  deletePrintableData(printableData: PrintableData) {
    this.openDeletePrintableDataDialog.emit(printableData.id);
  }
}
