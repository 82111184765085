import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Brand } from 'app/models/brand.model';
import { Subject, filter } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'brand-filter',
  templateUrl: './brand-filter.component.html',
  styleUrls: ['./brand-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandFilterComponent implements OnChanges, OnDestroy {
  @Input() brands: Brand[];
  @Input() brandName: string;

  @Output() filterBrands = new EventEmitter();
  @Output() fetchBrands = new EventEmitter();
  @Output() addBrand = new EventEmitter<void>();

  brandFilterForm: FormGroup;

  private readonly unsubscribeSubject: Subject<void>;

  constructor() {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.brandFilterForm) {
      this.buildForm();
    }

    if (changes['brandName']?.currentValue) {
      this.brandFilterForm.get('brand').setValue(this.brandName);
    }
  }

  getBrands(brand: AbstractControl) {
    if (!brand.value) {
      this.fetchBrands.emit(null);
    }
  }

  buildForm() {
    this.brandFilterForm = new FormGroup({
      brand: new FormControl(null),
    });

    this.brandFilterForm
      .get('brand')
      .valueChanges.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        takeUntil(this.unsubscribeSubject),
        filter(searchName => searchName?.length > 1),
      )
      .subscribe(brandName => {
        this.fetchBrands.emit({ brandName: brandName.trim() });
      });
  }

  search() {
    const brand = this.brandFilterForm.controls['brand'].value;
    this.brands = undefined;
    this.filterBrands.emit(brand?.trim());
  }

  onAddBrand() {
    this.addBrand.emit();
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
