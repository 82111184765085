import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MagicTranslateService } from './app-services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private magicTranslateService: MagicTranslateService,
  ) {
    const prefix = 'mat-icons/svg-sprite-';

    [
      'action',
      'alert',
      'av',
      'communication',
      'content',
      'device',
      'editor',
      'file',
      'hardware',
      'image',
      'maps',
      'navigation',
      'notification',
      'places',
      'social',
      'toggle',
    ].map(url => iconRegistry.addSvgIconSet(sanitizer.bypassSecurityTrustResourceUrl(prefix + url + '.svg')));
  }

  ngOnInit() {
    this.magicTranslateService.initLanguage();
    const preloader = document.querySelector('.preloader');
    if (preloader && preloader.parentNode) {
      preloader.parentNode.removeChild(preloader);
    }
  }
}
