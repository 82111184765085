import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReplaySubject } from 'rxjs';
import { HandleErrorsService, ModelService } from '../app-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'delete-model-dialog',
  templateUrl: './delete-model-dialog.component.html',
  styleUrls: ['./delete-model-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteModelDialogComponent {
  loading = new ReplaySubject<boolean>(1);

  constructor(
    protected modelService: ModelService,
    protected snackBar: MatSnackBar,
    protected dialogRef: MatDialogRef<DeleteModelDialogComponent>,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public modelId: number,
  ) {}

  deleteModel() {
    this.loading.next(true);
    this.modelService.deleteModel(this.modelId).subscribe({
      next: () => {
        this.translateService.get('model-delete-success').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
        this.dialogRef.close();
      },
      error: err => {
        this.loading.next(false);
        this.handleErrors.handleErrors(err);
      },
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
