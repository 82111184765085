import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { AdminService, HandleErrorsService, MagicTranslateService } from '../app-services';
import { Admin } from '../models/admin.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'admin-profil-tab',
  templateUrl: './admin-profil-tab.component.html',
  styleUrls: ['./admin-profil-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminProfilTabComponent implements OnInit {
  admin: Admin;
  canEdit = new Subject<boolean>();
  loading = new ReplaySubject<boolean>(1);
  adminInfo: Partial<Admin>;

  constructor(
    protected adminService: AdminService,
    protected snackBar: MatSnackBar,
    protected route: ActivatedRoute,
    protected handleErrors: HandleErrorsService,
    private magicTranslateService: MagicTranslateService,
    protected translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.admin = this.route.parent.snapshot.data['adminDetails'];
    this.canEdit.pipe(startWith(false));
  }

  allowEdit() {
    this.canEdit.next(true);
  }

  handleAdminInfo(adminInfo: Partial<Admin>) {
    this.adminInfo = adminInfo;
  }

  updateAdmin() {
    this.loading.next(true);
    if (this.adminInfo) {
      this.adminService
        .updateAdmin({
          ...this.adminInfo,
          id: this.admin.id,
        })
        .subscribe({
          next: () => {
            this.magicTranslateService.updateUserLanguage(this.adminInfo.language);
            this.translateService.get('admin-update-success').subscribe(message => {
              this.snackBar.open(message, 'OK', { duration: 7000 });
            });
            this.canEdit.next(false);
          },
          error: err => {
            this.handleErrors.handleErrors(err);
          },
          complete: () => {
            this.loading.next(false);
          },
        });
    }
  }

  cancelUpdate() {
    this.admin = Object.assign({}, this.admin);
    this.canEdit.next(false);
    // reminder used object assign to force detect change
  }
}
