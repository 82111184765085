<h2>{{ 'credit-log' | translate }}</h2>
<admin-credit-log-filter
  class="toolbar-body"
  [users]="userList | async"
  [searchedUsers]="searchedUsers$ | async"
  [searchedDatas]="searchedDatas$ | async"
  (queryUsers)="fetchUsers($event)"
  (filterQuery)="filterLogging($event)"
></admin-credit-log-filter>
<admin-credit-log-list [loggings]="loggings | async" [offset]="offset$ | async" (submitFilterOptions)="pageNavigation($event)">
</admin-credit-log-list>
