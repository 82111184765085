import { throwError as observableThrowError } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { AdminService, HandleErrorsService } from '../app-services';

@Injectable()
export class AdminDetailsResolver {
  constructor(
    protected adminService: AdminService,
    protected handleErrors: HandleErrorsService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.adminService.getAdmin(+route.params['id']).pipe(
      map(res => ({
        ...res.data,
        allowedPPF: Number(res.data.allowedPPF).toFixed(2),
        allowedWindows: Number(res.data.allowedWindows).toFixed(2),
      })),
      catchError(err => {
        this.handleErrors.handleErrors(err);

        return observableThrowError(err);
      }),
    );
  }
}
