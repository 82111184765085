<h1 mat-dialog-title>{{ 'cut-pieces' | translate }}</h1>
<mat-dialog-content>
  <printing-visualisation-view
    [printedPieces]="printing[0].printedPieces"
    [printableData]="printableData | async"
  ></printing-visualisation-view>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="accent" (click)="leaveDialog(queryParams)">{{ 'close' | translate }}</button>
</mat-dialog-actions>
