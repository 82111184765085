import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { AuthService, HandleErrorsService, MagicTranslateService, UsersService } from '../app-services';
import { User, UserInfo } from '../models/user.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent implements OnInit {
  user: User;
  canEdit = new Subject<boolean>();

  constructor(
    protected usersService: UsersService,
    protected snackBar: MatSnackBar,
    public authService: AuthService,
    protected route: ActivatedRoute,
    protected handleErrors: HandleErrorsService,

    protected translateService: TranslateService,
    private magicTranslateService: MagicTranslateService,
  ) {}

  ngOnInit() {
    this.user = this.route.parent.snapshot.data['userDetails'];
    this.canEdit.pipe(startWith(false));
  }

  allowEdit(state: boolean) {
    this.canEdit.next(state);
  }

  updateUser(userInfo: UserInfo) {
    if (userInfo) {
      this.usersService.updateUser(this.user.id, userInfo).subscribe({
        next: result => {
          this.magicTranslateService.updateUserLanguage(userInfo.language);
          this.translateService.get('user-update-success').subscribe(message => {
            this.snackBar.open(message, 'OK', { duration: 7000 });
          });
          this.canEdit.next(false);
          this.user = Object.assign({}, result.data); // tempo
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
    }
  }

  cancelUpdate() {
    this.user = Object.assign({}, this.user);
    this.canEdit.next(false);
  }
}
