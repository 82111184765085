<h1 mat-dialog-title>{{ 'pay-card' | translate }}</h1>
<mat-dialog-content c class="dialog-content">
  @if (loading$ | async) {
    <div class="spinner">
      <magic-cut-spinner></magic-cut-spinner>
    </div>
  }
  <form id="payment-form">
    <div [hidden]="loading$ | async">
      <div id="link-authentication-element">
        <!-- Elements will create authentication element here -->
      </div>
      <div id="payment-element">
        <!-- Elements will create form elements here -->
      </div>
      <div id="payment-amount" *ngIf="paymentFormValid$ | async">
        <mat-label translate="total" />
        : {{ paymentIntent?.amount / 100 | currency: (paymentIntent?.currency | uppercase) : true }}
      </div>
      <div id="payment-message" *ngIf="errorMessage$">
        {{ errorMessage$ | async | translate }}
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="leave()">{{ 'cancel' | translate }}</button>
  <button
    (click)="pay()"
    id="payment-button"
    mat-raised-button
    color="primary"
    [disabled]="(loading$ | async) || !(paymentFormValid$ | async)"
  >
    <span id="button-text">{{ 'pay' | translate }}</span>
  </button>
</mat-dialog-actions>
