import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Admin, adminCreditUpdate } from 'app/models/admin.model';
import { Subject, takeUntil } from 'rxjs';
import { omitBy } from 'lodash';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'admin-credit-form',
  templateUrl: './admin-credit-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminCreditFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() admin: Admin;

  @Output() creditUpdate = new EventEmitter<adminCreditUpdate>();

  creditForm: FormGroup;

  private readonly unsubscribeSubject: Subject<void>;
  constructor(@Inject(LOCALE_ID) private readonly locale: string) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.admin?.isFirstChange()) {
      const newAdmin: Admin = changes.admin.currentValue;
      const allowedPPF = this.formatNumber(newAdmin.allowedPPF);
      const allowedWindows = this.formatNumber(newAdmin.allowedWindows);
      this.creditForm.patchValue({
        allowedPPFExtra: 0,
        allowedPPF,
        allowdPPFFinal: allowedPPF,
        allowedWindows,
        allowedWindowsExtra: 0,
        allowedWindowsFinal: allowedWindows,
      });
    }
  }

  ngOnInit() {
    this.buildForm();
  }

  private formatNumber(number: number): string {
    return formatNumber(number, this.locale, '1.2-2');
  }

  private buildForm() {
    this.creditForm = new FormGroup({
      allowedPPF: new FormControl({ value: this.formatNumber(this.admin.allowedPPF), disabled: true }),
      allowedPPFExtra: new FormControl(0, [Validators.required, Validators.min(-this.admin.allowedPPF)]),
      allowedPPFFinal: new FormControl({ value: this.formatNumber(this.admin.allowedPPF), disabled: true }),
      allowedWindows: new FormControl({ value: this.formatNumber(this.admin.allowedWindows), disabled: true }),
      allowedWindowsExtra: new FormControl(0, [Validators.required, Validators.min(-this.admin.allowedWindows)]),
      allowedWindowsFinal: new FormControl({ value: this.formatNumber(this.admin.allowedWindows), disabled: true }),
    });

    this.creditForm
      .get('allowedPPFExtra')
      .valueChanges.pipe(takeUntil(this.unsubscribeSubject))
      .subscribe({
        next: () => {
          this.addForms({ initial: 'allowedPPF', extra: 'allowedPPFExtra', final: 'allowedPPFFinal' });
        },
      });
    this.creditForm
      .get('allowedWindowsExtra')
      .valueChanges.pipe(takeUntil(this.unsubscribeSubject))
      .subscribe({
        next: () => {
          this.addForms({ initial: 'allowedWindows', extra: 'allowedWindowsExtra', final: 'allowedWindowsFinal' });
        },
      });
  }

  addForms(formsKey: { initial: string; extra: string; final: string }) {
    const initialValue: number = parseFloat(this.creditForm.get(formsKey.initial).value);
    const extraValue: number = parseFloat(this.creditForm.get(formsKey.extra).value);
    const finalValue = extraValue + initialValue;
    this.creditForm.get(formsKey.final).setValue(this.formatNumber(finalValue));
  }

  save() {
    const extraPPF = this.creditForm.get('allowedPPFExtra').value;
    const extraWindows = this.creditForm.get('allowedWindowsExtra').value;
    this.creditUpdate.emit(omitBy({ extraPPF, extraWindows }, (value: number) => value === 0));
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
