<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  @for (link of links; track link) {
    <a mat-tab-link [routerLink]="link.url" routerLinkActive #activeLink="routerLinkActive" [active]="activeLink.isActive">
      {{ link.label | translate }}
    </a>
  }
</nav>

<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>
