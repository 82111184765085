<mat-toolbar>
  <form [formGroup]="printingFilterForm" novalidate>
    <mat-form-field class="search-form-field" [subscriptSizing]="'dynamic'">
      <mat-chip-grid class="chips-list" formControlName="admins" #adminsList aria-label="Admins selection">
        @for (admin of printingFilterForm.get('admins').value; track admin) {
          <mat-chip-row class="chip" [value]="admin" (removed)="removeAdmin(admin)">
            {{ admin.fullName }}
            <button matChipRemove>
              <mat-icon svgIcon="ic_clear_24px"></mat-icon>
            </button>
          </mat-chip-row>
        }
        <input
          matInput
          #adminInput
          formControlName="adminSearch"
          [placeholder]="'admins' | translate"
          [matAutocomplete]="adminAuto"
          [matChipInputFor]="adminsList"
        />
      </mat-chip-grid>
      <mat-autocomplete #adminAuto="matAutocomplete" (optionSelected)="selectedAdmin($event)">
        @for (admin of adminList; track admin) {
          <mat-option [value]="admin">
            {{ admin.fullName }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="search-form-field" [subscriptSizing]="'dynamic'">
      <mat-chip-grid class="chips-list" formControlName="models" #modelsList aria-label="Models selection">
        @for (model of printingFilterForm.get('models').value; track model) {
          <mat-chip-row class="chip" [value]="model" (removed)="removeModel(model)">
            {{ model.name }}
            <button matChipRemove>
              <mat-icon svgIcon="ic_clear_24px"></mat-icon>
            </button>
          </mat-chip-row>
        }
        <input
          matInput
          #modelInput
          formControlName="modelSearch"
          [placeholder]="'models' | translate"
          [matAutocomplete]="modelAuto"
          [matChipInputFor]="modelsList"
        />
      </mat-chip-grid>
      <mat-autocomplete #modelAuto="matAutocomplete" (optionSelected)="selectedModel($event)">
        @for (model of models; track model) {
          <mat-option [value]="model">
            {{ model.name }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="form-field" [subscriptSizing]="'dynamic'">
      <input
        matInput
        [max]="maxFirstDate"
        [matDatepicker]="startPicker"
        [placeholder]="'start-date' | translate"
        formControlName="startDate"
      />
      <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
    </mat-form-field>

    <mat-datepicker #startPicker></mat-datepicker>

    <mat-form-field class="form-field" [subscriptSizing]="'dynamic'">
      <input
        matInput
        [min]="minLastDate"
        [max]="maxLastDate"
        [matDatepicker]="endPicker"
        [placeholder]="'end-date' | translate"
        formControlName="endDate"
      />
      <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #endPicker></mat-datepicker>

    <button mat-mini-fab color="accent" (click)="search()">
      <mat-icon svgIcon="ic_search_24px"></mat-icon>
    </button>
  </form>
</mat-toolbar>
