import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ModelAdmin } from '../models/model-admin.model';
import { AvailableLanguages } from 'app/models/available-languages.const';

@Component({
  selector: 'model-admin-profile-form',
  templateUrl: './model-admin-profile-form.component.html',
  styleUrls: ['./model-admin-profile-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelAdminProfileFormComponent implements OnChanges {
  @Input() loading: boolean;
  @Input() modelAdmin: ModelAdmin;
  @Output() modelAdminInfoSubmit = new EventEmitter<any>();
  @Output() changeCanceling = new EventEmitter<void>();
  disabled = false;

  modelAdminform: FormGroup;
  availableLanguages = AvailableLanguages;

  ngOnChanges(changes: SimpleChanges) {
    if (!this.modelAdminform) {
      this.buildForm();
    }

    this.disabled = this.loading === false ? true : false;

    if (changes.modelAdmin?.currentValue) {
      Object.keys(changes.modelAdmin?.currentValue)
        .filter(f => f !== 'id' && f !== 'password' && f !== '$$index')
        .map(m => this.modelAdminform.get(m).setValue(changes.modelAdmin.currentValue[m]));
    }
  }

  buildForm() {
    this.modelAdminform = new FormGroup({
      fullName: new FormControl({ value: null, disabled: true }, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      language: new FormControl(null, Validators.required),
    });
  }

  submitForm() {
    this.modelAdminInfoSubmit.emit({
      fullName: this.modelAdminform.get('fullName').value,
      email: this.modelAdminform.get('email').value,
      language: this.modelAdminform.get('language').value,
    });
  }

  cancel() {
    this.changeCanceling.emit();
  }
}
