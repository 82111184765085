import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  getApiUrl(): string {
    return `${environment.apiHost}/uploads/`;
  }
  getApiBanner(): string {
    return `${environment.apiHost}/uploads/banner/`;
  }
}
