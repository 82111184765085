import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Plotter, PlotterInfo, PlotterQuery } from '../models/plotter.model';
import { map } from 'rxjs/operators';

@Injectable()
export class PlotterService {
  update = new Subject<{ operation: string; value: Plotter[] }>();
  constructor(private _http: HttpClient) {}

  getPlottersList(query: PlotterQuery = {}): Observable<Response> {
    const route = {
      name: 'plotter-name',
      vendorId: 'vendor-id',
      productId: 'product-id',
      offset: 'offset',
      limit: 'limit',
      exclude: 'exclude',
      next: 'next',
    };

    const questionMark = Object.keys(query).length > 0 ? '?' : '';
    const url =
      '/api/plotter-brand/list' +
      questionMark +
      Object.keys(query)
        .map(k =>
          Array.isArray(query[k]) ? query[k].map((value, index) => `${route[k]}[${index}]=${value}`).join('&') : `${route[k]}=${query[k]}`,
        )
        .join('&');

    return this._http.get(url, { observe: 'response' }).pipe(
      map((res: HttpResponse<Response>) => {
        return res.body;
      }),
    );
  }

  addPlotter(newPlotter: PlotterInfo): Observable<{ statusCode: number; data: Plotter }> {
    return this._http.post('/api/plotter-brand', newPlotter, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: Plotter; statusCode: number }>) => {
        this.update.next({ operation: 'addPlotter', value: [res.body.data] });

        return res.body;
      }),
    );
  }

  deletePlotter(plotterId: number): Observable<{ statusCode: number }> {
    return this._http.delete('/api/plotter-brand/' + plotterId, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number }>) => {
        this.update.next({ operation: 'deletePlotter', value: [] });

        return { statusCode: res.body.statusCode };
      }),
    );
  }
}

export type Response = {
  statusCode: number;
  data: {
    listing: Plotter[];
    total: number;
  };
};
