import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentDocument, StripeKey } from 'app/models/payment-document.model';
import { Observable, Subject, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  update = new Subject<{ operation: string; value: PaymentDocument[] }>();

  constructor(private _http: HttpClient) {}

  retriveClientSecret(paymentDocumentId: number): Observable<{ statusCode: number; data: StripeKey }> {
    return this._http.get('/api/payment-intents/' + paymentDocumentId, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number; data: StripeKey }>) => {
        return res.body;
      }),
    );
  }

  getStripePublishableKey(): Observable<{ statusCode: number; data: StripeKey }> {
    return this._http.get('/api/stripeConfig', { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number; data: StripeKey }>) => {
        return res.body;
      }),
    );
  }
}
