import { throwError as observableThrowError } from 'rxjs';

import { Injectable } from '@angular/core';

import { catchError, map } from 'rxjs/operators';
import { AdminService, AuthService, HandleErrorsService } from '../app-services';

@Injectable()
export class CurrentAdminDataResolver {
  constructor(
    protected adminService: AdminService,
    protected authService: AuthService,
    protected handleErrors: HandleErrorsService,
  ) {}

  resolve() {
    const credentials = this.authService.credentials;

    return this.adminService.getAdmin(credentials.payload.id).pipe(
      map(res => ({
        ...res.data,
        allowedPPF: Number(res.data.allowedPPF).toFixed(2),
        allowedWindows: Number(res.data.allowedWindows).toFixed(2),
      })),
      catchError(err => {
        this.handleErrors.handleErrors(err);

        return observableThrowError(() => err);
      }),
    );
  }
}
