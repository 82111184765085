@if (disabled) {
  <h1 mat-dialog-title>{{ 'delete-brand-error' | translate }}:</h1>
} @else {
  <h1 mat-dialog-title>{{ 'confirm-delete-brand' | translate }}?</h1>
}

<mat-dialog-content>
  <delete-brand-dialog-dumb [serieList]="serieList | async"></delete-brand-dialog-dumb>
</mat-dialog-content>
<mat-dialog-actions>
  @if (loading | async) {
    <mat-spinner></mat-spinner>
  }
  <button mat-raised-button [disabled]="disabled || (loading | async)" color="warn" (click)="confirm()">{{ 'confirm' | translate }}</button>
  <button mat-raised-button color="accent" (click)="cancel()">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
