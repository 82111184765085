import { Observable, from, throwError as observableThrowError } from 'rxjs';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthStateService } from './auth-state.service';
import { environment } from 'environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(
    private authState: AuthStateService,
    private authService: AuthService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('i18n') || request.url.includes('mat-icons')) {
      return next.handle(request);
    }

    if (!this.authState.noAuthEndPoint.find(e => !!request.url.match(new RegExp(`^${e}`)))) {
      const encodedUri = encodeURI(request.url).replace(/%5B/g, '[').replace(/%5D/g, ']').replace(/'/g, '%27').replace('+', '%2B');

      request = request.clone({
        url: `${environment.apiHost}:${encodedUri}`,
        setHeaders: {
          Authorization: encodedUri.includes('refresh-token')
            ? `Bearer ${this.authState.credentials.refreshToken}`
            : `Bearer ${this.authState.credentials.token}`,
        },
      });
    } else {
      request = request.clone({
        url: environment.apiHost + request.url,
      });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: {
              data: event.body,
              statusCode: event.status,
            },
          });
        }

        return event;
      }),
      catchError(err => {
        if (err.status === 401) {
          if (err.error.message === 'Authorization token expired') {
            return this.refreshToken(request, next);
          } else {
            this.authState.clearCredentials();

            return observableThrowError(() => ({ statusCode: err.status, data: { error: err.error } }));
          }
        }
      }),
    );
  }

  refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.authService.refreshToken()).pipe(
      switchMap(res => {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + res.data.token,
          },
        });

        return next.handle(request).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              return event.clone({
                body: {
                  data: event.body,
                  statusCode: event.status,
                },
              });
            }

            return event;
          }),
        );
      }),
      catchError(err => {
        if (err.status === 403) {
          this.authState.clearCredentials();
        }

        return observableThrowError(() => ({ statusCode: err.status, data: { error: err.error } }));
      }),
    );
  }
}
