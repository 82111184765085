<h2>{{ 'serie-management' | translate }}</h2>
<serie-filter
  [series]="serieListComplete | async"
  [brands]="brandList | async"
  [searchTerms]="searchTerms"
  (filterSeries)="filterSeries($event)"
  (fetchBrands)="fetchBrands($event)"
  (fetchSeries)="fetchSeries($event)"
  (routeNavigate)="navigate($event)"
></serie-filter>
<serie-list
  [serieList]="serieList | async"
  [offset]="offset$ | async"
  (openDeleteSerieDialog)="openDeleteSerieDialog($event)"
  (tablePagesNavigation)="pageNavigation($event)"
  (routeNavigate)="navigate($event)"
></serie-list>
