<h2>{{ 'admins-management' | translate }}</h2>
<admin-management-filter
  [admins]="adminListCompleteList | async"
  (filterAdmins)="filterAdmins($event)"
  [searchedAdmins]="searchedAdmins$ | async"
  (filterAdmins)="filterAdmins($event)"
  (queryAdmins)="fetchAdmins($event)"
  (addAdmin)="onAddAdminClick()"
></admin-management-filter>
<admin-list
  [adminList]="adminList | async"
  [offset]="offset$ | async"
  (setActivation)="setActivation($event)"
  (openDeleteAdminDialog)="openDeleteAdminDialog($event)"
  (submitFilterOptions)="pageNavigation($event)"
></admin-list>
