import { HttpClient, HttpEvent, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityList } from 'app/models/entity-list.model';
import { Observable, Subject, map } from 'rxjs';
import { Brand } from '../models/brand.model';
import { ExtendedModel, Model } from '../models/model.model';
import { ExtendedSerie, Serie } from '../models/serie.model';

@Injectable()
export class ModelService {
  updateModels = new Subject<{ operation: string; value: Model[] }>();
  updateBrand = new Subject<{ operation: string; value: Brand[] }>();
  updateSeries = new Subject<{ operation: string; value: Serie[] }>();

  constructor(private _http: HttpClient) {}

  getModelById(modelId: number): Observable<{ statusCode: number; data: Model }> {
    return this._http.get('/api/model/' + modelId, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: Model; statusCode: number }>) => {
        return res.body;
      }),
    );
  }

  getModelList(query: ModelQuery = {}): Observable<{ statusCode: number; data: EntityList<ExtendedModel> }> {
    const route = {
      brandName: 'brand-name',
      serieName: 'serie-name',
      modelName: 'model-name',
      expand: 'expand',
      offset: 'offset',
      limit: 'limit',
      showParents: 'show-parents',
      serieIds: 'serie-ids',
      exclude: 'exclude',
      ids: 'ids',
      exactSearch: 'exact-search',
    };
    const questionMark = Object.keys(query).length > 0 ? '?' : '';
    const url =
      '/api/model/list' +
      questionMark +
      Object.keys(query)
        .map(k =>
          Array.isArray(query[k]) ? query[k].map((value, index) => `${route[k]}[${index}]=${value}`).join('&') : `${route[k]}=${query[k]}`,
        )
        .join('&');

    return this._http.get(url, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: EntityList<ExtendedModel>; statusCode: number }>) => {
        return res.body;
      }),
    );
  }

  addModel(model: { serieId: number; name: string; description: string; file: string }): Observable<HttpEvent<any>> {
    const newModel = {};
    Object.keys(model)
      .filter(f => model[f])
      .map(m => (newModel[m] = model[m]));

    return this._http.request(
      new HttpRequest('POST', '/api/model', newModel, {
        reportProgress: true,
        headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
      }),
    );
  }

  updateModel(
    modelId: number,
    modelInfo: { description: string; serieId: number; name: string; file?: File | string },
  ): Observable<HttpEvent<any>> {
    const updatedModel = {};
    Object.keys(modelInfo)
      .filter(f => modelInfo[f])
      .map(m => (updatedModel[m] = modelInfo[m]));

    return this._http.request(
      new HttpRequest(
        'PUT',
        '/api/model',
        { ...updatedModel, id: modelId },
        {
          reportProgress: true,
          headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
        },
      ),
    );
  }

  deleteModel(modelId: number): Observable<{ statusCode: number }> {
    return this._http.delete('/api/model/' + modelId, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: Model; statusCode: number }>) => {
        this.updateModels.next({ operation: 'deleteModel', value: [] });

        return res.body;
      }),
    );
  }

  addSerie(data: { brandId: number; name: string }): Observable<{ statusCode: number; data: Serie }> {
    return this._http.post('/api/serie', data, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: Serie; statusCode: number }>) => {
        this.updateSeries.next({ operation: 'addSerie', value: [res.body.data] });

        return res.body;
      }),
    );
  }

  updateSerie(serieId: number, data: { brandId: number; name: string }): Observable<{ statusCode: number; data: Serie }> {
    return this._http.put('/api/serie', { ...data, id: serieId }, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: Serie; statusCode: number }>) => {
        this.updateSeries.next({ operation: 'updateSerie', value: [res.body.data] });

        return res.body;
      }),
    );
  }

  deleteSerie(serieId: number): Observable<{ statusCode: number }> {
    return this._http.delete('/api/serie/' + serieId, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: Model; statusCode: number }>) => {
        this.updateSeries.next({ operation: 'deleteSerie', value: [] });

        return res.body;
      }),
    );
  }

  getSerieList(query: SerieQuery = {}): Observable<{ statusCode: number; data: EntityList<ExtendedSerie> }> {
    const route = {
      brandName: 'brand-name',
      serieName: 'serie-name',
      expand: 'expand',
      offset: 'offset',
      limit: 'limit',
      showParents: 'show-parents',
      brandIds: 'brand-ids',
      exactSearch: 'exact-search',
    };
    const questionMark = Object.keys(query).length > 0 ? '?' : '';
    const url =
      '/api/serie/list' +
      questionMark +
      Object.keys(query)
        .map(k =>
          Array.isArray(query[k]) ? query[k].map((value, index) => `${route[k]}[${index}]=${value}`).join('&') : `${route[k]}=${query[k]}`,
        )
        .join('&');

    return this._http.get(url, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: EntityList<ExtendedSerie>; statusCode: number }>) => {
        return res.body;
      }),
    );
  }

  getBrand(brandId: number): Observable<{ statusCode: number; data: Brand }> {
    return this._http.get('/api/brand/' + brandId, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: Brand; statusCode: number }>) => {
        return res.body;
      }),
    );
  }

  getSerie(serieId: number): Observable<{ statusCode: number; data: Serie }> {
    return this._http.get<{ statusCode: number; data: Serie }>('/api/serie/' + serieId);
  }

  getBrandList(query: BrandQuery = {}): Observable<{ statusCode: number; data: EntityList<Brand> }> {
    //const params = new HttpParams({ fromObject: query });
    const route = {
      brandName: 'brand-name',
      exactSearch: 'exact-search',
      offset: 'offset',
      limit: 'limit',
      exclude: 'exclude',
      expand: 'expand',
      like: 'like',
    };

    const questionMark = Object.keys(query).length > 0 ? '?' : '';
    const url =
      '/api/brand/list' +
      questionMark +
      Object.keys(query)
        .map(k =>
          Array.isArray(query[k]) ? query[k].map((value, index) => `${route[k]}[${index}]=${value}`).join('&') : `${route[k]}=${query[k]}`,
        )
        .join('&');

    return this._http.get<{ data: EntityList<Brand>; statusCode: number }>(url, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number; data: EntityList<Brand> }>) => {
        return res.body;
      }),
    );
  }

  addBrand(newBrand: { name: string; file: string | File }): Observable<HttpEvent<any>> {
    const req = new HttpRequest('POST', '/api/brand', newBrand, {
      reportProgress: true,
      headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
    });

    return this._http.request(req);
  }

  editBrand(brandId, brand: { name: string; file?: File }): Observable<HttpEvent<any>> {
    return this._http.request(
      new HttpRequest(
        'PUT',
        '/api/brand/',
        { ...brand, id: brandId },
        {
          reportProgress: true,
          headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
        },
      ),
    );
  }

  deleteBrand(brandId: number): Observable<{ statusCode: number; data: Brand }> {
    return this._http.delete('/api/brand/' + brandId, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: Brand; statusCode: number }>) => {
        this.updateBrand.next({ operation: 'deleteBrand', value: [] });

        return res.body;
      }),
    );
  }

  loadSVG(file: File): Observable<any> {
    const myReader: FileReader = new FileReader();
    const subject = new Subject<any>();

    myReader.onload = () => {
      subject.next(this.ParseSVG(myReader.result));
      subject.complete();
    };

    myReader.readAsText(file);

    // const src = URL.createObjectURL(file);
    // console.log(src);
    return subject.asObservable();
  }

  ParseSVG(svgstring: string | ArrayBuffer) {
    const parser = new DOMParser();
    const svgDom = parser.parseFromString(svgstring as string, 'image/svg+xml');
    const docEle = <any>svgDom.documentElement;
    // console.log('documentelement: ', { docEle });
    const globalStyle = docEle.getElementsByTagName('style').item(0);
    // console.log({ globalStyle });
    const sheet = globalStyle.sheet;
    const ruleslist = <CSSRuleList>sheet.rules;
    // console.log({ ruleslist });
    const classAndFill = [];
    Object.keys(ruleslist).forEach(key => {
      // console.log(key + ': ' + ruleslist[ key ].selectorText);
      // console.log(key + ': ' + ruleslist[ key ].style.fill);
      classAndFill.push({ class: ruleslist[key].selectorText.substr(1), fill: ruleslist[key].style.fill });
    });
    // console.log(classAndFill);
    const paths = docEle.querySelectorAll('path');
    // console.log(paths);
    const pathsInfo = [];
    paths.forEach(p => {
      // Reminder: Mettre un index si la valeur est null
      const d = p.getAttribute('d');
      const id = p.getAttribute('id');
      const className = p.getAttribute('class');
      const fill = classAndFill.filter(path => path.class === className).map(path => path.fill);
      // console.log('fill', fill);
      // const className = <any>classAndFill.filter(cf => cf.class == '.' + cf.getAttribute('class'));
      pathsInfo.push({ label: id, path: d, class: className, color: fill[0] });
      // console.log(classAndFill.filter(cf => cf.class === '.' + cf.getAttribute('class')));
    });
    // console.log(pathsInfo);
    pathsInfo
      .filter(f => f.label === null)
      .map(m => {
        m.label = 'noname' + Math.floor(Math.random() * 1000);
        m.class = 'noname';
        m.color = 'rgb(0,0,0)';
      });

    return pathsInfo;
  }
}

export type BrandQuery = {
  brandName?: string;
  limit?: number;
  offset?: number;
  expand?: number[];
  exactSearch?: boolean;
  like?: string;
  exclude?: number[];
};

export type SerieQuery = {
  brandName?: string;
  serieName?: string;
  expand?: number[];
  offset?: number;
  limit?: number;
  showParents?: boolean;
  brandIds?: number[];
  exactSearch?: boolean;
  like?: string;
  exclude?: number[];
};

export type ModelQuery = {
  brandName?: string;
  serieName?: string;
  modelName?: string;
  expand?: number[];
  offset?: number;
  limit?: number;
  showParents?: boolean;
  serieIds?: number[];
  ids?: number[];
  exactSearch?: boolean;
  like?: string;
  exclude?: number[];
};
