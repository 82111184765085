import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const VID_REGEX = /^[0-9A-Fa-f]{4}$/;
const INVALID_VID = '0000';

export const vidValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    const valid = VID_REGEX.test(value) && value.toUpperCase() !== INVALID_VID;

    return valid ? null : { invalidVid: { value } };
  };
};
