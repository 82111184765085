import { FormGroup, ValidatorFn } from '@angular/forms';

export function fieldsMatch(firstControl, secondControl) {
  const fieldsMatchValidator: ValidatorFn = (c: FormGroup): { [key: string]: boolean } => {
    const first = c.get(firstControl);
    const second = c.get(secondControl);

    if (!first || !second) {
      return null;
    }

    return first.value === second.value ? null : { mismatch: true };
  };

  return fieldsMatchValidator;
}
