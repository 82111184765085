import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CloseDialogInterface } from 'app/app-interfaces/close-dialog.interface';
import { SuccessDocumentPaymentDialogComponent } from 'app/success-document-payment-dialog/success-document-payment-dialog.component';

@Component({
  selector: 'app-success-document-payment',
  templateUrl: './success-document-payment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessDocumentPaymentComponent implements OnInit, CloseDialogInterface {
  dialogRef: any;
  constructor(protected dialog: MatDialog) {}

  ngOnInit(): void {
    const config: MatDialogConfig = { width: '60%', disableClose: true };
    this.dialogRef = this.dialog.open(SuccessDocumentPaymentDialogComponent, config);
  }
  closeDialog() {
    this.dialogRef.close();

    return true;
  }
}
