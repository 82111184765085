import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';

import { AdminService } from 'app/app-services';

@Injectable({
  providedIn: 'root',
})
export class DZAdminGuard {
  constructor(
    private readonly router: Router,
    private readonly adminService: AdminService,
  ) {}

  canActivate(activatedRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const adminId = activatedRoute?.parent?.params?.['id'];

    return this.adminService
      .getAdmin(adminId)
      .pipe(
        map(response => (response.data.country === 'DZ' ? true : this.router.createUrlTree(['/admin-details', adminId, 'admin-profile']))),
      );
  }
}
