<form [formGroup]="paymentDocumentForm">
  <div class="payment-document-form-row-1"></div>

  <div class="payment-document-form-row-2">
    <mat-form-field>
      <mat-label translate="admin" />
      <input type="text" matInput formControlName="admin" [matAutocomplete]="auto" />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selected($event)">
        @for (option of filteredOptions$ | async; track option) {
          <mat-option [value]="option">
            {{ option.fullName }}
          </mat-option>
        }
      </mat-autocomplete>
      @if (paymentDocumentForm.controls['admin'].hasError('invalidAdmin')) {
        <mat-error> {{ 'admin-validation' | translate }} </mat-error>
      }
      @if (paymentDocumentForm.controls['admin'].hasError('required')) {
        <mat-error> {{ 'required' | translate }}</mat-error>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label translate="type" />
      <mat-select formControlName="creditType" [attr.disabled]="paymentDocumentForm.controls['admin'].invalid">
        @for (event of eventType; track event) {
          <mat-option [value]="event">
            {{ event }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  @if (paymentDocumentForm.controls['creditType'].value === 'PPF') {
    <div class="payment-document-form-row-4">
      <mat-form-field>
        <mat-label translate="allowedPPF" />
        <input matInput type="number" formControlName="initialQuantity" />
      </mat-form-field>
      <mat-form-field hintLabel="Min {{ admin ? -admin.allowedPPF : 0 }}">
        <mat-label translate="allowedPPFExtra" />
        <input matInput type="number" [min]="admin ? -admin.allowedPPF : 0" formControlName="extraQuantity" updateOnBlur />
        @if (paymentDocumentForm.controls['extraQuantity']?.hasError('required')) {
          <mat-error>{{ 'requiredPPFValue' | translate }} </mat-error>
        }
        @if (paymentDocumentForm.controls['extraQuantity']?.hasError('min')) {
          <mat-error>
            {{ 'value' | translate }} {{ paymentDocumentForm.controls['extraQuantity'].errors?.min.actual }}
            {{ 'below-minimal' | translate }} {{ paymentDocumentForm.controls['extraQuantity'].errors?.min.min }} f
            {{ 'forPPFCredit' | translate }}
          </mat-error>
        }
        <mat-hint align="end"> {{ paymentDocumentForm.get('extraQuantity').value }}/{{ -admin.allowedPPF }} </mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label translate="allowedPPFFinal" />
        <input matInput type="number" formControlName="finalQuantity" />
      </mat-form-field>
    </div>
  }

  @if (paymentDocumentForm.controls['creditType'].value === 'WINDOW') {
    <div class="payment-document-form-row-4">
      <mat-form-field>
        <mat-label translate="allowedWindows" />
        <input matInput type="number" formControlName="initialQuantity" />
      </mat-form-field>
      <mat-form-field hintLabel="min {{ admin ? -this.admin.allowedWindows : 0 }}">
        <mat-label translate="allowedWindowsExtra" />
        <input matInput type="number" [min]="admin ? -this.admin.allowedWindows : 0" formControlName="extraQuantity" updateOnBlur />
        @if (paymentDocumentForm.controls['extraQuantity'].hasError('required')) {
          <mat-error>{{ 'requiredWindowValue' | translate }} </mat-error>
        }
        @if (paymentDocumentForm.controls['extraQuantity'].hasError('min')) {
          <mat-error>
            {{ 'value' | translate }} {{ paymentDocumentForm.controls['extraQuantity'].errors?.min.actual
            }}{{ 'below-minimal' | translate }} {{ paymentDocumentForm.controls['extraQuantity'].errors?.min.min }}
            {{ 'forWindowCredit' | translate }}</mat-error
          >
        }
        <mat-hint align="end"> {{ paymentDocumentForm.get('extraQuantity').value }}/{{ -admin.allowedWindows }}</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label translate="allowedWindowsFinal" />
        <input matInput type="number" formControlName="finalQuantity" />
      </mat-form-field>
    </div>
  }

  <div class="payment-document-form-row-4">
    <mat-form-field>
      <mat-label translate="pu" />
      <input matInput type="number" formControlName="unitPrice" updateOnBlur />
      @if (paymentDocumentForm.controls['unitPrice'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label translate="vat" />
      <input matInput type="number" [min]="0" [max]="100" formControlName="vat" updateOnBlur />
      @if (paymentDocumentForm.controls['vat'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label translate="amount" />
      <input matInput type="number" formControlName="amount" readonly updateOnBlur />
    </mat-form-field>
  </div>
</form>
