import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PrintableDataService } from '../app-services/printable-data.service';

import { Piece } from 'app/models/model.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as svgPath from 'svgpath';
const svgPathDefault = svgPath;

@Component({
  selector: 'model-viewer',
  templateUrl: './model-viewer.component.html',
  styleUrls: ['./model-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelViewerComponent implements OnChanges, AfterViewInit, OnInit, OnChanges, OnDestroy {
  @Input() parsedSVG: Piece[];
  @Input() bbox: { x: number; y: number; width: number; height: number };
  @Output() updateParsedSVGAndBbox = new EventEmitter();
  @ViewChild('svg', { static: true }) svg: ElementRef;

  viewbox = { x: 0, y: 0, width: 0, height: 0 };
  viewboxString = '0 0 0 0';
  translatecoord = { x: 0, y: 0 };

  private readonly unsubscribeSubject: Subject<void>;

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
  constructor(
    private cd: ChangeDetectorRef,
    private printableDataService: PrintableDataService,
  ) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnInit() {
    this.printableDataService.beforeSendSvgEvent.pipe(takeUntil(this.unsubscribeSubject)).subscribe();
  }

  ngAfterViewInit() {
    if (!this.bbox) {
      this.updateSvg();
    }
  }

  updateSvg() {
    this.viewbox = this.svg.nativeElement.getBBox();
    this.translatecoord.x = this.viewbox.x * -1;
    this.translatecoord.y = this.viewbox.y * -1;
    // this.viewboxString = '' + this.viewbox.x + ' ' + this.viewbox.y + ' ' + this.viewbox.width + ' ' + this.viewbox.height;
    // console.log(this.viewboxString);
    this.parsedSVG.forEach(
      p => (p.path = svgPathDefault(p.path).translate(this.translatecoord.x, this.translatecoord.y).round(0.000001).toString().toString()),
    );
    this.cd.detectChanges();
    let paths: any[] = this.svg.nativeElement.querySelectorAll('path');
    paths = Array.from(paths); // convertir le node list en array
    this.parsedSVG.forEach(p => {
      const f = paths.find(af => af.id === p.label);
      // next line debug purpose
      // const tf = this.parsedSVG.indexOf(f);
      const bbp = f.getBBox();
      // adding all paths boubdary box info
      p.x = bbp.x;
      p.y = bbp.y;
      p.width = bbp.width;
      p.height = bbp.height;
    });

    this.cd.detectChanges();
    // getting all svg boundary box
    const svgBbox = this.svg.nativeElement.getBBox();
    this.viewboxString = svgBbox.x + ' ' + svgBbox.y + ' ' + svgBbox.width + ' ' + svgBbox.height;
    this.cd.detectChanges();
    this.updateParsedSVGAndBbox.emit({ path: this.parsedSVG, bbox: svgBbox });
  }

  ngOnChanges() {
    if (this.bbox) {
      this.viewboxString = `${this.bbox.x} ${this.bbox.y} ${this.bbox.width} ${this.bbox.height}`;
    }
    // doesn't work ngonchange procs before  render is done so it doesn't calculate bbox
    // console.log('change', change);

    // this.viewbox = this.svg.nativeElement.getBBox();
    // this.viewboxString = '' + this.viewbox.x + ' ' + this.viewbox.y + ' ' + this.viewbox.width + ' ' + this.viewbox.height;
    // console.log('bbox ch', this.svg.nativeElement.getBBox());
    // this.translatecoord.x = this.viewbox.x < 0 ? this.viewbox.x * -1 : 0;
    // this.translatecoord.y = this.viewbox.y < 0 ? this.viewbox.y * -1 : 0;
    // console.log('aga', this.viewbox);
  }
}
