import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SuperAdmin } from '../models/super-admin.model';

@Injectable()
export class SuperAdminService {
  constructor(private _http: HttpClient) {}

  getSuperAdmin(): Observable<{ statusCode: number; data: SuperAdmin }> {
    return this._http.get('/api/super-admin', { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number; data: SuperAdmin }>) => {
        return res.body;
      }),
    );
  }

  updateSuperAdmin(updatedSuperAdmin: SuperAdmin): Observable<{ statusCode: number; data: SuperAdmin }> {
    return this._http.put('/api/super-admin', updatedSuperAdmin, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number; data: SuperAdmin }>) => {
        return res.body;
      }),
    );
  }

  setPassword(passwords: { currentPassword: string; newPassword: string }): Observable<{ statusCode: number; data: SuperAdmin }> {
    return this._http.put('/api/super-admin/set-password', passwords, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number; data: SuperAdmin }>) => {
        return res.body;
      }),
    );
  }
}
