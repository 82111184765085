<h1>{{ 'login' | translate }}</h1>

<form (ngSubmit)="submitUserData()" [formGroup]="loginForm">
  <mat-form-field>
    <mat-label translate="email" />
    <input matInput type="email" formControlName="email" updateOnBlur />
  </mat-form-field>
  <mat-form-field>
    <mat-label translate="password" />
    <input matInput type="password" formControlName="password" updateOnBlur />
  </mat-form-field>
  <button color="primary" [disabled]="loginForm.invalid" mat-raised-button type="submit">{{ 'login' | translate }}</button>
</form>
<a (click)="submitScope()">{{ 'forgot-password' | translate }}</a>
