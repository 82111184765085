import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs';
import { omitBy, isEmpty } from 'lodash';
import { Brand } from '../models/brand.model';
import { Serie } from '../models/serie.model';

@Component({
  selector: 'serie-filter',
  templateUrl: './serie-filter.component.html',
  styleUrls: ['./serie-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SerieFilterComponent implements OnChanges, OnDestroy {
  @Input() series: Serie[];
  @Input() brands: Brand[];
  @Input() searchTerms: { brandName: string; serieName: string };

  @Output() filterSeries = new EventEmitter();
  @Output() fetchBrands = new EventEmitter();
  @Output() fetchSeries = new EventEmitter();
  @Output() routeNavigate = new EventEmitter();

  filterForm: FormGroup;

  private readonly unsubscribeSubject: Subject<void>;

  constructor() {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.filterForm) {
      this.buildForm();
    }

    if (changes['searchTerms']?.currentValue) {
      this.filterForm.get('brand').setValue(this.searchTerms.brandName);
      this.filterForm.get('serie').setValue(this.searchTerms.serieName);
    }
  }

  private buildForm() {
    this.filterForm = new FormGroup({
      brand: new FormControl(null),
      serie: new FormControl(null),
    });
    this.filterForm.controls['brand'].valueChanges
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        takeUntil(this.unsubscribeSubject),
        filter(searchTerm => searchTerm?.length > 1),
      )
      .subscribe(brandName => this.fetchBrands.emit({ brandName: brandName.trim() }));

    this.filterForm.controls['serie'].valueChanges
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        takeUntil(this.unsubscribeSubject),
        filter(searchTerm => searchTerm?.length > 1),
      )
      .subscribe(serieName => this.fetchSeries.emit({ serieName: serieName.trim() }));
  }

  search() {
    const brand = this.filterForm.controls['brand'].value;
    const serie = this.filterForm.controls['serie'].value;
    const value = {};
    if (brand) {
      value['brandName'] = brand.trim();
    }
    if (serie) {
      value['serieName'] = serie.trim();
    }

    value['showParents'] = 'true';

    this.filterSeries.emit(
      omitBy(
        value,

        isEmpty,
      ),
    );
    this.brands = undefined;
    this.series = undefined;
  }

  navigate() {
    this.routeNavigate.emit(null);
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
