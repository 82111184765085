@if (printed.length) {
  <div>
    <h3>{{ 'printed' | translate }}</h3>
    <model-viewer [bbox]="printableData.bbox" [parsedSVG]="printed"></model-viewer>
  </div>
}

@if (notPrinted.length) {
  <div>
    <h3>{{ 'not-printed' | translate }}</h3>
    <model-viewer [bbox]="printableData.bbox" [parsedSVG]="notPrinted"></model-viewer>
  </div>
}
