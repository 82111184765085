<h2>{{ 'payment-document-log' | translate }}</h2>
<super-admin-payment-document-log-filter
  class="toolbar-body"
  [admins]="adminList | async"
  [searchedAdmins]="searchedAdmins$ | async"
  [searchedDates]="searchedDates"
  (queryAdmins)="fetchAdmins($event)"
  (filterQuery)="filterEPayment($event)"
  (addPaymentDocument)="onAddPaymentDocumentClick()"
></super-admin-payment-document-log-filter>
<super-admin-payment-document-log-list
  [paymentsDocumentList]="paymentsDocumentList | async"
  [offset]="offset$ | async"
  (submitFilterOptions)="pageNavigation($event)"
>
</super-admin-payment-document-log-list>
