<h2 mat-dialog-title>{{ 'new-model' | translate }}</h2>
<mat-dialog-content>
  <model-form #modelForm [serieList]="serieList | async" [model]="model" (modelInfoSubmit)="handleModelInfo($event)"></model-form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-raised-button
    color="accent"
    (click)="addModel()"
    [disabled]="modelForm.modelForm.invalid || !model.picture || (progress | async)"
  >
    {{ (progress | async) ? (progress | async) + '%' : ('add' | translate) }}
  </button>
  <button mat-raised-button color="warn" (click)="cancel()">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
