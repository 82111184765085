<div class="contents">
  @if (modelAdmins) {
    <div>
      <ngx-datatable
        [rows]="modelAdmins.listing"
        [footerHeight]="30"
        [columnMode]="'force'"
        [rowHeight]="'auto'"
        [count]="modelAdmins.total"
        [messages]="messages$ | async"
      >
        <ngx-datatable-column [name]="'admin-models' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/', { outlets: { dialog: ['edit-model-admin', row.id] } }]">
              {{ row.fullName }}
            </a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'email' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.email }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [cellClass]="'item-cell'"
          [maxWidth]="100"
          [name]="'delete' | translate"
          [sortable]="false"
          [draggable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button mat-icon-button color="warn" (click)="deleteDBAdmin(row)">
              <mat-icon svgIcon="ic_delete_24px"></mat-icon>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  } @else {
    <div>
      <mat-spinner></mat-spinner>
    </div>
  }
</div>
