import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { SuperAdmin } from '../models/super-admin.model';
import { phoneValidator } from '../validators/phone.validator';
import { AvailableLanguages } from 'app/models/available-languages.const';

@Component({
  selector: 'super-admin-profile-form',
  templateUrl: './super-admin-profile-form.component.html',
  styleUrls: ['./super-admin-profile-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuperAdminProfileFormComponent implements OnChanges {
  @Input() loading: boolean;
  @Input() superAdmin: SuperAdmin;
  @Output() formSave = new EventEmitter();
  @Output() formReset = new EventEmitter();
  availableLanguages = AvailableLanguages;
  superAdminForm: FormGroup;
  disabled = false;

  ngOnChanges(changes: SimpleChanges) {
    if (!this.superAdminForm) {
      this.buildForm();
    }

    this.disabled = this.loading === false ? true : false;
    if (changes.superAdmin?.currentValue) {
      this.superAdminForm.patchValue(changes.superAdmin.currentValue);
      this.superAdminForm.markAsPristine();
    }
  }

  buildForm() {
    this.superAdminForm = new FormGroup({
      fullName: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      address: new FormControl(null, Validators.required),
      phone: new FormControl(null, [Validators.required, phoneValidator]),
      language: new FormControl(null, Validators.required),
    });
  }

  save() {
    this.formSave.emit(this.superAdminForm.value);
  }

  cancel() {
    this.formReset.emit(null);
  }
}
