import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  PaymentIntent,
  Stripe,
  StripeElements,
  StripeLinkAuthenticationElement,
  StripePaymentElement,
  loadStripe,
} from '@stripe/stripe-js';
import { AuxiliaryRouteClass } from 'app/app-interfaces/close-dialog.interface';
import { CheckoutService } from 'app/app-services/checkout.service';
import { TranslateService } from '@ngx-translate/core';
import { from, switchMap, map, tap, finalize, ReplaySubject, Subject } from 'rxjs';
import { PaymentDocumentService } from 'app/app-services/payment-document.service';

@Component({
  selector: 'app-stripe-payment-interface-dialog',
  templateUrl: './stripe-payment-interface-dialog.component.html',
  styleUrls: ['./stripe-payment-interface-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripePaymentInterfaceDialogComponent extends AuxiliaryRouteClass implements OnInit {
  elements: StripeElements;
  paymentElement: StripePaymentElement = null;
  paymentIntent: PaymentIntent;
  linkAuthenticationElement: StripeLinkAuthenticationElement = null;
  submitted = false;
  private stripe: Stripe;
  loading$ = new ReplaySubject<boolean>();
  paymentFormValid$ = new Subject<boolean>();
  errorMessage$ = new Subject<string>();
  private clientSecret: string;
  constructor(
    private readonly checkoutService: CheckoutService,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
    private readonly paymentDocumentService: PaymentDocumentService,
    protected router: Router,
    @Inject(MAT_DIALOG_DATA) private paymentDocumentId: number,
  ) {
    super(router);
  }

  ngOnInit() {
    this.paymentFormValid$.next(false);
    this.loading$.next(true);
    this.checkoutService
      .getStripePublishableKey()
      .pipe(
        map(response => response.data.publishableKey),
        switchMap(stripePublicKey => loadStripe(stripePublicKey)),
        tap(stripe => {
          this.stripe = stripe;
        }),
        switchMap(() => this.checkoutService.retriveClientSecret(this.paymentDocumentId)),
        map(response => response.data.clientSecret),
        tap(response => {
          this.clientSecret = response;
          this.elements = this.stripe?.elements({ clientSecret: this.clientSecret, appearance: { theme: 'stripe' }, loader: 'always' });
          this.paymentElement = this.elements?.create('payment', { layout: 'tabs', business: { name: 'MAGIC-CUT' } });
          this.paymentElement.mount('#payment-element');
        }),
        switchMap(() => this.stripe.retrievePaymentIntent(this.clientSecret)),
        finalize(() => {
          this.loading$.next(false);
        }),
      )
      .subscribe(paymentIntentResult => {
        this.paymentIntent = paymentIntentResult.paymentIntent;
        // Create and mount the linkAuthentication Element to enable autofilling customer payment details
        this.linkAuthenticationElement = this.elements.create('linkAuthentication', {
          defaultValues: { email: this.paymentIntent.receipt_email || '' },
        });
        this.linkAuthenticationElement.mount('#link-authentication-element');

        this.paymentElement.on('change', event => {
          this.paymentFormValid$.next(event.complete);
        });
      });
  }

  pay() {
    if (this.submitted) {
      return;
    }
    this.loading$.next(true);
    this.submitted = true;
    this.errorMessage$.next('');

    from(
      this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {},
        redirect: 'if_required',
      }),
    ).subscribe({
      next: ({ error, paymentIntent }) => {
        if (paymentIntent && paymentIntent.status === 'succeeded') {
          this.router.navigate(['/', { outlets: { dialog: ['success-payment'] } }]);
        }
        if (error) {
          this.errorMessage$.next(error.decline_code ? error.decline_code : error.code);
          this.translateService.get(['stripe-failed', 'try-again']).subscribe(result => {
            this.snackBar.open(result['stripe-failed'], result['try-again'], { duration: 9000 });
          });
        }
      },
      error: error => {
        this.errorMessage$.next(error.decline_code ? error.decline_code : error.code);
        this.translateService.get(['stripe-failed', 'retry']).subscribe(({ message, retry }) => {
          this.snackBar.open(message, retry, { duration: 9000 });
        });
      },
      complete: () => {
        this.submitted = false;
        this.paymentDocumentService.update.next(true);
        this.loading$.next(false);
      },
    });
  }

  leave() {
    this.paymentDocumentService.update.next(true);
    this.leaveDialog();
  }
}
