<mat-toolbar>
  <form [formGroup]="paymentDocumentFiltreForm" novalidate>
    <mat-form-field [subscriptSizing]="'dynamic'">
      <input
        matInput
        name="fDate"
        [max]="maxFirstDate"
        formControlName="startDate"
        [matDatepicker]="sDate"
        [placeholder]="'start-date' | translate"
        #firstDate
      />
      @if (paymentDocumentFiltreForm.controls['startDate'].hasError('date')) {
        <mat-error> {{ 'date' | translate }} </mat-error>
      }
      <mat-datepicker-toggle matSuffix [for]="sDate"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #sDate></mat-datepicker>

    <mat-form-field [subscriptSizing]="'dynamic'">
      <input
        matInput
        name="lDate"
        [min]="minLastDate"
        [max]="maxLastDate"
        formControlName="endDate"
        [matDatepicker]="eDate"
        [placeholder]="'end-date' | translate"
        #lastDate
      />
      @if (paymentDocumentFiltreForm.controls['endDate'].hasError('date')) {
        <mat-error> {{ 'date' | translate }} </mat-error>
      }
      <mat-datepicker-toggle matSuffix [for]="eDate"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #eDate></mat-datepicker>

    <mat-form-field [subscriptSizing]="'dynamic'">
      <mat-label [translate]="'type' | translate"></mat-label>
      <mat-select formControlName="type">
        @for (documentType of paymentDocumentTypes; track documentType) {
          <mat-option [value]="documentType">{{ documentType | translate }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field [subscriptSizing]="'dynamic'">
      <mat-label [translate]="'status' | translate"></mat-label>
      <mat-select formControlName="status">
        @for (documentStatus of paymentDocumentStatus; track documentStatus) {
          <mat-option [value]="documentStatus">{{ documentStatus | translate }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <button mat-mini-fab color="accent" (click)="search()">
      <mat-icon svgIcon="ic_search_24px"></mat-icon>
    </button>
  </form>
</mat-toolbar>
