<form [formGroup]="addressForm">
  <div class="address-form-row">
    <mat-form-field>
      <mat-label translate="country" />
      <input matInput formControlName="country" [matAutocomplete]="countryAuto" />
      @if (addressForm.controls['country'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
    </mat-form-field>
    <mat-autocomplete
      #countryAuto="matAutocomplete"
      (optionSelected)="countrySelected($event.option.value)"
      [displayWith]="displayWithName"
    >
      @for (country of filteredCountry | async; track country) {
        <mat-option [value]="country"> {{ country.flag }}&nbsp;&nbsp;{{ country.name }} </mat-option>
      }
    </mat-autocomplete>

    <mat-form-field>
      <mat-label translate="region" />
      <input matInput formControlName="state" [matAutocomplete]="stateAuto" />
      @if (addressForm.controls['state'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
    </mat-form-field>
    <mat-autocomplete #stateAuto="matAutocomplete" (optionSelected)="stateSelected($event.option.value)" [displayWith]="displayWithName">
      @for (state of filteredState | async; track state) {
        <mat-option [value]="state">
          {{ state.name }}
        </mat-option>
      }
    </mat-autocomplete>
  </div>
  <div class="address-form-row">
    <mat-form-field>
      <mat-label translate="city" />
      <input matInput formControlName="city" [matAutocomplete]="cityAuto" />
      @if (addressForm.controls['city'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
    </mat-form-field>
    <mat-autocomplete #cityAuto="matAutocomplete" [displayWith]="displayWithName">
      @for (city of filteredCity | async; track city) {
        <mat-option [value]="city"> {{ city.name }}</mat-option>
      }
    </mat-autocomplete>
    <mat-form-field>
      <mat-label translate="address" />
      <input matInput type="text" formControlName="address" updateOnBlur />
      @if (addressForm.controls['address'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
    </mat-form-field>
  </div>
</form>
