import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Admin } from 'app/models/admin.model';
import { Observable, Subject, filter, map, merge, startWith, takeUntil } from 'rxjs';
import { autocompleteAdminValidator } from '../validators/autocomplete-admin-validator';

@Component({
  selector: 'payment-document-form',
  templateUrl: './payment-document-form.component.html',
})
export class PaymentDocumentFormComponent implements OnInit, OnDestroy {
  admin: Admin;

  @Input() allAdmin: Admin[];
  @Output() paymentDocumentValue = new EventEmitter<any>();
  eventType = ['PPF', 'WINDOW'] as const;

  filteredOptions$: Observable<Admin[]>;
  private readonly unsubscribeSubject: Subject<void>;

  paymentDocumentForm = this.formBuilder.group({
    admin: ['', [Validators.required, autocompleteAdminValidator()]],
    adminId: [null as number, Validators.required],
    creditType: [{ value: '' as 'PPF' | 'WINDOW', disabled: true }, Validators.required],
    initialQuantity: [{ value: 0, disabled: true }],
    extraQuantity: [null as number, Validators.required],
    finalQuantity: [{ value: 0, disabled: true }],
    vat: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
    unitPrice: [null as number, Validators.required],
    amount: [{ value: 0, disabled: true }],
  });

  constructor(private readonly formBuilder: FormBuilder) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnInit() {
    this.filteredOptions$ = this.paymentDocumentForm.get('admin').valueChanges.pipe(
      takeUntil(this.unsubscribeSubject),
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : (value as Admin)?.fullName;

        return name ? this.filterAdmins(name) : this.allAdmin?.slice();
      }),
    );

    merge(
      this.paymentDocumentForm.get('extraQuantity').valueChanges,
      this.paymentDocumentForm.get('unitPrice').valueChanges,
      this.paymentDocumentForm.get('vat').valueChanges,
    )
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(() => {
        this.totalCalculate();
      });

    this.paymentDocumentForm
      .get('creditType')
      .valueChanges.pipe(
        takeUntil(this.unsubscribeSubject),
        filter(() => this.paymentDocumentForm.get('adminId').valid),
      )
      .subscribe(creditType => {
        this.paymentDocumentForm.get('initialQuantity').setValue(creditType === 'PPF' ? this.admin.allowedPPF : this.admin.allowedWindows);
        this.resetValue();
      });

    this.paymentDocumentForm
      .get('admin')
      .valueChanges.pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(() => {
        this.paymentDocumentForm.get('admin').valid
          ? this.paymentDocumentForm.get('creditType').enable()
          : this.paymentDocumentForm.get('creditType').disable();
      });

    this.paymentDocumentForm.valueChanges
      .pipe(
        takeUntil(this.unsubscribeSubject),
        filter(() => this.paymentDocumentForm.valid),
      )
      .subscribe(formValue => {
        this.paymentDocumentValue.emit({
          adminId: formValue.adminId,
          unitPrice: formValue.unitPrice,
          vat: formValue.vat,
          extra: {
            quantity: formValue.extraQuantity,
            creditType: formValue.creditType,
          },
        });
      });
  }

  private filterAdmins(name: string): Admin[] {
    const filterValue = name.toLowerCase();

    return this.allAdmin.filter(admin => admin.fullName.toLowerCase().includes(filterValue));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.admin = event.option.value as Admin;
    this.paymentDocumentForm.get('creditType').reset();
    this.resetValue();
    this.paymentDocumentForm.get('adminId').setValue(this.admin.id);
  }

  private resetValue() {
    this.paymentDocumentForm.get('extraQuantity').reset();
    this.paymentDocumentForm.get('amount')?.setValue(0);
    this.paymentDocumentForm.get('vat')?.setValue(0);
    this.paymentDocumentForm.get('unitPrice')?.setValue(0);
  }

  totalCalculate() {
    const quantity = this.paymentDocumentForm.get('extraQuantity').value;

    const creditType = this.paymentDocumentForm.get('creditType').value;
    this.paymentDocumentForm
      .get('finalQuantity')
      .setValue((creditType === 'PPF' ? this.admin.allowedPPF : this.admin.allowedWindows) + quantity);

    const unitPrice = this.paymentDocumentForm.get('unitPrice').value || 0;

    const VAT = this.paymentDocumentForm.get('vat').value;

    const totalAmount =
      VAT > 0 ? +(unitPrice * quantity + (VAT / 100) * (unitPrice * quantity)).toFixed(2) : +(unitPrice * quantity).toFixed(2);

    this.paymentDocumentForm.get('amount').setValue(totalAmount);
  }

  displayFn(admin: Admin) {
    return admin ? admin.fullName : undefined;
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
