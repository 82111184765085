import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { vidValidator } from '../validators/vendor-id.validator';

@Component({
  selector: 'plotter-form',
  templateUrl: './plotter-form.component.html',
})
export class PlotterFormComponent implements OnInit, OnDestroy {
  @Output() formSubmit = new EventEmitter();
  plotterForm: FormGroup;
  ngUnsubscribe: Subscription[] = [];

  ngOnInit() {
    this.buildForm();
    this.ngUnsubscribe.push(
      this.plotterForm.valueChanges.subscribe(plotterForm => {
        this.formSubmit.emit({
          name: plotterForm.name,
          vendorId: plotterForm.vendorId,
          productId: plotterForm.productId,
        });
      }),
    );
  }

  private buildForm() {
    this.plotterForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      vendorId: new FormControl(null, [Validators.required, vidValidator()]),
      productId: new FormControl(null, [Validators.required, vidValidator()]),
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.forEach(res => res.unsubscribe);
  }
}
