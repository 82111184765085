import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddPlotterDialogComponent } from '../add-plotter-dialog/add-plotter-dialog.component';
import { CloseDialogInterface } from '../app-interfaces/close-dialog.interface';

@Component({
  selector: 'add-plotter',
  templateUrl: './add-plotter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPlotterComponent implements OnInit, CloseDialogInterface {
  dialogRef: any;

  constructor(protected dialog: MatDialog) {}

  ngOnInit() {
    const config: MatDialogConfig = { width: '60%', disableClose: true };
    this.dialogRef = this.dialog.open(AddPlotterDialogComponent, config);
  }

  closeDialog() {
    this.dialogRef.close();

    return true;
  }
}
