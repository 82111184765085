import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';

import { AuxiliaryRouteClass } from '../app-interfaces/close-dialog.interface';
import { AuthService, HandleErrorsService, UsersService } from '../app-services';
import { UserInfo } from '../models/user.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddUserDialogComponent extends AuxiliaryRouteClass implements OnInit {
  userInfo: UserInfo;
  queryParams: any;
  loading = new ReplaySubject<boolean>(1);

  constructor(
    public router: Router,
    protected route: ActivatedRoute,
    protected usersService: UsersService,
    protected snackBar: MatSnackBar,
    protected authService: AuthService,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
  ) {
    super(router);
  }

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;
  }

  handleUserInfo(userInfo: UserInfo) {
    this.userInfo = userInfo;
  }

  addUser() {
    this.loading.next(true);
    this.usersService.addUser(this.userInfo).subscribe({
      next: () => {
        this.translateService.get('user-message-success').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
        this.leaveDialog(this.queryParams);
      },
      error: err => {
        this.loading.next(false);
        this.handleErrors.handleErrors(err);
      },
    });
  }
}
