<form [formGroup]="creditForm">
  <div class="credit-form-row-4">
    <mat-form-field>
      <mat-labe translate="allowedPPF" />
      <input matInput type="number" formControlName="allowedPPF" />
    </mat-form-field>

    <mat-form-field hintLabel="Max {{ this.admin.allowedPPF | number: '1.2-2' }}">
      <mat-label translate="allowedPPFExtra" />
      <input matInput type="number" min="0" [max]="admin.allowedPPF" formControlName="allowedPPFExtra" updateOnBlur />
      @if (creditForm.controls['allowedPPFExtra'].hasError('required')) {
        <mat-error> {{ 'required-pos-value-PPF' | translate }} </mat-error>
      }
      @if (creditForm.controls['allowedPPFExtra'].hasError('min')) {
        <mat-error>
          {{ 'value' | translate }} {{ creditForm.controls['allowedPPFExtra'].errors?.min.actual }} {{ 'below-minimal' | translate }}
          {{ creditForm.controls['allowedPPFExtra'].errors?.min.min }} {{ 'forPPFCredit' | translate }}
        </mat-error>
      }
      @if (creditForm.controls['allowedPPFExtra'].hasError('max')) {
        <mat-error>
          {{ 'value' | translate }} {{ creditForm.controls['allowedPPFExtra'].errors?.max.actual }} {{ 'above-max' | translate }}
          {{ creditForm.controls['allowedPPFExtra'].errors?.max.max }} {{ 'forPPFCredit' | translate }}
        </mat-error>
      }
      <mat-hint align="end">
        {{ creditForm.get('allowedPPFExtra').value | number: '1.2-2' }}/{{ admin.allowedPPF | number: '1.2-2' }}
      </mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label translate="allowedPPFFinal" />
      <input matInput type="number" formControlName="allowedPPFFinal" />
    </mat-form-field>
  </div>
  <div class="credit-form-row-4">
    <mat-form-field>
      <mat-label translate="allowedWindows" />
      <input matInput type="number" formControlName="allowedWindows" />
    </mat-form-field>

    <mat-form-field hintLabel="Max {{ this.admin.allowedWindows | number: '1.2-2' }}">
      <mat-label translate="allowedWindowsExtra" />
      <input matInput type="number" min="0" [max]="admin.allowedWindows" formControlName="allowedWindowsExtra" updateOnBlur />
      @if (creditForm.controls['allowedWindowsExtra'].hasError('required')) {
        <mat-error> {{ 'required-pos-value-Window' | translate }} </mat-error>
      }
      @if (creditForm.controls['allowedWindowsExtra'].hasError('min')) {
        <mat-error>
          {{ 'value' | translate }} {{ creditForm.controls['allowedWindowsExtra'].errors?.min.actual }} {{ 'below-minimal' | translate }}
          {{ creditForm.controls['allowedWindowsExtra'].errors?.min.min }} {{ 'forWindowCredit' | translate }}</mat-error
        >
      }
      @if (creditForm.controls['allowedWindowsExtra'].hasError('max')) {
        <mat-error>
          {{ 'value' | translate }} {{ creditForm.controls['allowedWindowsExtra'].errors?.max.actual }}{{ 'above-max' | translate }}
          {{ creditForm.controls['allowedWindowsExtra'].errors?.max.max }} {{ 'forWindowCredit' | translate }}
        </mat-error>
      }
      <mat-hint align="end">
        {{ creditForm.get('allowedWindowsExtra').value | number: '1.2-2' }}/{{ admin.allowedWindows | number: '1.2-2' }}</mat-hint
      >
    </mat-form-field>
    <mat-form-field>
      <mat-label translate="allowedWindowsFinal" />
      <input matInput type="number" formControlName="allowedWindowsFinal" />
    </mat-form-field>
  </div>
  <button
    mat-raised-button
    color="primary"
    [disabled]="
      creditForm.status !== 'VALID' ||
      (creditForm.get('allowedWindowsExtra')?.value === 0 && creditForm.get('allowedPPFExtra')?.value === 0)
    "
    (click)="save()"
  >
    {{ 'add-credit' | translate }}
  </button>
</form>
