import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BannerService, HandleErrorsService } from '../app-services';

@Injectable({
  providedIn: 'root',
})
export class AdminBanneResolver {
  constructor(
    protected bannerService: BannerService,
    protected handleErrors: HandleErrorsService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const id = route.parent.params['id'];

    return this.bannerService.getBanner(id).pipe(
      catchError(err => {
        this.handleErrors.handleErrors(err);

        return of(null);
      }),
    );
  }
}
