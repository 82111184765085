<h2>{{ 'credit-log' | translate }}</h2>
<super-admin-credit-log-filter
  class="toolbar-body"
  [admins]="adminList | async"
  [searchedAdmins]="searchedAdmins$ | async"
  [searchedDates]="searchedDates"
  (queryAdmins)="fetchAdmins($event)"
  (filterQuery)="filterLogging($event)"
></super-admin-credit-log-filter>
<super-admin-credit-log-list [loggings]="loggings | async" [offset]="offset$ | async" (submitFilterOptions)="pageNavigation($event)">
</super-admin-credit-log-list>
