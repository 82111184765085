<h2 mat-dialog-title>{{ 'new_plotter' | translate }}</h2>
<mat-dialog-content>
  <plotter-form #plotterForm (formSubmit)="handleFormInfo($event)"></plotter-form>
</mat-dialog-content>

<mat-dialog-actions>
  @if (loading | async) {
    <mat-spinner></mat-spinner>
  }
  <button mat-raised-button color="accent" (click)="createPlotter()" [disabled]="loading | async">
    {{ 'add' | translate }}
  </button>
  <button mat-raised-button color="warn" (click)="leaveDialog(queryParams)">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
