import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'delete-serie-dialog-dumb',
  templateUrl: './delete-serie-dialog-dumb.component.html',
  styleUrls: ['./delete-serie-dialog-dumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteSerieDialogDumbComponent {
  @Input() modelList: any[];
}
