import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class AuthStateService {
  noAuthEndPoint = [
    '/api/auth/admin/login',
    '/api/auth/super-admin/login',
    '/api/auth/model-admin/login',
    '/api/auth/request-reset-password',
    '/api/auth/reset-password',
  ];
  excludedPayloads = ['/api/brand', '/api/model'];

  credentials: Credentials = null;
  credentialsListner = new Subject<Credentials>();

  constructor() {
    this.credentials = JSON.parse(sessionStorage.getItem('credentials'));
  }

  getCredentials() {
    return this.credentials;
  }

  setCredentials(credentials: Credentials) {
    sessionStorage.setItem('credentials', JSON.stringify(credentials));
    this.credentials = credentials;
    this.credentialsListner.next(credentials);
  }

  clearCredentials() {
    this.setCredentials(null);
  }
}

export type Credentials = {
  payload: {
    role: string;
    id: number;
  };
  email: string;
  token: string;
  refreshToken: string;
};
