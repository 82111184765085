import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';

import { Brand } from 'app/models/brand.model';
import { ExtendedModel, Piece } from 'app/models/model.model';
import { Serie } from 'app/models/serie.model';

@Component({
  selector: 'printable-data-add-dumb',
  templateUrl: './printable-data-add-dumb.component.html',
  styleUrls: ['./printable-data-add-dumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintableDataAddDumbComponent implements OnChanges, OnInit, OnDestroy {
  @Input() brandList: Brand[];
  @Input() serieList: Serie[];
  @Input() modelList: ExtendedModel[];
  @Input() parsedSvg: Piece[];
  @Input() loadingBrands: boolean;
  @Input() loadingSeries: boolean;
  @Input() loadingModels: boolean;

  @Output() svgLoader = new EventEmitter();
  @Output() formSubmit = new EventEmitter();
  @Output() brandSelected = new EventEmitter();
  @Output() serieSelected = new EventEmitter();
  @Output() updateParsedSVGAndBbox = new EventEmitter();

  @ViewChild('file', { static: true }) file: ElementRef;

  brandListAutocomplete: Observable<Brand[]>;
  serieListAutocomplete: Observable<Serie[]>;
  modelListAutocomplete: Observable<ExtendedModel[]>;
  printableDataForm: FormGroup;

  disableBtn = false;
  droppedFile = null;
  types = ['PPF', 'VITRE'];
  private readonly unsubscribeSubject: Subject<void>;
  constructor() {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
  ngOnInit() {
    this.printableDataForm
      .get('brand')
      .valueChanges.pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(brand => {
        if (brand === '' || this.printableDataForm.controls['serie'].value) {
          this.changeFormControl('serie', false);
        }
      });

    this.printableDataForm
      .get('serie')
      .valueChanges.pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(serie => {
        if (serie === '' || this.printableDataForm.controls['model'].value) {
          this.changeFormControl('model', false);
        }
      });

    this.printableDataForm
      .get('model')
      .valueChanges.pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(model => {
        if (model === '' || this.printableDataForm.controls['type'].value) {
          this.changeFormControl('type', false);
        }
      });

    this.printableDataForm.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.unsubscribeSubject)).subscribe(form => {
      if (this.printableDataForm.controls['type'].value) {
        this.formSubmit.emit({ model: form['model'].id, type: form['type'] });
      }
    });
  }

  ngOnChanges() {
    if (!this.printableDataForm) {
      this.buildForm();
    }

    if (this.brandList) {
      this.brandListAutocomplete = this.printableDataForm.controls['brand'].valueChanges.pipe(
        startWith(null),
        map(brand => (brand ? this.filterBrands(brand) : this.brandList.slice())),
        takeUntil(this.unsubscribeSubject),
      );
    }

    if (this.serieList) {
      this.serieListAutocomplete = this.printableDataForm.controls['serie'].valueChanges.pipe(
        startWith(null),
        map(serie => (serie ? this.filterSeries(serie) : this.serieList.slice())),
        takeUntil(this.unsubscribeSubject),
      );
    }

    if (this.modelList) {
      this.modelListAutocomplete = this.printableDataForm.controls['model'].valueChanges.pipe(
        startWith(null),
        map(model => (model ? this.filterModels() : this.modelList.slice())),
        takeUntil(this.unsubscribeSubject),
      );
    }
  }

  buildForm() {
    this.printableDataForm = new FormGroup({
      brand: new FormControl(null, Validators.required),
      serie: new FormControl({ value: null, disabled: true }, Validators.required),
      model: new FormControl({ value: null, disabled: true }, Validators.required),
      type: new FormControl({ value: null, disabled: true }, Validators.required),
    });
  }

  filterBrands(brandName: string) {
    return this.brandList.filter(brand => brand.name.toLowerCase().indexOf(brandName.toLowerCase()) !== -1);
  }

  filterSeries(serieName: string) {
    return this.serieList.filter(serie => serie.name.toLowerCase().indexOf(serieName.toLowerCase()) !== -1);
  }

  filterModels() {
    return this.modelList.filter(model => model.name.toLowerCase().indexOf(model.name.toLowerCase()) !== -1);
  }

  selectBrand(brand: MatOptionSelectionChange) {
    if (brand.isUserInput) {
      this.changeFormControl('serie', true);
      this.brandSelected.emit(brand.source.value);
    }
  }

  selectSerie(serie: MatOptionSelectionChange) {
    if (serie.isUserInput) {
      this.changeFormControl('model', true);
      this.serieSelected.emit(serie.source.value);
    }
  }

  selectModel(model: MatOptionSelectionChange) {
    if (model.isUserInput) {
      this.changeFormControl('type', true);
    }
  }

  changeFormControl(field: string, enable: boolean) {
    this.printableDataForm.controls[field].setValue('');
    if (enable) {
      this.printableDataForm.controls[field].enable();
      this.printableDataForm.controls[field].markAsUntouched();
    } else {
      this.printableDataForm.controls[field].disable();
    }
  }

  getFile(event: Event) {
    const file = (event.target as HTMLInputElement).files;
    if (this.file.nativeElement.value.indexOf('.svg') !== -1) {
      this.svgLoader.emit(file[0]);
      this.disableBtn = true;
      this.droppedFile = file[0];
    }
    this.file.nativeElement.value = '';
    // to empty the file when we use it
  }

  // TODO event has type DragDropData
  dropFile(event: any) {
    if (!this.droppedFile) {
      if (event.mouseEvent.dataTransfer.files[0].type === 'image/svg+xml') {
        this.droppedFile = event.mouseEvent.dataTransfer.files[0];
        this.svgLoader.emit(this.droppedFile);
        this.disableBtn = true;
      }
    }
  }

  bubbleUpdate(event) {
    this.updateParsedSVGAndBbox.emit(event);
  }

  displayModels(model) {
    return model ? model.name : model;
  }
}
