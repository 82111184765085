import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Brand } from '../models/brand.model';
import { ExtendedSerie } from '../models/serie.model';

@Component({
  selector: 'serie-form',
  templateUrl: './serie-form.component.html',
  styleUrls: ['./serie-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SerieFormComponent implements OnChanges, OnDestroy {
  @Input() brandList: Brand[];
  @Input() serie: ExtendedSerie;

  @Output() formSubmit = new EventEmitter();

  serieForm: FormGroup;
  ngUnsubscribe: Subscription[] = [];

  ngOnChanges() {
    this.buildForm();
    this.ngUnsubscribe.push(
      this.serieForm.valueChanges.subscribe(form => {
        this.formSubmit.emit({ brandId: form.brand, name: form.name });
      }),
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.forEach(res => res.unsubscribe);
  }

  buildForm() {
    this.serieForm = new FormGroup({
      brand: new FormControl(this.serie?.brand?.id, Validators.required),
      name: new FormControl(this.serie.name, Validators.required),
    });
  }
}
