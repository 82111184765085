import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { AuxiliaryRouteClass } from '../app-interfaces/close-dialog.interface';
import { HandleErrorsService, ModelService } from '../app-services';
import { Brand } from '../models/brand.model';
import { Serie } from '../models/serie.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'edit-serie-dialog',
  templateUrl: './edit-serie-dialog.component.html',
  styleUrls: ['./edit-serie-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSerieDialogComponent extends AuxiliaryRouteClass implements OnInit {
  brandList = new ReplaySubject<Brand[]>(1);
  loading = new ReplaySubject<boolean>(1);

  formInfo = null;
  queryParams: any;

  constructor(
    public router: Router,
    protected route: ActivatedRoute,
    protected modelService: ModelService,
    protected snackBar: MatSnackBar,
    protected translateService: TranslateService,
    protected handleErrors: HandleErrorsService,
    @Inject(MAT_DIALOG_DATA) public serie: Serie,
  ) {
    super(router);
  }

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;

    this.modelService.getBrandList().subscribe({
      next: res => {
        this.brandList.next(res.data.listing);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  handleFormInfo(formInfo: { brand: number; name: string }) {
    this.formInfo = formInfo;
  }

  updateSerie() {
    this.loading.next(true);
    this.modelService.updateSerie(this.serie.id, { brandId: this.formInfo.brandId, name: this.formInfo.name.trim() }).subscribe({
      next: () => {
        this.translateService.get('serie-update-success').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
        this.leaveDialog(this.queryParams);
      },
      error: err => {
        this.loading.next(false);
        this.handleErrors.handleErrors(err);
      },
    });
  }
}
