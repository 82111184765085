import { Pipe, PipeTransform } from '@angular/core';
import { Country } from 'country-state-city';

@Pipe({
  name: 'countryName',
})
export class CountryNamePipe implements PipeTransform {
  transform(value: string): string {
    return Country.getCountryByCode(value)?.name ?? '-';
  }
}
