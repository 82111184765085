<h2 mat-dialog-title>{{ 'edit-model' | translate }}: {{ model.name }}</h2>
<mat-dialog-content>
  <model-form #modelForm [serieList]="serieList | async" [model]="model" (modelInfoSubmit)="handleModelInfo($event)"></model-form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="accent" (click)="updateModel()" [disabled]="modelForm.modelForm.invalid || (progress | async)">
    {{ (progress | async) ? (progress | async) + '%' : ('edit' | translate) }}
  </button>
  <button mat-raised-button color="warn" (click)="leaveDialog(queryParams)">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
