import { Component, Inject } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { HandleErrorsService } from '../app-services';
import { CheckoutService } from '../app-services/checkout.service';
import { map, switchMap, tap } from 'rxjs';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentDocument } from '../models/payment-document.model';
import { PaymentDocumentService } from 'app/app-services/payment-document.service';

@Component({
  selector: 'confirm-cancel-unpaid-payment-document',
  templateUrl: './confirm-cancel-unpaid-payment-document.component.html',
})
export class ConfirmCancelUnpaidPaymentDocumentComponent {
  loading = new ReplaySubject<boolean>(1);
  private stripe: Stripe;
  constructor(
    protected checkoutService: CheckoutService,
    private readonly paymentDocumentService: PaymentDocumentService,
    protected snackBar: MatSnackBar,
    protected dialogRef: MatDialogRef<ConfirmCancelUnpaidPaymentDocumentComponent>,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public payment_document: PaymentDocument,
  ) {}

  confirm() {
    this.checkoutService
      .getStripePublishableKey()
      .pipe(
        map(response => response.data.publishableKey),
        switchMap(stripePublicKey => loadStripe(stripePublicKey)),
        tap(stripe => {
          this.stripe = stripe;
        }),
        switchMap(() => this.checkoutService.retriveClientSecret(this.payment_document.id)),
        switchMap(res => this.stripe.retrievePaymentIntent(res.data.clientSecret)),
        switchMap(res => this.paymentDocumentService.cancelPaymentDocument(res.paymentIntent.id)),
      )
      .subscribe({
        next: () => {
          this.translateService.get('payment-document-cancel-success').subscribe(message => {
            this.snackBar.open(message, 'OK', { duration: 7000 });
          });
          this.dialogRef.close();
        },
        error: err => {
          this.loading.next(false);
          this.handleErrors.handleErrors(err);
        },
      });
  }

  cancel() {
    this.dialogRef.close();
  }
}
