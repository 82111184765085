import { Params, Router } from '@angular/router';

export abstract class AuxiliaryRouteClass {
  constructor(protected router: Router) {}

  leaveDialog(queryParams?: Params) {
    if (queryParams) {
      this.router.navigate([{ outlets: { dialog: null } }], { queryParams });
    } else {
      this.router.navigate([{ outlets: { dialog: null } }]);
    }
  }
}

export interface CloseDialogInterface {
  dialogRef: any;
  closeDialog(): boolean;
}
