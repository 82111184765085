import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CloseDialogInterface } from '../app-interfaces/close-dialog.interface';
import { HandleErrorsService, ModelAdminService } from '../app-services';
import { EditModelAdminDialogComponent } from '../edit-model-admin-dialog/edit-model-admin-dialog.component';

@Component({
  selector: 'edit-model-admin',
  templateUrl: './edit-model-admin.component.html',
  styleUrls: ['./edit-model-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditModelAdminComponent implements OnInit, CloseDialogInterface {
  dialogRef: any;

  constructor(
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected modelAdminService: ModelAdminService,
    protected handleErrors: HandleErrorsService,
  ) {}

  ngOnInit() {
    const id: number = this.route.snapshot.params['id'];

    this.modelAdminService.getModelAdmin(id).subscribe({
      next: response => {
        const config: MatDialogConfig = { width: '60%', disableClose: true, data: response.data };
        this.dialogRef = this.dialog.open(EditModelAdminDialogComponent, config);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  closeDialog() {
    this.dialogRef.close();

    return true;
  }
}
