import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { Admin } from '../models/admin.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'transfer-users-form',
  templateUrl: './transfer-users-form.component.html',
  styleUrls: ['./transfer-users-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferUsersFormComponent implements OnInit, OnDestroy {
  @Input() adminList: ReplaySubject<Admin[]>;
  @Output() selectedAdmin = new EventEmitter();
  transferUsersForm: FormGroup;

  private readonly unsubscribeSubject: Subject<void>;
  constructor() {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
  ngOnInit() {
    this.transferUsersForm = new FormGroup({
      selectedAdmin: new FormControl(null, Validators.required),
    });
    this.transferUsersForm.valueChanges
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(form => this.selectedAdmin.emit(form.selectedAdmin));
  }
}
