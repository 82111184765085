import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CloseDialogInterface } from 'app/app-interfaces/close-dialog.interface';
import { StripePaymentInterfaceDialogComponent } from 'app/stripe-payment-interface-dialog/stripe-payment-interface-dialog.component';

@Component({
  selector: 'app-stripe-payment-interface',
  templateUrl: './stripe-payment-interface.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripePaymentInterfaceComponent implements OnInit, CloseDialogInterface {
  dialogRef: any;
  constructor(
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const paymentDocumentId: number = this.route.snapshot.params['paymentDocumentId'];
    const config: MatDialogConfig = { data: paymentDocumentId, disableClose: true };
    this.dialogRef = this.dialog.open(StripePaymentInterfaceDialogComponent, config);
  }
  closeDialog() {
    this.dialogRef.close();

    return true;
  }
}
