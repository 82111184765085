<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  <a mat-tab-link [routerLink]="['profile']" routerLinkActive #profileLink="routerLinkActive" [active]="profileLink.isActive">{{
    'profil' | translate
  }}</a>
  <spinner [route]="'/user-details/' + user.id + '/profile'"></spinner>
  <a mat-tab-link [routerLink]="['user-printing-log']" routerLinkActive #logLink="routerLinkActive" [active]="logLink.isActive">{{
    'cut-log' | translate
  }}</a>
  <a
    *ngIf="!authService.isSuperAdmin()"
    mat-tab-link
    [routerLink]="['/user-details/' + user.id + '/credit']"
    routerLinkActive
    #creditLink="routerLinkActive"
    [active]="creditLink.isActive"
    >{{ 'credit' | translate }}</a
  >

  <spinner [route]="'/user-details/' + user.id + '/user-printing-log'"></spinner>
</nav>

<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>
