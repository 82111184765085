import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Admin, adminCreditUpdate } from 'app/models/admin.model';
import { User } from 'app/models/user.model';
import { Subject, takeUntil } from 'rxjs';
import { omitBy } from 'lodash';

@Component({
  selector: 'user-credit-form',
  templateUrl: './user-credit-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCreditFormComponent implements OnInit, OnDestroy {
  @Input() admin: Admin;
  @Input() user: User;

  @Output() creditUpdate = new EventEmitter<adminCreditUpdate>();

  creditForm: FormGroup;

  private readonly unsubscribeSubject: Subject<void>;
  constructor() {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.creditForm = new FormGroup({
      allowedPPF: new FormControl({ value: this.user.allowedPPF, disabled: true }),
      allowedPPFExtra: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(this.admin.allowedPPF)]),
      allowedPPFFinal: new FormControl({ value: this.user.allowedPPF, disabled: true }),
      allowedWindows: new FormControl({ value: this.user.allowedWindows, disabled: true }),
      allowedWindowsExtra: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(this.admin.allowedWindows)]),
      allowedWindowsFinal: new FormControl({ value: this.user.allowedWindows, disabled: true }),
    });

    this.creditForm
      .get('allowedPPFExtra')
      .valueChanges.pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(() => {
        this.addForms({ initial: 'allowedPPF', extra: 'allowedPPFExtra', final: 'allowedPPFFinal' });
      });

    this.creditForm
      .get('allowedWindowsExtra')
      .valueChanges.pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(() => {
        this.addForms({ initial: 'allowedWindows', extra: 'allowedWindowsExtra', final: 'allowedWindowsFinal' });
      });
  }

  addForms(formsKey: { initial: string; extra: string; final: string }) {
    const initialValue: number = parseFloat(this.creditForm.get(formsKey.initial).value);
    const extraValue: number = parseFloat(this.creditForm.get(formsKey.extra).value);
    const finalValue = extraValue + initialValue;
    this.creditForm.get(formsKey.final).setValue(finalValue);
  }

  save() {
    const extraPPF = this.creditForm.get('allowedPPFExtra').value;
    const extraWindows = this.creditForm.get('allowedWindowsExtra').value;
    this.creditUpdate.emit(omitBy({ extraPPF, extraWindows }, (value: number) => value === 0));
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
