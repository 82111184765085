import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BrandQuery } from 'app/app-services/model.service';
import { Brand } from 'app/models/brand.model';
import { EntityList } from 'app/models/entity-list.model';
import { Observable, ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { HandleErrorsService, ModelService } from '../app-services';
import { DeleteBrandDialogComponent } from '../delete-brand-dialog/delete-brand-dialog.component';
import { SearchEvent } from 'app/models/search-event.model';

@Component({
  selector: 'brand-management',
  templateUrl: './brand-management.component.html',
  styleUrls: ['./brand-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandManagementComponent implements OnInit, OnDestroy {
  brands = new ReplaySubject<EntityList<Brand>>(1);
  brandsAutoComplete = new ReplaySubject<Brand[]>(1);
  brandName$: Observable<string>;
  offset$ = of(0);

  private readonly unsubscribeSubject: Subject<void>;

  constructor(
    protected modelService: ModelService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected router: Router,
    protected handleErrors: HandleErrorsService,
  ) {
    this.unsubscribeSubject = new Subject<void>();
  }
  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams, this.modelService.updateBrand.pipe(startWith(null))])
      .pipe(
        switchMap(([params, queryParams]) => {
          const query = {
            limit: 10,
            offset: 0,
          };

          if (params['page']) {
            query['offset'] = (+params['page'] - 1) * 10;
            this.offset$ = of(+params['page'] - 1);
          }

          if (queryParams['brandName']) {
            query['brandName'] = queryParams['brandName'];
            this.brandName$ = of(queryParams['brandName']);
          }

          if (queryParams['exactSearch']) {
            query['exactSearch'] = queryParams['exactSearch'];
          }

          return this.modelService.getBrandList(query);
        }),
        takeUntil(this.unsubscribeSubject),
      )
      .subscribe({
        next: res => {
          this.brands.next(res.data);
        },

        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }

  pageNavigation(event: SearchEvent) {
    this.route.queryParams.pipe(takeUntil(this.unsubscribeSubject)).subscribe((queryParams: Params) => {
      if (!Object.keys(queryParams).length) {
        this.router.navigate(['/management/brand-management', event.offset + 1]);
      } else {
        this.router.navigate(['/management/brand-management', event.offset + 1], {
          queryParams: { brandName: queryParams['BrandName'] },
        });
      }
    });
  }

  filterBrands(brandName: string) {
    this.router.navigate(['/management/brand-management'], { queryParams: { brandName } });
  }

  fetchBrands(query: BrandQuery) {
    this.modelService.getBrandList(query).subscribe({
      next: response => {
        this.brandsAutoComplete.next(response.data.listing);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  deleteBrand(brandId: number) {
    const config: MatDialogConfig = { data: brandId, width: '60%', disableClose: true };
    this.dialog.open(DeleteBrandDialogComponent, config);
  }

  navigate(brandId?: number) {
    const queryParams = this.route.snapshot.queryParams;
    const link = brandId ? ['/', { outlets: { dialog: ['edit-brand', brandId] } }] : ['/', { outlets: { dialog: ['add-brand'] } }];
    this.router.navigate(link, { queryParams });
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
