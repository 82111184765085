import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'request-password-reset-form',
  templateUrl: './request-password-reset-form.component.html',
  styleUrls: ['./request-password-reset-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestPasswordResetFormComponent implements OnChanges, OnInit {
  @Input() loading: boolean;
  @Output() requestPasswordReset = new EventEmitter();
  requestResetPasswordForm: FormGroup;
  disabled = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.buildForm();
  }

  ngOnChanges() {
    this.disabled = this.loading === false ? true : false;
  }

  buildForm() {
    this.requestResetPasswordForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  sendRequest() {
    this.requestPasswordReset.emit({
      email: this.requestResetPasswordForm.controls['email'].value,
      scope: this.route.snapshot.params['scope'],
    });
  }
}
