<div class="contents">
  @if (loggings) {
    <div>
      <ngx-datatable
        [rows]="loggings?.listing"
        [footerHeight]="30"
        [columnMode]="'force'"
        [rowHeight]="'auto'"
        (page)="handlePages($event)"
        [limit]="10"
        [externalPaging]="true"
        [count]="loggings?.total"
        [offset]="offset"
        [messages]="messages$ | async"
      >
        <ngx-datatable-column [name]="'user' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="row?.user ? ['/user-details', row?.user?.id, 'profile'] : null">
              {{ row?.user?.fullName }}
            </a>
            <spinner [route]="'/user-details/' + row.id + '/profile'"></spinner>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'credit-type' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.extra.creditType }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'quantity' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.extra.value | number: '1.2-2' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'event-type' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.eventType }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'date' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span [matTooltip]="row?.createdAt | date: 'dd/MM/yyyy, HH:mm'">{{ row?.createdAt | date: 'dd/MM, HH:mm' }}</span>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  } @else {
    <div>
      <mat-spinner></mat-spinner>
    </div>
  }
</div>
