import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../app-services';
import { Admin } from '../models/admin.model';
import { phoneValidator } from '../validators/phone.validator';
import { Role } from 'app/models/loggin.model';
import { AvailableLanguages } from 'app/models/available-languages.const';
import { Address } from 'app/models/address.model';

@Component({
  selector: 'admin-profile-form',
  templateUrl: './admin-profile-form.component.html',
  styleUrls: ['./admin-profile-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminProfileFormComponent implements OnChanges {
  @Input() loading: boolean;
  @Input() admin: Admin;
  @Output() formSubmit = new EventEmitter<any>();
  @Output() changeCanceling = new EventEmitter<any>();
  adminForm: FormGroup;
  availableLanguages = AvailableLanguages;

  constructor(protected authService: AuthService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.adminForm) {
      this.buildForm();
    }

    if (changes.admin?.currentValue) {
      this.adminForm.patchValue(changes.admin.currentValue);
      this.adminForm.markAsPristine();
    }
  }

  buildForm() {
    this.adminForm = new FormGroup({
      fullName: new FormControl({ value: null, disabled: true }),
      address: new FormControl(null, Validators.required),
      country: new FormControl(null, Validators.required),
      phone: new FormControl(null, [Validators.required, phoneValidator]),
      email: new FormControl(
        null,
        [Validators.required, Validators.email],
        this.authService.isUniqueEmailValidator(Role.ADMIN, this.admin.email),
      ),
      language: new FormControl(null, Validators.required),
      allowedPPF: new FormControl({ value: null, disabled: true }),
      allowedWindows: new FormControl({ value: null, disabled: true }),
    });
  }

  submitForm() {
    this.formSubmit.emit(this.adminForm.value);
  }

  updateAddressValue(value: Address) {
    this.adminForm.patchValue(value);
    this.adminForm.markAsDirty();
  }

  cancelUpdate() {
    this.changeCanceling.emit(null);
  }
}
