import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Admin } from '../models/admin.model';

@Component({
  selector: 'admin-details',
  templateUrl: './admin-details.component.html',
  styleUrls: ['./admin-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminDetailsComponent implements OnInit {
  adminDetails: Admin;

  constructor(protected route: ActivatedRoute) {}

  ngOnInit() {
    this.adminDetails = this.route.snapshot.data['adminDetails'];
  }
}
