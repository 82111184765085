import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Brand } from 'app/models/brand.model';
import { ConfigService } from 'app/services/config/config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'brand-form',
  templateUrl: './brand-form.component.html',
  styleUrls: ['./brand-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandFormComponent implements OnChanges, OnInit, OnDestroy {
  @Input() brand: Brand;
  @Output() brandInfoSubmit = new EventEmitter();
  cropWidth = 150;
  cropHeight = 150;

  showLogo: boolean;
  brandForm: FormGroup;
  file: File = null;
  logo: File = null;

  private readonly unsubscribeSubject: Subject<void>;

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
  apiUrl: string;

  constructor(private readonly configService: ConfigService) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnChanges() {
    this.buildForm();
    this.brandForm.controls['name'].valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe(name => {
      this.brandInfoSubmit.emit({ name: name, logo: this.brand.logo ? this.brand.logo : this.logo });
    });
    this.showLogo = !this.file && this.brand.logo ? true : false;
  }

  ngOnInit() {
    this.apiUrl = this.configService.getApiUrl();
  }

  buildForm() {
    this.brandForm = new FormGroup({
      name: new FormControl(this.brand ? this.brand.name : null, Validators.required),
      logo: new FormControl(this.brand ? this.brand.logo : null, Validators.required),
    });
  }

  addLogo(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    this.file = files[0];
    this.showLogo = false;
    this.brandInfoSubmit.emit({
      name: this.brandForm.controls['name'].value,
      logo: files[0],
    });
  }

  handleCroppedImage(logo: File) {
    this.logo = logo;
    this.brandInfoSubmit.emit({
      name: this.brandForm.controls['name'].value,
      logo: logo,
    });
  }
}
