import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subject, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { PaymentDocument, PaymentDocumentQuery, Response, StripeKey } from 'app/models/payment-document.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentDocumentService {
  update = new Subject<boolean>();
  constructor(private _http: HttpClient) {}

  getPaymentDocumentList(query: PaymentDocumentQuery = {}): Observable<Response> {
    const route = {
      ids: 'ids',
      adminIds: 'admin-ids',
      startDate: 'start-date',
      endDate: 'end-date',
      offset: 'offset',
      limit: 'limit',
      exclude: 'exclude',
      next: 'next',
      type: 'type',
      status: 'status',
    };
    const questionMark = Object.keys(query).length > 0 ? '?' : '';
    const url =
      '/api/payment-document/super-admin' +
      questionMark +
      Object.keys(query)
        .map(k =>
          Array.isArray(query[k]) ? query[k].map((value, index) => `${route[k]}[${index}]=${value}`).join('&') : `${route[k]}=${query[k]}`,
        )
        .join('&');

    return this._http.get(url, { observe: 'response' }).pipe(
      map((res: HttpResponse<Response>) => {
        return res.body;
      }),
    );
  }

  getAdminPaymentDocumentList(query: PaymentDocumentQuery = {}): Observable<Response> {
    const route = {
      ids: 'ids',
      startDate: 'start-date',
      endDate: 'end-date',
      offset: 'offset',
      limit: 'limit',
      exclude: 'exclude',
      next: 'next',
      type: 'type',
      status: 'status',
    };
    const questionMark = Object.keys(query).length > 0 ? '?' : '';
    const url =
      '/api/payment-document/admin' +
      questionMark +
      Object.keys(query)
        .map(k =>
          Array.isArray(query[k]) ? query[k].map((value, index) => `${route[k]}[${index}]=${value}`).join('&') : `${route[k]}=${query[k]}`,
        )
        .join('&');

    return this._http.get(url, { observe: 'response' }).pipe(
      map((res: HttpResponse<Response>) => {
        return res.body;
      }),
    );
  }

  addPaymentDocument(paymentDocument: PaymentDocument) {
    return this._http.post('/api/payment-document', paymentDocument, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number; data: PaymentDocument }>) => {
        this.update.next(true);

        return res.body;
      }),
    );
  }

  cancelPaymentDocument(payment_intent: string): Observable<{ statusCode: number; data: StripeKey }> {
    return this._http.post('/api/cancel-payment-intent', payment_intent, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number; data: StripeKey }>) => {
        this.update.next(true);

        return res.body;
      }),
    );
  }
}
