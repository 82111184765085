import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuxiliaryRouteClass } from 'app/app-interfaces/close-dialog.interface';
import { AdminService, HandleErrorsService } from 'app/app-services';
import { PaymentDocumentService } from 'app/app-services/payment-document.service';
import { Admin } from 'app/models/admin.model';
import { PaymentDocument } from 'app/models/payment-document.model';
import { Observable, ReplaySubject, Subject, map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'add-payment-document-dialog',
  templateUrl: './add-payment-document-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPaymentDocumentDialogComponent extends AuxiliaryRouteClass implements OnInit, OnDestroy {
  paymentDocumentInfo: PaymentDocument = null;
  loading = new ReplaySubject<boolean>(1);
  adminList$: Observable<Admin[]>;
  queryParams: Params;
  private readonly unsubscribeSubject: Subject<void>;

  constructor(
    protected router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly handleErrors: HandleErrorsService,
    private readonly paymentDocumentService: PaymentDocumentService,
    private readonly adminService: AdminService,
    private readonly translateService: TranslateService,
    private readonly route: ActivatedRoute,
  ) {
    super(router);
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;
    this.adminList$ = this.adminService.getAdminList().pipe(
      map(response =>
        response.data?.listing.map(admin => ({
          ...admin,
          allowedPPF: Number(admin.allowedPPF),
          allowedWindows: Number(admin.allowedWindows),
        })),
      ),
    );
  }

  getPaymentDocumentInfo(paymentDocument: PaymentDocument) {
    this.paymentDocumentInfo = paymentDocument;
  }

  addPaymentDocument() {
    this.loading.next(true);
    this.paymentDocumentService.addPaymentDocument(this.paymentDocumentInfo).subscribe({
      next: () => {
        this.translateService.get('payment-document-message-success').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
        this.leaveDialog(this.queryParams);
      },
      error: err => {
        this.loading.next(false);
        this.handleErrors.handleErrors(err);
      },
    });
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
