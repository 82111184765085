import { throwError as observableThrowError } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { HandleErrorsService, UsersService } from '../app-services';

@Injectable()
export class UserDetailsResolver {
  constructor(
    protected usersService: UsersService,
    protected handleErrors: HandleErrorsService,
    protected router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.usersService.getUserById(+route.params['id']).pipe(
      map(res => ({
        ...res.data,
        allowedPPF: Number(res.data.allowedPPF).toFixed(2),
        allowedWindows: Number(res.data.allowedWindows).toFixed(2),
      })),
      catchError(err => {
        this.handleErrors.handleErrors(err);
        this.router.navigate(['/users-management']);

        return observableThrowError(() => err);
      }),
    );
  }
}
