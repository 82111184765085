<h2>{{ 'models-management' | translate }}</h2>
<model-filter
  [brandList]="brandAutoCompleteList | async"
  [serieList]="serieAutoCompleteList | async"
  [modelList]="modelAutoCompleteList | async"
  [searchTerms]="searchTerms"
  (filterModels)="filterModels($event)"
  (fetchLists)="fetchLists($event)"
  (routeNavigate)="navigate($event)"
></model-filter>
<model-list
  [modelList]="modelList | async"
  [offset]="offset$ | async"
  (tablePagesNavigation)="pageNavigation($event)"
  (openDeleteModelDialog)="openDeleteModelDialog($event)"
  (routeNavigate)="navigate($event)"
></model-list>
