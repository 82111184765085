import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityList } from 'app/models/entity-list.model';
import { ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { HandleErrorsService, ModelService } from '../app-services';
import { DeleteModelDialogComponent } from '../delete-model-dialog/delete-model-dialog.component';
import { Brand } from '../models/brand.model';
import { ExtendedModel, Model } from '../models/model.model';
import { Serie } from '../models/serie.model';
import { SearchEvent } from 'app/models/search-event.model';

@Component({
  selector: 'model-management',
  templateUrl: './model-management.component.html',
  styleUrls: ['./model-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelManagementComponent implements OnInit, OnDestroy {
  modelList = new ReplaySubject<EntityList<ExtendedModel>>(1);
  modelAutoCompleteList = new ReplaySubject<Model[]>(1);
  brandAutoCompleteList = new ReplaySubject<Brand[]>(1);
  serieAutoCompleteList = new ReplaySubject<Serie[]>(1);
  searchTerms = { brandName: null, serieName: null, modelName: null };
  offset$ = of(0);

  private readonly unsubscribeSubject: Subject<void>;

  constructor(
    protected modelService: ModelService,
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    protected handleErrors: HandleErrorsService,
    protected router: Router,
  ) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams, this.modelService.updateModels.pipe(startWith(null))])
      .pipe(
        switchMap(([params, queryParams]) => {
          const query = {
            limit: 10,
            offset: 0,
          };

          if (!Object.keys(queryParams).length) {
            query['showParents'] = true;
          } else {
            if (queryParams['modelName'] && !queryParams['brandName'] && !queryParams['serieName']) {
              query['showParents'] = true;
            }
            if (queryParams['exactSearch']) {
              query['exactSearch'] = queryParams['exactSearch'];
            }
          }

          if (params['page']) {
            query['offset'] = (+params['page'] - 1) * 10;
            this.offset$ = of(+params['page'] - 1);
          }

          Object.keys(queryParams).map(k => {
            query[k] = queryParams[k];
            this.searchTerms[k] = queryParams[k];
          });

          return this.modelService.getModelList(query);
        }),
        takeUntil(this.unsubscribeSubject),
      )
      .subscribe({
        next: res => {
          this.modelList.next(res.data);
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }

  fetchLists(event: { type: string; name: string }) {
    const query = {};
    switch (event.type) {
      case 'brandName':
        event.name ? (query['brandName'] = event.name) : query;
        this.modelService
          .getBrandList(query)
          .pipe(distinctUntilChanged())
          .subscribe({
            next: response => {
              this.brandAutoCompleteList.next(response.data.listing);
            },
            error: err => {
              this.handleErrors.handleErrors(err);
            },
          });
        break;
      case 'serieName':
        event.name ? (query['serieName'] = event.name) : query;
        this.modelService
          .getSerieList(query)
          .pipe(distinctUntilChanged())
          .subscribe({
            next: response => {
              this.serieAutoCompleteList.next(response.data.listing);
            },
            error: err => {
              this.handleErrors.handleErrors(err);
            },
          });
        break;
      case 'modelName':
        event.name ? (query['modelName'] = event.name) : query;
        this.modelService
          .getModelList(query)
          .pipe(distinctUntilChanged())
          .subscribe({
            next: res => {
              this.modelAutoCompleteList.next(res.data.listing);
            },
            error: err => {
              this.handleErrors.handleErrors(err);
            },
          });
        break;
    }
  }

  filterModels(queryParams: { brandName: string; serieName: string; modelName: string }) {
    this.router.navigate(['/management/model-management'], { queryParams });
  }

  openDeleteModelDialog(modelId: number) {
    const config: MatDialogConfig = { data: modelId, width: '60%', disableClose: true };
    this.dialog.open(DeleteModelDialogComponent, config);
  }

  pageNavigation(event: SearchEvent) {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate(['/management/model-management', event.offset + 1], { queryParams });
  }

  navigate(modelId: number) {
    const queryParams = this.route.snapshot.queryParams;
    const link = modelId ? ['/', { outlets: { dialog: ['edit-model', modelId] } }] : ['/', { outlets: { dialog: ['add-model'] } }];
    this.router.navigate(link, { queryParams });
  }
}
