import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EntityList, Messages } from 'app/models/entity-list.model';

import { ModelAdmin } from '../models/model-admin.model';
import { MagicTranslateService } from 'app/app-services';

@Component({
  selector: 'model-admin-list',
  templateUrl: './model-admin-list.component.html',
  styleUrls: ['./model-admin-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelAdminListComponent implements OnInit {
  @Input() modelAdmins: EntityList<ModelAdmin>;
  @Output() delete = new EventEmitter();

  messages$: Messages;

  constructor(private readonly magicTranslateService: MagicTranslateService) {}

  ngOnInit() {
    this.messages$ = this.magicTranslateService.getMessage();
  }

  deleteDBAdmin(modelAdmin: ModelAdmin) {
    this.delete.emit(modelAdmin);
  }
}
