import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMenuComponent {
  @Input() menuList: { label: string; link: string }[];
  @Output() logoutEvent = new EventEmitter();

  onLogout() {
    this.logoutEvent.emit();
  }
}
