import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { HandleErrorsService, MagicTranslateService, SuperAdminService } from '../app-services';
import { SuperAdmin } from '../models/super-admin.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'super-admin-profile',
  templateUrl: './super-admin-profile.component.html',
  styleUrls: ['./super-admin-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuperAdminProfileComponent implements OnInit {
  superAdmin: SuperAdmin;
  loading = new ReplaySubject<boolean>(1);

  constructor(
    protected route: ActivatedRoute,
    protected superAdminService: SuperAdminService,
    protected snackBar: MatSnackBar,
    protected handleErrors: HandleErrorsService,
    private magicTranslateService: MagicTranslateService,
    protected translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.superAdmin = this.route.snapshot.data['superAdminProfile'][0];
  }

  updateSuperAdminProfile(updatedSuperAdmin: SuperAdmin) {
    this.loading.next(false);
    this.superAdminService
      .updateSuperAdmin({
        ...updatedSuperAdmin,
        id: this.superAdmin.id,
      })
      .subscribe({
        next: () => {
          this.magicTranslateService.updateUserLanguage(updatedSuperAdmin.language);
          this.loading.next(true);
          this.translateService.get('update-success').subscribe(message => {
            this.snackBar.open(message, 'OK', { duration: 7000 });
          });
        },
        error: err => {
          this.loading.next(true);
          this.handleErrors.handleErrors(err);
        },
      });
  }

  cancelModifications() {
    this.superAdmin = Object.assign({}, this.superAdmin); // temporaire
  }
}
