import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { AuxiliaryRouteClass } from '../app-interfaces/close-dialog.interface';
import { HandleErrorsService, ModelService, PrintableDataService } from '../app-services';
import { PrintableData } from '../models/model.model';
import { Printing } from '../models/printing.model';

@Component({
  selector: 'printing-visualisation-dialog',
  templateUrl: './printing-visualisation-dialog.component.html',
  styleUrls: ['./printing-visualisation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintingVisualisationDialogComponent extends AuxiliaryRouteClass implements OnInit {
  printableData = new ReplaySubject<PrintableData>(1);
  queryParams: any;

  constructor(
    protected modelService: ModelService,
    protected printableDataService: PrintableDataService,
    public router: Router,
    protected route: ActivatedRoute,
    protected handleErrors: HandleErrorsService,
    @Inject(MAT_DIALOG_DATA) public printing: Printing,
  ) {
    super(router);
  }

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;

    this.printableDataService.getPrintableData(this.printing[0].printableData.id).subscribe({
      next: response => {
        this.printableData.next(response.data);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }
}
