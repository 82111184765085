import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { AuthService } from '../app-services';
import { User } from '../models/user.model';
import { phoneValidator } from '../validators/phone.validator';
import { takeUntil } from 'rxjs/operators';
import { Role } from 'app/models/loggin.model';
import { AvailableLanguages } from 'app/models/available-languages.const';
import { Address } from 'app/models/address.model';

@Component({
  selector: 'user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserFormComponent implements OnChanges, OnInit, OnDestroy {
  @Input() user: User = { isActive: true } as User;
  @Input() canEdit: boolean;
  @Output() userActive = new EventEmitter<any>();
  userForm: FormGroup;
  isActiveMessage: string;
  availableLanguages = AvailableLanguages;

  private readonly unsubscribeSubject: Subject<void>;

  constructor(protected authService: AuthService) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.userForm) {
      this.buildForm();
    }

    if (changes.user?.currentValue) {
      this.userForm.patchValue(changes.user.currentValue);
    }

    this.toggleForm();
  }

  ngOnInit(): void {
    this.userForm.valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe(form => {
      this.isActiveMessage = form.isActive ? 'Utilisateur activé' : 'Utilisateur désactivé';
      this.userActive.emit(form);
    }),
      (this.isActiveMessage = this.user.isActive ? 'user-enabled' : 'user-disabled');
  }

  buildForm() {
    this.userForm = new FormGroup({
      fullName: new FormControl(null, Validators.required),
      address: new FormControl(null, Validators.required),
      country: new FormControl(null, Validators.required),
      phone: new FormControl(null, [Validators.required, phoneValidator]),
      email: new FormControl(
        null,
        [Validators.required, Validators.email],
        this.authService.isUniqueEmailValidator(Role.USER, this.user.email),
      ),
      isActive: new FormControl(false, Validators.required),
      language: new FormControl(null, Validators.required),
      allowedPPF: new FormControl(this.user.allowedPPF),
      allowedWindows: new FormControl(this.user.allowedWindows),
    });
  }

  toggleForm() {
    if (this.canEdit) {
      this.userForm.enable();
      this.userForm.controls.allowedWindows.disable();
      this.userForm.controls.allowedPPF.disable();
    } else {
      this.userForm.disable();
    }
  }

  updateAddressValue(value: Address) {
    this.userForm.patchValue(value);
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
