import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../app-services';
import { Title } from '@angular/platform-browser';
import { environment } from 'environments/environment';

@Component({
  selector: 'main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit {
  constructor(
    protected authService: AuthService,
    protected router: Router,
    private readonly titleService: Title,
  ) {}

  ngOnInit() {
    const env = environment.name === 'test';
    const role = this.authService.credentials.payload.role;
    this.titleService.setTitle(`${env ? 'Test - ' : ''} Magic cut (${role})`);

    if (this.router.url === '/') {
      const paths = {
        ADMIN: '/users-management',
        SUPER_ADMIN: '/admin-management',
        MODEL_ADMIN: '/printable-data-management',
      };

      this.router.navigate([paths[role]]);
    }
  }
}
