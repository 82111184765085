import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { AdminService, HandleErrorsService, MagicTranslateService } from '../app-services';
import { Admin } from '../models/admin.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminProfileComponent implements OnInit {
  admin: Admin;
  loading = new ReplaySubject<boolean>(1);

  constructor(
    protected route: ActivatedRoute,
    protected adminService: AdminService,
    protected snackBar: MatSnackBar,
    protected handleErrors: HandleErrorsService,
    private magicTranslateService: MagicTranslateService,
    protected translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.admin = this.route.snapshot.data['currentAdminData'];
  }

  updateAdmin(adminInfo: Admin) {
    this.loading.next(true);
    adminInfo.isActive = this.admin.isActive;
    this.adminService
      .updateAdmin({
        ...adminInfo,
        id: this.admin.id,
      })
      .subscribe({
        next: () => {
          this.magicTranslateService.updateUserLanguage(adminInfo.language);
          this.translateService.get('update-success').subscribe(message => {
            this.snackBar.open(message, 'OK', { duration: 7000 });
          });
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
        complete: () => {
          this.loading.next(false);
        },
      });
  }

  cancelUpdate() {
    this.admin = Object.assign({}, this.admin);
  }
}
