import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';

import { HttpEventType } from '@angular/common/http';
import { Brand } from 'app/models/brand.model';
import { AuxiliaryRouteClass } from '../app-interfaces/close-dialog.interface';
import { HandleErrorsService, ModelService } from '../app-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'add-brand-dialog',
  templateUrl: './add-brand-dialog.component.html',
  styleUrls: ['./add-brand-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddBrandDialogComponent extends AuxiliaryRouteClass implements OnDestroy, OnInit {
  progress = new ReplaySubject<number>(1);

  queryParams: any;
  newBrand: Brand = {
    name: null,
    logo: null,
  };
  private readonly unsubscribeSubject: Subject<void>;

  constructor(
    public router: Router,
    protected route: ActivatedRoute,
    protected dialogRef: MatDialogRef<AddBrandDialogComponent>,
    protected modelService: ModelService,
    protected snackBar: MatSnackBar,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
  ) {
    super(router);
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;
  }

  getBrandInfo(form: { name: string; logo: string }) {
    this.newBrand = form;
  }

  addBrand() {
    const o = { name: this.newBrand.name.trim(), file: this.newBrand.logo };
    this.modelService.addBrand(o).subscribe({
      next: event => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.progress.next(0);
            break;

          case HttpEventType.UploadProgress:
            if (event.total) {
              this.progress.next(Math.round((event.loaded / event.total) * 100));
            }
            break;

          case HttpEventType.Response:
            this.progress.next(100);
            this.leaveDialog(this.queryParams);
            this.translateService.get('brand-message-success').subscribe(message => {
              this.snackBar.open(message, 'OK', { duration: 7000 });
            });
            this.modelService.updateBrand.next({ operation: 'addBrand', value: [event.body.data] });
            break;
        }
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  cancel() {
    this.leaveDialog(this.queryParams);
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
