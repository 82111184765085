<h1 mat-dialog-title>
  <span>{{ 'change-password' | translate }}</span>
</h1>
<mat-dialog-content>
  <change-password-form #changePassword (formSubmit)="handlePasswords($event)"> </change-password-form>
</mat-dialog-content>
<mat-dialog-actions>
  @if (loading | async) {
    <mat-spinner></mat-spinner>
  }
  <button mat-raised-button color="accent" [disabled]="changePassword.passwordForm.invalid || (loading | async)" (click)="setPassword()">
    {{ 'edit' | translate }}
  </button>
  <button mat-raised-button color="warn" (click)="leaveDialog()">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
