import { HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { AuxiliaryRouteClass } from '../app-interfaces/close-dialog.interface';
import { HandleErrorsService, ModelService } from '../app-services';
import { Model } from '../models/model.model';
import { ExtendedSerie } from '../models/serie.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'add-model-dialog',
  templateUrl: './add-model-dialog.component.html',
  styleUrls: ['./add-model-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddModelDialogComponent extends AuxiliaryRouteClass implements OnInit, OnDestroy {
  serieList = new ReplaySubject<ExtendedSerie[]>(1);
  progress = new ReplaySubject<number>(1);

  model: Model = {
    serieId: null,
    name: null,
    picture: null,
    description: null,
  };
  queryParams: any;

  constructor(
    public router: Router,
    protected route: ActivatedRoute,
    protected snackBar: MatSnackBar,
    protected modelService: ModelService,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
  ) {
    super(router);
    this.unsubscribeSubject = new Subject<void>();
  }
  private readonly unsubscribeSubject: Subject<void>;

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;

    this.modelService
      .getSerieList({ showParents: true })
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe({
        next: response => {
          this.serieList.next(response.data.listing);
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }

  handleModelInfo(modelInfo: { form: { description: string; name: string; serie: number }; picture: string }) {
    this.model.name = modelInfo.form.name;
    this.model.serieId = modelInfo.form.serie;
    this.model.description = modelInfo.form.description;
    this.model.picture = modelInfo.picture;
  }
  addModel() {
    this.modelService
      .addModel({
        serieId: this.model.serieId,
        name: this.model.name.trim(),
        description: this.model.description,
        file: this.model.picture,
      })
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe({
        next: event => {
          switch (event.type) {
            case HttpEventType.Sent:
              this.progress.next(0);
              break;

            case HttpEventType.UploadProgress:
              if (event.total) {
                this.progress.next(Math.round((event.loaded / event.total) * 100));
              }
              break;

            case HttpEventType.Response:
              this.progress.next(100);
              this.translateService.get('model-message-success').subscribe(message => {
                this.snackBar.open(message, 'OK', { duration: 7000 });
              });
              this.leaveDialog(this.queryParams);
              this.modelService.updateModels.next({ operation: 'addModel', value: [event.body.data] });
              break;
          }
        },
        error: err => {
          this.progress.next(null);
          this.handleErrors.handleErrors(err);
        },
      });
  }

  cancel() {
    this.leaveDialog(this.queryParams);
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
