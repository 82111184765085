import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuxiliaryRouteClass } from '../app-interfaces/close-dialog.interface';
import { ReplaySubject } from 'rxjs';
import { PlotterInfo } from '../models/plotter.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlotterService } from '../app-services/plotter.service';
import { HandleErrorsService } from '../app-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'add-plotter-dialog',
  templateUrl: './add-plotter-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPlotterDialogComponent extends AuxiliaryRouteClass implements OnInit {
  loading = new ReplaySubject<boolean>(1);

  formInfo: PlotterInfo;

  queryParams: Params;

  constructor(
    public router: Router,
    protected route: ActivatedRoute,
    protected snackBar: MatSnackBar,
    protected plotterService: PlotterService,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
  ) {
    super(router);
  }

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;
  }

  handleFormInfo(formInfo: PlotterInfo) {
    this.formInfo = formInfo;
  }

  createPlotter() {
    this.loading.next(true);
    this.plotterService.addPlotter(this.formInfo).subscribe({
      next: () => {
        this.translateService.get('plotter_message_success').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
        this.leaveDialog(this.queryParams);
      },
      error: err => {
        this.loading.next(false);
        this.handleErrors.handleErrors(err);
      },
    });
  }
}
