<form [formGroup]="serieForm">
  <mat-form-field>
    <mat-label translate="brand" />
    <mat-select formControlName="brand">
      @for (brand of brandList; track brand) {
        <mat-option [value]="brand.id">
          {{ brand.name }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="serie-name" />
    <input matInput type="text" formControlName="name" updateOnBlur />
    @if (serieForm.controls['name'].hasError('required')) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
  </mat-form-field>
</form>
