import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityList } from 'app/models/entity-list.model';
import { omit } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Admin } from '../models/admin.model';
import { AuthService } from './auth.service';

@Injectable()
export class AdminService {
  update = new Subject<{ operation: string; value: Admin[] }>();

  constructor(
    private _http: HttpClient,
    protected authService: AuthService,
  ) {}

  addAdmin(admin: Admin): Observable<{ statusCode: number; data: Admin }> {
    return this._http.post('/api/admin', admin, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number; data: Admin }>) => {
        this.update.next({ operation: 'addAdmin', value: [res.body.data] });

        return res.body;
      }),
    );
  }

  deleteAdmin(adminId: number, replaceAdminId: number): Observable<{ statusCode: number }> {
    return this._http.delete('/api/admin/' + adminId + '/' + replaceAdminId, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number }>) => {
        this.update.next({ operation: 'deleteAdmin', value: [] });

        return res.body;
      }),
    );
  }

  getAdmin(adminId: number): Observable<{ statusCode: number; data: Admin }> {
    return this._http.get('/api/admin/' + adminId, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number; data: Admin }>) => {
        return res.body;
      }),
    );
  }

  getAdminList(query: AdminQuery = {}): Observable<{ statusCode: number; data: EntityList<Admin> }> {
    const route = { ids: 'ids', offset: 'offset', limit: 'limit', like: 'like', exclude: 'exclude' };
    const questionMark = Object.keys(query).length > 0 ? '?' : '';
    const url =
      '/api/admin/list' +
      questionMark +
      Object.keys(query)
        .map(k =>
          Array.isArray(query[k]) ? query[k].map((value, index) => `${route[k]}[${index}]=${value}`).join('&') : `${route[k]}=${query[k]}`,
        )
        .join('&');

    return this._http.get(url, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number; data: EntityList<Admin> }>) => {
        return res.body;
      }),
    );
  }

  setActivation(adminId: number, activation: boolean): Observable<{ statusCode: number; data?: Admin }> {
    return this._http.put('/api/admin/' + adminId, { isActive: activation }, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number; data: Admin }>) => {
        return res.body;
      }),
    );
  }

  updateAdmin(admin: Partial<Admin>): Observable<{ statusCode: number; data: Admin }> {
    const payload = this.authService.isSuperAdmin() ? admin : omit(admin, ['allowedPPF', 'allowedWindows', 'isActive']);

    return this._http.put('/api/admin', payload, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number; data: Admin }>) => {
        this.update.next({ operation: 'updateAdmin', value: [res.body.data] });

        return res.body;
      }),
    );
  }

  setPassword(passwords: { currentPassword: string; newPassword: string }): Observable<{ statusCode: number }> {
    return this._http.put('/api/admin/set-password', passwords, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number; data: Admin }>) => {
        return res.body;
      }),
    );
  }

  updateCredit(payload: { window: number; ppf: number; id: number }): Observable<Admin> {
    return this._http.put<{ data: Admin }>('/api/admin/credit', payload).pipe(map(response => response.data));
  }
}

export type AdminQuery = {
  ids?: number[];
  limit?: number;
  offset?: number;
  like?: string;
  exclude?: number[];
};
