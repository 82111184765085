import { Observable, Subject } from 'rxjs';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityList } from 'app/models/entity-list.model';
import { map } from 'rxjs/operators';
import { User, UserInfo } from '../models/user.model';

@Injectable()
export class UsersService {
  update = new Subject<{ operation: string; value: User[] }>();

  constructor(private _http: HttpClient) {}

  getUserById(userId: number): Observable<{ statusCode: number; data: User }> {
    return this._http.get('/api/user/' + userId, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: User; statusCode: number }>) => {
        return res.body;
      }),
    );
  }

  getUserList(query: UserQuery = {}): Observable<{ statusCode: number; data: EntityList<User> }> {
    const route = {
      userIds: 'ids',
      adminIds: 'admin-ids',
      offset: 'offset',
      limit: 'limit',
      exclude: 'exclude',
      like: 'like',
    };
    const questionMark = Object.keys(query).length > 0 ? '?' : '';
    const url =
      '/api/user/list' +
      questionMark +
      Object.keys(query)
        .map(k =>
          Array.isArray(query[k]) ? query[k].map((value, index) => `${route[k]}[${index}]=${value}`).join('&') : `${route[k]}=${query[k]}`,
        )
        .join('&');

    return this._http.get(url, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number; data: EntityList<User> }>) => {
        return res.body;
      }),
    );
  }

  addUser(newUser: UserInfo): Observable<{ statusCode: number; data: User }> {
    return this._http.post('/api/user', newUser, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: User; statusCode: number }>) => {
        this.update.next({ operation: 'addUser', value: [res.body.data] });

        return res.body;
      }),
    );
  }

  deleteUser(userId: number): Observable<{ statusCode: number }> {
    return this._http.delete('/api/user/' + userId, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number }>) => {
        this.update.next({ operation: 'addUser', value: [] });

        return { statusCode: res.body.statusCode };
      }),
    );
  }

  updateUser(userId: number, userData: UserInfo): Observable<{ statusCode: number; data: User }> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { allowedPPF, allowedWindows, ...userDataNoCredit } = userData;

    return this._http.put('/api/user', { ...userDataNoCredit, id: userId }, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: User; statusCode: number }>) => {
        this.update.next({ operation: 'addUser', value: [res.body.data] });

        return res.body;
      }),
    );
  }

  setActivation(id: number, status: boolean): Observable<{ statusCode: number; data: User }> {
    return this._http.put('/api/user', { isActive: status, id }, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: User; statusCode: number }>) => {
        return res.body;
      }),
    );
  }
  updateCredit(payload: { window: number; ppf: number; id: number }) {
    return this._http.put('/api/user/credit', payload, { observe: 'response' }).pipe(
      map(
        (
          res: HttpResponse<{
            data: {
              user: User;
            };
            statusCode: number;
          }>,
        ) => {
          return res.body;
        },
      ),
    );
  }
}

export type UserQuery = {
  adminIds?: number[];
  userIds?: number[];
  offset?: number;
  limit?: number;
  like?: string;
  exclude?: number[];
};
