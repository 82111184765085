import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Brand } from '../models/brand.model';
import { Model } from '../models/model.model';
import { Serie } from '../models/serie.model';

@Component({
  selector: 'model-filter',
  templateUrl: './model-filter.component.html',
  styleUrls: ['./model-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelFilterComponent implements OnChanges, OnInit, OnDestroy {
  @Input() modelList: Model[];
  @Input() brandList: Brand[];
  @Input() serieList: Serie[];
  @Input() searchTerms: { brandName: string; serieName: string; modelName: string };

  @Output() filterModels = new EventEmitter();
  @Output() fetchLists = new EventEmitter<{ type: string; name: string }>();
  @Output() routeNavigate = new EventEmitter();

  filterForm: FormGroup;
  private readonly unsubscribeSubject: Subject<void>;
  constructor() {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
  ngOnInit() {
    Object.keys(this.filterForm.value).map(k => {
      this.filterForm.controls[k].valueChanges
        .pipe(debounceTime(250), distinctUntilChanged(), takeUntil(this.unsubscribeSubject))
        .subscribe(name => {
          this.fetchLists.emit({ type: k, name: name.trim() });
        });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.filterForm) {
      this.buildForm();
    }

    if (changes['searchTerms'] && changes['searchTerms'].previousValue === undefined && changes['searchTerms'].currentValue) {
      Object.keys(this.filterForm.value).map(k => {
        this.filterForm.get(k).setValue(this.searchTerms[k]);
      });
    }
  }

  search() {
    const model = this.filterForm.controls['modelName'].value;
    const serie = this.filterForm.controls['serieName'].value;
    const brand = this.filterForm.controls['brandName'].value;
    this.filterModels.emit({
      brandName: brand ? brand.trim() : null,
      serieName: serie ? serie.trim() : null,
      modelName: model ? model.trim() : null,
      showParents: true,
    });
  }

  buildForm() {
    this.filterForm = new FormGroup({
      brandName: new FormControl(null),
      serieName: new FormControl(null),
      modelName: new FormControl(null),
    });
  }

  navigate() {
    this.routeNavigate.emit(null);
  }
}
