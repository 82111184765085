import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Brand } from 'app/models/brand.model';
import { EntityList, Messages } from 'app/models/entity-list.model';
import { ConfigService } from '../services/config/config.service';
import { MagicTranslateService } from 'app/app-services';
import { SearchEvent } from 'app/models/search-event.model';

@Component({
  selector: 'brand-list',
  templateUrl: './brand-list.component.html',
  styleUrls: ['./brand-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandListComponent implements OnInit {
  @Input() brands: EntityList<Brand>;
  @Input() offset: number;

  @Output() brandDelete = new EventEmitter();
  @Output() tablePagesNavigation = new EventEmitter();
  @Output() routeNavigate = new EventEmitter();

  messages$: Messages;

  apiUrl: string;

  constructor(
    private readonly configService: ConfigService,
    private readonly magicTranslateService: MagicTranslateService,
  ) {}

  ngOnInit() {
    this.apiUrl = this.configService.getApiUrl();
    this.messages$ = this.magicTranslateService.getMessage();
  }

  deleteBrand(brandId: number) {
    this.brandDelete.emit(brandId);
  }

  handlePages(event: SearchEvent) {
    this.tablePagesNavigation.emit(event);
  }

  navigate(brandId: number) {
    this.routeNavigate.emit(brandId);
  }
}
