import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReplaySubject } from 'rxjs';
import { AdminService, HandleErrorsService } from '../app-services';
import { Admin } from '../models/admin.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'delete-admin-and-transfer-users',
  templateUrl: './delete-admin-and-transfer-users.component.html',
  styleUrls: ['./delete-admin-and-transfer-users.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteAdminAndTransferUsersComponent implements OnInit {
  otherAdmins = new ReplaySubject<Admin[]>(1);
  loading = new ReplaySubject<boolean>(1);
  selectedAdminId: number;

  constructor(
    protected adminService: AdminService,
    protected snackBar: MatSnackBar,
    protected dialogRef: MatDialogRef<DeleteAdminAndTransferUsersComponent>,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) protected admin: Admin,
  ) {}

  ngOnInit() {
    this.adminService.getAdminList().subscribe(result => {
      this.otherAdmins.next(result.data.listing.filter(a => a.id !== this.admin.id));
    });
  }

  selectAdmin(id: number) {
    this.selectedAdminId = id;
  }

  deleteAdmin() {
    this.loading.next(true);
    if (this.selectedAdminId) {
      this.adminService.deleteAdmin(this.admin.id, this.selectedAdminId).subscribe({
        next: () => {
          this.dialogRef.close();
          this.translateService.get('admin-delete-success').subscribe(message => {
            this.snackBar.open(message, 'OK', { duration: 7000 });
          });
        },
        error: err => {
          this.loading.next(false);
          this.handleErrors.handleErrors(err);
        },
      });
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
