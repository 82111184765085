<div class="contents">
  @if (printings) {
    <div>
      <ngx-datatable
        [rows]="printings?.listing"
        [footerHeight]="30"
        [columnMode]="'force'"
        [rowHeight]="'auto'"
        (page)="handlePages($event)"
        [limit]="10"
        [externalPaging]="true"
        [count]="printings?.total"
        [offset]="offset"
        [messages]="messages$ | async"
      >
        <ngx-datatable-column [name]="'user' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.user.fullName }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'model' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span
              [matTooltip]="
                row?.printableData?.model?.serie?.brand?.name +
                '/' +
                row?.printableData?.model?.serie?.name +
                '/' +
                row?.printableData?.model?.name
              "
              >{{ row?.printableData?.model?.serie?.brand?.name || 'deleted' }}/{{ row?.printableData?.model?.serie?.name || 'deleted' }}/{{
                row?.printableData?.model?.name || 'deleted'
              }}</span
            >
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'config' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ 'unit' | translate }}:{{ row?.plotterConfig?.unit }} | {{ 'plotter' | translate }}:
            {{ row?.plotterConfig?.switchAxis ? 'Roland' : 'Non-Roland' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [headerClass]="'pieces-cell'"
          [cellClass]="'pieces-cell'"
          [maxWidth]="200"
          [name]="'date' | translate"
          [sortable]="false"
          [draggable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span [matTooltip]="row?.createdAt | date: 'dd/MM/yyyy, HH:mm'">{{ row?.createdAt | date: 'dd/MM, HH:mm' }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [cellClass]="'pieces-cell'"
          [maxWidth]="150"
          [name]="'cut-number' | translate"
          [sortable]="false"
          [draggable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/', { outlets: { dialog: ['printing-visualisation', row?.id] } }]" [queryParams]="queryParams">
              {{ row?.printedPieces?.length + '/' + row?.printableData?.pieces?.length }}
            </a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [cellClass]="'pieces-cell'"
          [maxWidth]="150"
          [name]="'printed-area' | translate"
          [sortable]="false"
          [draggable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span [matTooltip]="row?.printedArea?.toString()">{{ row?.printedArea | floor: 2 }}</span>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  } @else {
    <div>
      <mat-spinner></mat-spinner>
    </div>
  }
</div>
