import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelQuery } from 'app/app-services/model.service';
import { EntityList } from 'app/models/entity-list.model';
import { EMPTY, Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HandleErrorsService, ModelService, PrintingService } from '../app-services';
import { Model } from '../models/model.model';
import { ExtendedPrinting, FilterQuery } from '../models/printing.model';
import { SearchEvent } from 'app/models/search-event.model';

@Component({
  selector: 'user-printing-log',
  templateUrl: './user-printing-log.component.html',
  styleUrls: ['./user-printing-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPrintingLogComponent implements OnInit {
  userId: number;
  printings = new ReplaySubject<EntityList<ExtendedPrinting>>(1);
  models = new ReplaySubject<Model[]>(1);
  offset$ = of(0);
  searchedDates = { endDate: null, startDate: null };
  searchedModels$: Observable<Model[]>;

  constructor(
    private readonly printingService: PrintingService,
    private readonly modelService: ModelService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly handleErrors: HandleErrorsService,
  ) {}

  ngOnInit() {
    this.userId = this.route.parent.snapshot.data['userDetails'].id;
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(
        switchMap(([params, queryParams]) => {
          const query = { userIds: [this.userId] };

          if (params['page']) {
            query['offset'] = (+params['page'] - 1) * 10;
            this.offset$ = of(+params['page'] - 1);
          }

          Object.keys(queryParams).map(k => {
            query[k] = k === 'modelIds' ? queryParams[k].split(',') : queryParams[k];
            if (k === 'modelIds') {
              this.searchedModels$ = this.modelService.getModelList({ ids: [queryParams['modelIds']] }).pipe(
                map(response => response.data.listing),
                catchError(error => {
                  this.handleErrors.handleErrors(error);

                  return EMPTY;
                }),
              );
            } else {
              this.searchedDates[k] = queryParams[k];
            }
          });

          return this.printingService.getPrintingList(query);
        }),
      )
      .subscribe({
        next: response => {
          this.printings.next(response.data);
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }

  fetchModelList(query: ModelQuery) {
    this.modelService.getModelList(query).subscribe({
      next: result => {
        this.models.next(result.data.listing);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  filterPrintings(queryParams: FilterQuery) {
    this.router.navigate(['/user-details/' + this.userId + '/user-printing-log'], { queryParams });
  }

  pageNavigation(event: SearchEvent) {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate(['/user-details/' + this.userId + '/user-printing-log', event.offset + 1], { queryParams });
  }
}
