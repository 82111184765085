<form [formGroup]="passwordForm">
  <mat-form-field>
    <mat-label translate="current-password" />
    <input matInput type="password" formControlName="currentPassword" updateOnBlur />
    @if (passwordForm.controls['currentPassword'].hasError('required')) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="new-password" />
    <input matInput type="password" formControlName="newPassword" updateOnBlur />
    @if (passwordForm.controls['newPassword'].hasError('required')) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="confirm-password" />
    <input matInput type="password" formControlName="confirmPassword" updateOnBlur />
    @if (passwordForm.controls['confirmPassword'].hasError('required')) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
  </mat-form-field>
  @if (!passwordForm.controls['confirmPassword'].hasError('required') && passwordForm.hasError('mismatch')) {
    <mat-error class="mismatch-error"> {{ 'mismatch' | translate }} </mat-error>
  }
</form>
