import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuxiliaryRouteClass } from 'app/app-interfaces/close-dialog.interface';
import { PaymentDocumentService } from 'app/app-services/payment-document.service';

@Component({
  selector: 'app-success-document-payment-dialog',
  templateUrl: './success-document-payment-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessDocumentPaymentDialogComponent extends AuxiliaryRouteClass implements OnInit {
  queryParams: any;
  constructor(
    public router: Router,
    private readonly paymentDocumentService: PaymentDocumentService,
    protected route: ActivatedRoute,
  ) {
    super(router);
  }

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;
  }

  leave() {
    this.paymentDocumentService.update.next(true);
    this.leaveDialog();
  }
}
