import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CloseDialogInterface } from '../app-interfaces/close-dialog.interface';
import { PrintingService } from '../app-services';
import { PrintingVisualisationDialogComponent } from '../printing-visualisation-dialog/printing-visualisation-dialog.component';

@Component({
  selector: 'printing-visualisation',
  templateUrl: './printing-visualisation.component.html',
  styleUrls: ['./printing-visualisation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintingVisualisationComponent implements OnInit, CloseDialogInterface {
  dialogRef: any;

  constructor(
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    protected printingService: PrintingService,
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.params['id'];
    this.printingService.getPrintingList({ ids: [id] }).subscribe(result => {
      const config: MatDialogConfig = { data: result.data.listing, width: '60%', disableClose: true };
      this.dialogRef = this.dialog.open(PrintingVisualisationDialogComponent, config);
    });
  }

  closeDialog() {
    this.dialogRef.close();

    return true;
  }
}
