<mat-toolbar>
  <form [formGroup]="adminFilterForm" novalidate>
    <mat-form-field class="admin-form-field" [subscriptSizing]="'dynamic'">
      <mat-chip-grid class="chips-list" formControlName="admins" #adminsList aria-label="Admins selection">
        @for (admin of adminFilterForm.get('admins').value; track admin) {
          <mat-chip-row class="chip" [value]="admin" (removed)="remove(admin)">
            {{ admin.fullName }}
            <button matChipRemove>
              <mat-icon svgIcon="ic_clear_24px"></mat-icon>
            </button>
          </mat-chip-row>
        }
        <input
          matInput
          [placeholder]="'admins' | translate"
          #adminInput
          formControlName="adminSearch"
          [matAutocomplete]="auto"
          [matChipInputFor]="adminsList"
        />
      </mat-chip-grid>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        @for (admin of filteredAdmins; track admin) {
          <mat-option [value]="admin">
            {{ admin.fullName }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    <button mat-mini-fab color="accent" (click)="search(adminFilterForm.value)">
      <mat-icon svgIcon="ic_search_24px"></mat-icon>
    </button>
  </form>
  <div class="add-btn">
    <a mat-raised-button color="accent" (click)="onAddAdmin()">
      <mat-icon svgIcon="ic_person_add_24px"></mat-icon>
      {{ 'add' | translate }}
    </a>
  </div>
</mat-toolbar>
