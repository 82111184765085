<h2 mat-dialog-title>{{ 'new-admin' | translate }}</h2>
<mat-dialog-content>
  <admin-form (adminValue)="getAdminInfo($event)" #adminForm></admin-form>
</mat-dialog-content>

<mat-dialog-actions>
  @if (loading | async) {
    <mat-spinner></mat-spinner>
  }
  <button mat-raised-button color="accent" (click)="addAdmin()" [disabled]="adminForm.form.status !== 'VALID' || (loading | async)">
    {{ 'add' | translate }}
  </button>
  <button mat-raised-button color="warn" (click)="leaveDialog(queryParams)">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
