import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { AuxiliaryRouteClass } from '../app-interfaces/close-dialog.interface';
import { HandleErrorsService, ModelAdminService } from '../app-services';
import { ModelAdmin } from '../models/model-admin.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'edit-model-admin-dialog',
  templateUrl: './edit-model-admin-dialog.component.html',
  styleUrls: ['./edit-model-admin-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditModelAdminDialogComponent extends AuxiliaryRouteClass {
  updatedModelAdmin: any;
  loading = new ReplaySubject<boolean>(1);

  constructor(
    public router: Router,
    protected modelAdminService: ModelAdminService,
    protected snackBar: MatSnackBar,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public modelAdmin: ModelAdmin,
  ) {
    super(router);
  }

  handleModelAdminInfo(modelAdminInfo: { fullName: string; email: string }) {
    this.updatedModelAdmin = modelAdminInfo;
  }

  updateModelAdmin() {
    this.loading.next(true);
    this.modelAdminService.updateModelAdmin({ ...this.updatedModelAdmin, id: this.modelAdmin.id }).subscribe({
      next: () => {
        this.translateService.get('model-admin-update-success').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
        this.leaveDialog();
      },
      error: err => {
        this.loading.next(false);
        this.handleErrors.handleErrors(err);
      },
    });
  }
}
