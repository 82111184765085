import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Banner } from '../models/banner.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  updateBanner = new Subject<{ operation: string; value: Banner[] }>();

  constructor(
    private _http: HttpClient,
    protected authService: AuthService,
  ) {}

  getBanner(id: number): Observable<Banner> {
    const url = this.authService.isSuperAdmin() ? '/api/banner?adminId=' + id : '/api/banner';

    return this._http.get(url, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: Banner; statusCode: number }>) => {
        return res.body.data;
      }),
    );
  }

  addBanner(newBanner: Banner): Observable<void> {
    return this._http.post<void>(
      '/api/banner',
      { adminId: newBanner.adminId, file: newBanner.picture },
      {
        headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
      },
    );
  }

  editBanner(bannerId, banner: Partial<Banner>): Observable<void> {
    return this._http.put<void>(
      `/api/banner`,
      { id: bannerId, file: banner.picture },
      {
        headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
      },
    );
  }
}
