<form [formGroup]="userForm">
  <div class="user-form-row-1">
    <mat-form-field>
      <mat-label translate="full-name" />
      <input matInput type="text" formControlName="fullName" updateOnBlur />
      @if (userForm.controls['fullName'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
    </mat-form-field>

    <div class="is-active-toggle">
      <mat-slide-toggle formControlName="isActive"></mat-slide-toggle>
      {{ isActiveMessage | translate }}
    </div>
  </div>

  <address-form
    [address]="{ country: user.country, address: user.address }"
    [canEdit]="canEdit"
    (addressValue)="updateAddressValue($event)"
  ></address-form>

  <div class="user-form-row-3">
    <mat-form-field>
      <mat-label translate="email" />
      <input matInput type="email" formControlName="email" updateOnBlur />
      @if (userForm.controls['email'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
      @if (userForm.controls['email'].hasError('email') && !userForm.controls['email'].hasError('required')) {
        <mat-error> {{ 'email-validation' | translate }} </mat-error>
      }
      @if (userForm.controls['email'].hasError('unique')) {
        <mat-error> {{ 'used' | translate }} </mat-error>
      }
    </mat-form-field>
    <mat-form-field>
      <mat-label translate="phone" />
      <input matInput type="text" formControlName="phone" updateOnBlur />
      @if (userForm.controls['phone'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
      @if (userForm.controls['phone'].hasError('phone')) {
        <mat-error> {{ 'phone-validation' | translate }} </mat-error>
      }
    </mat-form-field>
  </div>

  <div class="user-form-row-4">
    <mat-form-field>
      <mat-label translate="allowedPPF" />
      <input matInput type="number" min="0" max="500" formControlName="allowedPPF" updateOnBlur />
    </mat-form-field>

    <mat-form-field>
      <mat-label translate="allowedWindows" />
      <input matInput type="number" formControlName="allowedWindows" />
    </mat-form-field>

    <mat-form-field>
      <mat-label translate="language" />
      <mat-select formControlName="language">
        @for (language of availableLanguages; track language) {
          <mat-option [value]="language.code">
            {{ language.label }}
          </mat-option>
        }
      </mat-select>
      @if (userForm.controls['language'].hasError('required') && userForm.controls['language'].touched) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
    </mat-form-field>
  </div>
</form>
