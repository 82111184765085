import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { AuthService } from '../app-services';
import { ModelAdmin } from '../models/model-admin.model';
import { takeUntil } from 'rxjs/operators';
import { Role } from 'app/models/loggin.model';
import { AvailableLanguages } from 'app/models/available-languages.const';

@Component({
  selector: 'model-admin-form',
  templateUrl: './model-admin-form.component.html',
  styleUrls: ['./model-admin-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelAdminFormComponent implements OnInit, OnDestroy {
  @Input() modelAdmin: ModelAdmin;
  @Output() submitValues = new EventEmitter<any>();

  modelAdminForm: FormGroup;

  availableLanguages = AvailableLanguages;

  constructor(protected authService: AuthService) {
    this.unsubscribeSubject = new Subject<void>();
  }
  private readonly unsubscribeSubject: Subject<void>;

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
  ngOnInit() {
    this.modelAdminForm = new FormGroup({
      fullName: new FormControl(this.modelAdmin?.fullName, Validators.required),
      email: new FormControl(
        this.modelAdmin?.email,
        [Validators.required, Validators.email],
        this.authService.isUniqueEmailValidator(Role.MODEL_ADMIN, this.modelAdmin?.email),
      ),
      language: new FormControl(this.modelAdmin?.language, Validators.required),
    });

    this.modelAdminForm.valueChanges
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(() => this.submitValues.emit(this.modelAdminForm.value));
  }
}
