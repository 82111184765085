import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../app-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mp-header',
  templateUrl: './mp-header.component.html',
  styleUrls: ['./mp-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MpHeaderComponent implements OnInit {
  constructor(
    protected authService: AuthService,
    protected snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {}
  menuList: { label: string; link: string }[];
  adminMenu: { label: string; link: string }[] = [
    { label: 'user-management', link: '/users-management' },
    { label: 'cut-log', link: '/admin-printing-log' },
    { label: 'credit-log', link: '/admin-credit-history' },
    { label: 'payments-document', link: '/admin-payment-document-history' },
    { label: 'profile', link: '/admin-profile' },
  ];

  superAdminMenu: { label: string; link: string }[] = [
    { label: 'admins-management', link: '/admin-management' },
    { label: 'user-management', link: '/user-management' },
    { label: 'admins-models-management', link: '/model-admin-management' },
    { label: 'log', link: '/super-admin-printing-log' },
    { label: 'payments-document', link: '/super-admin-payment-history' },
    { label: 'credit-log', link: '/super-admin-credit-history' },
    { label: 'plotter', link: '/plotters' },
    { label: 'profile', link: '/super-admin-profile' },
  ];

  modelAdminMenu: { label: string; link: string }[] = [
    { label: 'trac-management', link: '/printable-data-management' },
    { label: 'brands-series-models-management', link: '/management' },
    { label: 'profile', link: '/model-admin-profile' },
  ];

  ngOnInit() {
    if (this.authService.isAdmin()) {
      this.menuList = this.adminMenu;
    } else if (this.authService.isSuperAdmin()) {
      this.menuList = this.superAdminMenu;
    } else if (this.authService.isModelAdmin()) {
      this.menuList = this.modelAdminMenu;
    } else {
      this.menuList = null;
    }
  }

  logout() {
    this.authService.logout();
    this.translateService.get('server-logout').subscribe(message => {
      this.snackBar.open(message, 'OK', { duration: 7000 });
    });
  }
}
