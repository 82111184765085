import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { HandleErrorsService } from '../app-services/';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlotterService } from '../app-services/plotter.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'delete-plotter-dialog',
  templateUrl: './delete-plotter-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeletePlotterDialogComponent {
  loading = new ReplaySubject<boolean>(1);

  constructor(
    protected plotterService: PlotterService,
    protected snackBar: MatSnackBar,
    protected dialogRef: MatDialogRef<DeletePlotterDialogComponent>,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public plotterId: number,
  ) {}

  deleteModel() {
    this.loading.next(true);
    this.plotterService.deletePlotter(this.plotterId).subscribe({
      next: () => {
        this.translateService.get('plotter_delete_success').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
        this.dialogRef.close();
      },
      error: err => {
        this.loading.next(false);
        this.handleErrors.handleErrors(err);
      },
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
