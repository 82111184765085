import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReplaySubject } from 'rxjs';
import { HandleErrorsService, ModelAdminService, UsersService } from '../app-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'confirm-deletion-dialog',
  templateUrl: './confirm-deletion-dialog.component.html',
  styleUrls: ['./confirm-deletion-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDeletionDialogComponent implements OnInit {
  loading = new ReplaySubject<boolean>(1);
  type: string;

  constructor(
    private readonly usersService: UsersService,
    private readonly modelAdminService: ModelAdminService,
    private readonly snackBar: MatSnackBar,
    private readonly handleErrors: HandleErrorsService,
    private readonly translateService: TranslateService,
    private readonly dialogRef: MatDialogRef<ConfirmDeletionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: number; type: string },
  ) {}

  ngOnInit() {
    this.type = this.data.type === 'model-admin' ? 'admin-model-delete-success' : this.data.type === 'user' ? 'user-delete-success' : '';
  }

  confirm() {
    this.loading.next(true);
    let method;
    switch (this.data.type) {
      case 'model-admin':
        method = this.modelAdminService.deleteModelAdmin(this.data.id);
        break;
      case 'user':
        method = this.usersService.deleteUser(this.data.id);
        break;
    }
    method.subscribe({
      next: () => {
        this.translateService.get(this.type).subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
        this.dialogRef.close();
      },
      error: err => {
        this.loading.next(false);
        this.handleErrors.handleErrors(err);
      },
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
