import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { AddAdminDialogComponent } from '../add-admin-dialog/add-admin-dialog.component';

import { CloseDialogInterface } from '../app-interfaces/close-dialog.interface';

@Component({
  selector: 'add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddAdminComponent implements OnInit, CloseDialogInterface {
  dialogRef: any;

  constructor(protected dialog: MatDialog) {}

  ngOnInit() {
    const config: MatDialogConfig = { width: '60%', disableClose: true };
    this.dialogRef = this.dialog.open(AddAdminDialogComponent, config);
  }

  closeDialog() {
    this.dialogRef.close();

    return true;
  }
}
