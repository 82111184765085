import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { Piece, PrintableData } from '../models/model.model';

@Component({
  selector: 'printing-visualisation-view',
  templateUrl: './printing-visualisation-view.component.html',
  styleUrls: ['./printing-visualisation-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintingVisualisationViewComponent implements OnChanges {
  @Input() printedPieces: number[];
  @Input() printableData: PrintableData;
  printed: Piece[];
  notPrinted: Piece[];

  ngOnChanges() {
    this.printed = this.printableData ? this.printableData.pieces.filter((p, i) => this.printedPieces.indexOf(i) !== -1) : [];

    this.notPrinted = this.printableData ? this.printableData.pieces.filter((p, i) => this.printedPieces.indexOf(i) === -1) : [];
  }
}
