import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AdminService, HandleErrorsService } from 'app/app-services';
import { Admin, adminCreditUpdate } from 'app/models/admin.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'admin-credit',
  templateUrl: './admin-credit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminCreditComponent implements OnInit {
  admin: Admin;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly adminService: AdminService,
    private readonly snackBar: MatSnackBar,
    private readonly handleErrors: HandleErrorsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    protected translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.admin = this.route.parent.snapshot.data.adminDetails;
  }

  updateCredit(extra: adminCreditUpdate) {
    this.adminService.updateCredit({ id: this.admin.id, ppf: extra.extraPPF, window: extra.extraWindows }).subscribe({
      next: (admin: Admin) => {
        this.translateService.get('credit-update-success').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
        this.admin = { ...admin };
        this.changeDetectorRef.markForCheck();
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }
}
