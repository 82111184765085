<h2 mat-dialog-title>{{ 'new-cut' | translate }}</h2>
<mat-dialog-content>
  <printable-data-add-dumb
    #dumb
    [brandList]="brandList | async"
    [serieList]="serieList | async"
    [modelList]="modelList | async"
    [parsedSvg]="parsedSvg | async"
    [loadingBrands]="loadingBrands | async"
    [loadingSeries]="loadingSeries | async"
    [loadingModels]="loadingModels | async"
    (svgLoader)="loadSvg($event)"
    (formSubmit)="getFormInfo($event)"
    (updateParsedSVGAndBbox)="updateInfo($event)"
    (brandSelected)="getSeries($event)"
    (serieSelected)="getModels($event)"
  ></printable-data-add-dumb>
</mat-dialog-content>

<mat-dialog-actions>
  @if (loading | async) {
    <mat-spinner></mat-spinner>
  }
  <button
    mat-raised-button
    color="accent"
    [disabled]="!dumb.printableDataForm.controls['type'].value || !printableData.pieces || (loading | async)"
    (click)="addPrintableData()"
  >
    {{ 'add' | translate }}
  </button>
  <button mat-raised-button color="warn" (click)="leaveDialog(queryParams)">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
