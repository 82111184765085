import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { EntityList, Messages } from 'app/models/entity-list.model';
import { Admin } from '../models/admin.model';
import { MagicTranslateService } from 'app/app-services';
import { SearchEvent } from 'app/models/search-event.model';

@Component({
  selector: 'admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminListComponent implements OnInit {
  @Input() adminList: EntityList<Admin>;
  @Input() offset: number;
  @Output() setActivation = new EventEmitter();
  @Output() openDeleteAdminDialog = new EventEmitter();
  @Output() submitFilterOptions = new EventEmitter();

  constructor(private readonly magicTranslateService: MagicTranslateService) {}

  messages$: Messages;
  ngOnInit() {
    this.messages$ = this.magicTranslateService.getMessage();
  }

  changeStatus(adminId: number, event: MatSlideToggleChange) {
    this.setActivation.emit({ adminId: adminId, isActive: event.checked });
  }

  deleteAdmin(admin: Admin) {
    this.openDeleteAdminDialog.emit(admin);
  }

  handlePages(event: SearchEvent) {
    this.submitFilterOptions.emit(event);
  }
}
