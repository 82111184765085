import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddPrintableDataDialogComponent } from '../add-printable-data-dialog/add-printable-data-dialog.component';
import { CloseDialogInterface } from '../app-interfaces/close-dialog.interface';

@Component({
  selector: 'add-printable-data',
  templateUrl: './add-printable-data.component.html',
  styleUrls: ['./add-printable-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPrintableDataComponent implements OnInit, CloseDialogInterface {
  dialogRef: any;

  constructor(protected dialog: MatDialog) {}

  ngOnInit() {
    const config: MatDialogConfig = { width: '60%', disableClose: true };
    setTimeout(() => (this.dialogRef = this.dialog.open(AddPrintableDataDialogComponent, config)));
  }

  closeDialog() {
    this.dialogRef.close();

    return true;
  }
}
