import { Admin } from './admin.model';
import { PaymentDocumentStatus, PaymentDocumentType } from './payment-document.model';
import { User } from './user.model';

export type Logging = {
  id?: number;
  createdAt: Date;
  updatedAt: Date;
  eventType: string;
  adminId?: number;
  userId?: number;
  superAdminId?: number;
  extra: CreditLogging;
};

export interface CreditLogging {
  creditType: 'VITRE' | 'PPF';
  value: number;
}
export type CreditTransferLogging = Logging & {
  eventType: 'CreditTransfer';
};

export type ExtendedLogging = CreditTransferLogging & {
  User?: User;
  Admin?: Admin;
};

export type FilterQuery = {
  startDate?: Date;
  endDate?: Date;
  userIds?: string;
  adminIds?: string;
  type?: PaymentDocumentType;
  status?: PaymentDocumentStatus;
};

export type LoggingQuery = {
  ids?: number[];
  userIds?: number[];
  adminIds?: number[];
  startDate?: any;
  endDate?: any;
  offset?: number;
  limit?: number;
  exclude?: number[];
  next?: number;
};

export enum Role {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  MODEL_ADMIN = 'MODEL_ADMIN',
  USER = 'USER',
}
