import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../app-services';

@Injectable()
export class AntiGuard {
  constructor(
    protected authService: AuthService,
    protected router: Router,
  ) {}

  canActivate(): boolean {
    if (!this.authService.isLogged()) {
      return true;
    } else {
      this.router.navigate(['/']);

      return false;
    }
  }
}
