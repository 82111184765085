<h1 mat-dialog-title>
  <span>{{ 'new-user' | translate }}</span>
</h1>
<mat-dialog-content>
  <user-form #userForm [canEdit]="true" (userActive)="handleUserInfo($event)"></user-form>
</mat-dialog-content>
<mat-dialog-actions>
  @if (loading | async) {
    <mat-spinner></mat-spinner>
  }
  <button mat-raised-button color="accent" (click)="addUser()" [disabled]="userForm.userForm.status !== 'VALID' || (loading | async)">
    {{ 'add' | translate }}
  </button>
  <button mat-raised-button color="warn" (click)="leaveDialog(queryParams)">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
