import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HandleErrorsService } from 'app/app-services';
import { PlotterService } from 'app/app-services/plotter.service';
import { DeletePlotterDialogComponent } from 'app/delete-plotter-dialog/delete-plotter-dialog.component';
import { EntityList } from 'app/models/entity-list.model';
import { PlotterFilterQuery, Plotter, PlotterQuery } from 'app/models/plotter.model';
import { combineLatest, Observable, of, ReplaySubject, startWith, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'plotter-log',
  templateUrl: './plotter-log.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlotterLogComponent implements OnInit, OnDestroy {
  private readonly unsubscribeSubject: Subject<void>;
  plotterList = new ReplaySubject<EntityList<Plotter>>(1);
  plottersAutoComplete$ = new ReplaySubject<Plotter[]>(1);
  vendorId$: Observable<number>;
  productId$: Observable<number>;
  offset$ = of(0);
  constructor(
    private readonly plotterService: PlotterService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly handleErrors: HandleErrorsService,
    protected dialog: MatDialog,
  ) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams, this.plotterService.update.pipe(startWith(null))])
      .pipe(
        switchMap(([params, queryParams]) => {
          const query = {
            limit: 10,
            offset: 0,
          };

          if (params['page']) {
            query['offset'] = (+params['page'] - 1) * 10;
            this.offset$ = of(+params['page'] - 1);
          }

          Object.keys(queryParams).map(k => {
            query[k] = queryParams[k];
          });

          return this.plotterService.getPlottersList(query);
        }),
        takeUntil(this.unsubscribeSubject),
      )
      .subscribe({
        next: response => {
          this.plotterList.next(response.data);
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }

  pageNavigation(event: { count: number; pageSize: number; limit: number; offset: number }) {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate(['/plotters', event.offset + 1], { queryParams });
  }

  filterPlotter(queryParams: PlotterFilterQuery) {
    this.router.navigate(['plotters'], { queryParams });
  }

  fetchPlotter(query: PlotterQuery) {
    this.plotterService.getPlottersList(query).subscribe({
      next: response => {
        this.plottersAutoComplete$.next(response.data.listing);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  openDeletePlotterDialog(plotterId: number) {
    const config: MatDialogConfig = { data: plotterId, width: '60%', disableClose: true };
    this.dialog.open(DeletePlotterDialogComponent, config);
  }

  navigate() {
    const queryParams = this.route.snapshot.queryParams;
    const link = ['/', { outlets: { dialog: ['add-plotter'] } }];
    this.router.navigate(link, { queryParams });
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
