import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReplaySubject } from 'rxjs';
import { HandleErrorsService, ModelService } from '../app-services';
import { Serie } from '../models/serie.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'delete-serie-dialog',
  templateUrl: './delete-serie-dialog.component.html',
  styleUrls: ['./delete-serie-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteSerieDialogComponent implements OnInit {
  modelList = new ReplaySubject<any[]>(1);
  loading = new ReplaySubject<boolean>(1);
  disabled = false;

  constructor(
    protected modelService: ModelService,
    protected snackBar: MatSnackBar,
    protected dialogRef: MatDialogRef<DeleteSerieDialogComponent>,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public serie: Serie,
  ) {}

  ngOnInit() {
    this.modelService.getModelList({ serieIds: [this.serie.id] }).subscribe(res => {
      if (!res.data.listing.length) {
        this.disabled = true;
      }
      this.modelList.next(res.data.listing);
    });
  }

  deleteSerie() {
    this.loading.next(true);
    this.modelService.deleteSerie(this.serie.id).subscribe({
      next: () => {
        this.translateService.get('serie-delete-success').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
        this.dialogRef.close();
      },
      error: err => {
        this.loading.next(false);
        this.handleErrors.handleErrors(err);
      },
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
