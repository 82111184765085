import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { EntityList, Messages } from 'app/models/entity-list.model';

import { Model } from '../models/model.model';
import { MagicTranslateService } from 'app/app-services';
import { SearchEvent } from 'app/models/search-event.model';

@Component({
  selector: 'model-list',
  templateUrl: './model-list.component.html',
  styleUrls: ['./model-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelListComponent implements OnInit {
  @Input() modelList: EntityList<Model>;
  @Input() offset: number;

  @Output() openDeleteModelDialog = new EventEmitter();
  @Output() tablePagesNavigation = new EventEmitter();
  @Output() routeNavigate = new EventEmitter();

  messages$: Messages;

  constructor(private readonly magicTranslateService: MagicTranslateService) {}

  ngOnInit() {
    this.messages$ = this.magicTranslateService.getMessage();
  }

  deleteModel(model: Model) {
    this.openDeleteModelDialog.emit(model.id);
  }

  handlePages(event: SearchEvent) {
    this.tablePagesNavigation.emit(event);
  }

  navigate(modelId: number) {
    this.routeNavigate.emit(modelId);
  }
}
