<h1 mat-dialog-title>
  <span>{{ 'edit-brand' | translate }}</span>
</h1>
<mat-dialog-content>
  <brand-form #editForm [brand]="brand" (brandInfoSubmit)="getBrandInfo($event)"></brand-form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="accent" [disabled]="editForm.brandForm.invalid || (progress | async)" (click)="editBrand()">
    {{ (progress | async) ? (progress | async) + '%' : ('edit' | translate) }}
  </button>
  <button mat-raised-button color="warn" (click)="cancel()">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
