import { Routes } from '@angular/router';

import {
  AntiGuard,
  DialogCloseGuard,
  DZAdminGuard,
  HasAccessToPrintingsGuard,
  IsAdminGuard,
  IsAdminOrSuperAdminGuard,
  IsLoggedGuard,
  IsModelAdminGuard,
  IsSuperAdminGuard,
} from './guards';

import {
  AdminBanneResolver,
  AdminDetailsResolver,
  CurrentAdminDataResolver,
  CurrentModelAdminResolver,
  SuperAdminResolver,
  UserDetailsResolver,
} from './resolvers';

import { AddAdminComponent } from './add-admin/add-admin.component';
import { AdminDetailsComponent } from './admin-details/admin-details.component';
import { AdminManagementComponent } from './admin-management/admin-management.component';
import { AdminPrintingLogComponent } from './admin-printing-log/admin-printing-log.component';
import { AdminUsersManagementComponent } from './admin-users-management/admin-users-management.component';
import { GlobalUsersManagementComponent } from './global-users-management/global-users-management.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { PrintingVisualisationComponent } from './printing-visualisation/printing-visualisation.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserPrintingLogComponent } from './user-printing-log/user-printing-log.component';

import { AdminProfilTabComponent } from 'app/admin-profil-tab/admin-profil-tab.component';
import { AdminUsersListTabComponent } from 'app/admin-users-list-tab/admin-users-list-tab.component';
import { UserProfileComponent } from 'app/user-profile/user-profile.component';
import { AddBrandComponent } from './add-brand/add-brand.component';
import { AddModelAdminComponent } from './add-model-admin/add-model-admin.component';
import { AddModelComponent } from './add-model/add-model.component';
import { AddPrintableDataComponent } from './add-printable-data/add-printable-data.component';
import { AddSerieComponent } from './add-serie/add-serie.component';
import { AddUserComponent } from './add-user/add-user.component';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { BrandManagementComponent } from './brand-management/brand-management.component';
import { BrandSerieModelManagementComponent } from './brand-serie-model-management/brand-serie-model-management.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EditBrandComponent } from './edit-brand/edit-brand.component';
import { EditModelAdminComponent } from './edit-model-admin/edit-model-admin.component';
import { EditModelComponent } from './edit-model/edit-model.component';
import { EditSerieComponent } from './edit-serie/edit-serie.component';
import { ModelAdminManagementComponent } from './model-admin-management/model-admin-management.component';
import { ModelAdminProfileComponent } from './model-admin-profile/model-admin-profile.component';
import { ModelManagementComponent } from './model-management/model-management.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrintableDataManagementComponent } from './printable-data-management/printable-data-management.component';
import { PrintableDataPreviewComponent } from './printable-data-preview/printable-data-preview.component';
import { RequestPasswordResetComponent } from './request-password-reset/request-password-reset.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SerieManagementComponent } from './serie-management/serie-management.component';
import { SuperAdminPrintingLogComponent } from './super-admin-printing-log/super-admin-printing-log.component';
import { SuperAdminProfileComponent } from './super-admin-profile/super-admin-profile.component';
import { BannerProfilTabComponent } from './banner-profil-tab/banner-profil-tab.component';
import { ProfileCreditComponent } from './profile-credit/profile-credit.component';
import { AdminCreditComponent } from './admin-credit/admin-credit.component';
import { SuperAdminCreditLogComponent } from './super-admin-credit-log/super-admin-credit-log.component';
import { AdminCreditLogComponent } from './admin-credit-log/admin-credit-log.component';
import { SuperAdminPaymentDocumentLogComponent } from './super-admin-payment-document-log/super-admin-payment-document-log.component';
import { AddPaymentDocumentComponent } from './add-payment-document/add-payment-document.component';
import { AdminPaymentDocumentLogComponent } from './admin-payment-document-log/admin-payment-document-log.component';
import { SuccessDocumentPaymentComponent } from './success-document-payment/success-document-payment.component';
import { StripePaymentInterfaceComponent } from './stripe-payment-interface/stripe-payment-interface.component';
import { PlotterLogComponent } from './plotter-log/plotter-log.component';
import { AddPlotterComponent } from './add-plotter/add-plotter.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [IsLoggedGuard],
    children: [
      {
        path: 'admin-management',
        component: AdminManagementComponent,
        canActivate: [IsSuperAdminGuard],
      },
      {
        path: 'admin-management/:page',
        component: AdminManagementComponent,
        canActivate: [IsSuperAdminGuard],
      },
      {
        path: 'user-management',
        component: GlobalUsersManagementComponent,
        canActivate: [IsSuperAdminGuard],
      },
      {
        path: 'user-management/:page',
        component: GlobalUsersManagementComponent,
        canActivate: [IsSuperAdminGuard],
      },
      {
        path: 'admin-details/:id',
        component: AdminDetailsComponent,
        canActivate: [IsSuperAdminGuard],
        runGuardsAndResolvers: 'always',
        resolve: {
          adminDetails: AdminDetailsResolver,
        },
        children: [
          {
            path: 'admin-profile',
            component: AdminProfilTabComponent,
          },
          {
            path: 'admin-users-list',
            component: AdminUsersListTabComponent,
          },
          {
            path: 'admin-users-list/:page',
            component: AdminUsersListTabComponent,
          },
          {
            path: 'banner',
            component: BannerProfilTabComponent,
            runGuardsAndResolvers: 'always',
            resolve: {
              adminBanner: AdminBanneResolver,
            },
          },
          {
            path: 'credit',
            component: AdminCreditComponent,
            canActivate: [DZAdminGuard],
          },
        ],
      },
      {
        path: 'user-details/:id',
        component: UserDetailsComponent,
        canActivate: [IsAdminOrSuperAdminGuard],
        runGuardsAndResolvers: 'always',
        resolve: {
          userDetails: UserDetailsResolver,
        },
        children: [
          {
            path: 'profile',
            component: UserProfileComponent,
          },
          {
            path: 'credit',
            // runGuardsAndResolvers: 'always',
            canActivate: [IsAdminGuard],
            component: ProfileCreditComponent,
            resolve: { adminDetails: CurrentAdminDataResolver },
          },
          {
            path: 'user-printing-log',
            component: UserPrintingLogComponent,
          },
          {
            path: 'user-printing-log/:page',
            component: UserPrintingLogComponent,
          },
        ],
      },
      {
        path: 'users-management',
        component: AdminUsersManagementComponent,
        canActivate: [IsAdminGuard],
        resolve: {
          currentAdminData: CurrentAdminDataResolver,
        },
      },
      {
        path: 'users-management/:page',
        component: AdminUsersManagementComponent,
        canActivate: [IsAdminGuard],
        resolve: {
          currentAdminData: CurrentAdminDataResolver,
        },
      },
      {
        path: 'super-admin-printing-log',
        component: SuperAdminPrintingLogComponent,
        canActivate: [IsSuperAdminGuard],
      },
      {
        path: 'super-admin-credit-history',
        component: SuperAdminCreditLogComponent,
        canActivate: [IsSuperAdminGuard],
      },
      {
        path: 'super-admin-credit-history/:page',
        component: SuperAdminCreditLogComponent,
        canActivate: [IsSuperAdminGuard],
      },
      {
        path: 'super-admin-payment-history',
        component: SuperAdminPaymentDocumentLogComponent,
        canActivate: [IsSuperAdminGuard],
      },
      {
        path: 'super-admin-payment-history/:page',
        component: SuperAdminPaymentDocumentLogComponent,
        canActivate: [IsSuperAdminGuard],
      },
      {
        path: 'super-admin-printing-log/:page',
        component: SuperAdminPrintingLogComponent,
        canActivate: [IsSuperAdminGuard],
      },
      {
        path: 'model-admin-management',
        component: ModelAdminManagementComponent,
        canActivate: [IsSuperAdminGuard],
      },
      {
        path: 'super-admin-profile',
        component: SuperAdminProfileComponent,
        canActivate: [IsSuperAdminGuard],
        resolve: {
          superAdminProfile: SuperAdminResolver,
        },
      },
      {
        path: 'admin-profile',
        component: AdminProfileComponent,
        canActivate: [IsAdminGuard],
        resolve: {
          currentAdminData: CurrentAdminDataResolver,
        },
      },
      {
        path: 'model-admin-profile',
        component: ModelAdminProfileComponent,
        canActivate: [IsModelAdminGuard],
        resolve: {
          currentModelAdmin: CurrentModelAdminResolver,
        },
      },
      {
        path: 'printable-data-management',
        component: PrintableDataManagementComponent,
        canActivate: [IsModelAdminGuard],
      },
      {
        path: 'printable-data-management/:page',
        component: PrintableDataManagementComponent,
        canActivate: [IsModelAdminGuard],
      },
      {
        path: 'management',
        component: BrandSerieModelManagementComponent,
        canActivate: [IsModelAdminGuard],
        children: [
          {
            path: '',
            redirectTo: 'brand-management',
            pathMatch: 'full',
          },
          {
            path: 'brand-management',
            component: BrandManagementComponent,
          },
          {
            path: 'brand-management/:page',
            component: BrandManagementComponent,
          },
          {
            path: 'serie-management',
            component: SerieManagementComponent,
          },
          {
            path: 'serie-management/:page',
            component: SerieManagementComponent,
          },
          {
            path: 'model-management',
            component: ModelManagementComponent,
          },
          {
            path: 'model-management/:page',
            component: ModelManagementComponent,
          },
        ],
      },
      {
        path: 'admin-printing-log',
        component: AdminPrintingLogComponent,
        canActivate: [IsAdminGuard],
      },
      {
        path: 'admin-printing-log/:page',
        component: AdminPrintingLogComponent,
        canActivate: [IsAdminGuard],
      },
      {
        path: 'admin-credit-history',
        component: AdminCreditLogComponent,
        canActivate: [IsAdminGuard],
      },
      {
        path: 'admin-credit-history/:page',
        component: AdminCreditLogComponent,
        canActivate: [IsAdminGuard],
      },
      {
        path: 'admin-payment-document-history',
        component: AdminPaymentDocumentLogComponent,
        canActivate: [IsAdminGuard],
      },
      {
        path: 'admin-payment-document-history/:page',
        component: AdminPaymentDocumentLogComponent,
        canActivate: [IsAdminGuard],
      },
      {
        path: 'plotters',
        component: PlotterLogComponent,
        canActivate: [IsSuperAdminGuard],
      },
      {
        path: 'plotters/:page',
        component: PlotterLogComponent,
        canActivate: [IsSuperAdminGuard],
      },
      {
        path: 'error-404',
        component: PageNotFoundComponent,
        canActivate: [IsLoggedGuard],
      },
    ],
  },
  { path: 'login', component: LoginComponent, canActivate: [AntiGuard] },
  {
    path: 'reset-password/:hash',
    component: ResetPasswordComponent,
    canActivate: [AntiGuard],
  },
  {
    path: 'request-password-reset/:scope',
    component: RequestPasswordResetComponent,
    canActivate: [AntiGuard],
  },
  {
    path: 'success-payment',
    component: SuccessDocumentPaymentComponent,
    outlet: 'dialog',
    canDeactivate: [DialogCloseGuard],
  },
  {
    path: 'confirm-payment/:paymentDocumentId',
    component: StripePaymentInterfaceComponent,
    outlet: 'dialog',
    canDeactivate: [DialogCloseGuard],
    canActivate: [IsAdminGuard],
  },
  {
    path: 'add-plotter',
    component: AddPlotterComponent,
    outlet: 'dialog',
    canDeactivate: [DialogCloseGuard],
    canActivate: [IsSuperAdminGuard],
  },
  {
    path: 'add-admin',
    component: AddAdminComponent,
    outlet: 'dialog',
    canDeactivate: [DialogCloseGuard],
    canActivate: [IsSuperAdminGuard],
  },
  {
    path: 'add-payment-document',
    component: AddPaymentDocumentComponent,
    outlet: 'dialog',
    canDeactivate: [DialogCloseGuard],
    canActivate: [IsSuperAdminGuard],
  },
  {
    path: 'add-user',
    component: AddUserComponent,
    outlet: 'dialog',
    canDeactivate: [DialogCloseGuard],
    canActivate: [IsAdminGuard],
  },
  {
    path: 'printing-visualisation/:id',
    component: PrintingVisualisationComponent,
    outlet: 'dialog',
    canDeactivate: [DialogCloseGuard],
    canActivate: [IsAdminOrSuperAdminGuard, HasAccessToPrintingsGuard],
  },
  {
    path: 'add-model-admin',
    component: AddModelAdminComponent,
    outlet: 'dialog',
    canDeactivate: [DialogCloseGuard],
    canActivate: [IsSuperAdminGuard],
  },
  {
    path: 'edit-model-admin/:id',
    component: EditModelAdminComponent,
    outlet: 'dialog',
    canDeactivate: [DialogCloseGuard],
    canActivate: [IsSuperAdminGuard],
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    outlet: 'dialog',
    canDeactivate: [DialogCloseGuard],
    canActivate: [IsLoggedGuard],
  },
  {
    path: 'edit-brand/:id',
    component: EditBrandComponent,
    outlet: 'dialog',
    canDeactivate: [DialogCloseGuard],
    canActivate: [IsModelAdminGuard],
  },
  {
    path: 'add-brand',
    component: AddBrandComponent,
    outlet: 'dialog',
    canDeactivate: [DialogCloseGuard],
    canActivate: [IsModelAdminGuard],
  },
  {
    path: 'add-serie',
    component: AddSerieComponent,
    outlet: 'dialog',
    canDeactivate: [DialogCloseGuard],
    canActivate: [IsModelAdminGuard],
  },
  {
    path: 'edit-serie/:id',
    component: EditSerieComponent,
    outlet: 'dialog',
    canDeactivate: [DialogCloseGuard],
    canActivate: [IsModelAdminGuard],
  },
  {
    path: 'add-model',
    component: AddModelComponent,
    outlet: 'dialog',
    canDeactivate: [DialogCloseGuard],
    canActivate: [IsModelAdminGuard],
  },
  {
    path: 'edit-model/:id',
    component: EditModelComponent,
    outlet: 'dialog',
    canDeactivate: [DialogCloseGuard],
    canActivate: [IsModelAdminGuard],
  },
  {
    path: 'add-printable-data',
    component: AddPrintableDataComponent,
    outlet: 'dialog',
    canDeactivate: [DialogCloseGuard],
    canActivate: [IsModelAdminGuard],
  },
  {
    path: 'printable-data-preview/:id',
    component: PrintableDataPreviewComponent,
    outlet: 'dialog',
    canDeactivate: [DialogCloseGuard],
    canActivate: [IsModelAdminGuard],
  },
  {
    path: '**',
    redirectTo: 'error-404',
  },
];
