import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Plotter, PlotterFilterQuery, PlotterInfo } from '../models/plotter.model';
import { debounceTime, distinctUntilChanged, filter, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'plotter-log-filter',
  templateUrl: './plotter-log-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlotterLogFilterComponent implements OnChanges, OnDestroy {
  @Input() plotterList: Plotter[];
  @Input() searchTerms: PlotterInfo;

  @Output() filterPlotters = new EventEmitter<PlotterInfo>();
  @Output() fetchLists = new EventEmitter<PlotterFilterQuery>();
  @Output() routeNavigate = new EventEmitter<void>();

  plotterFilterForm: FormGroup;

  private readonly unsubscribeSubject: Subject<void>;
  constructor(protected router: Router) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.plotterFilterForm) {
      this.buildForm();
    }

    if (changes?.searchTerms?.isFirstChange()) {
      this.plotterFilterForm.patchValue(this.searchTerms);
    }
  }

  search() {
    this.filterPlotters.emit(this.plotterFilterForm.value);
    this.plotterList = undefined;
  }

  buildForm() {
    this.plotterFilterForm = new FormGroup({
      name: new FormControl(null),
      vendorId: new FormControl(null),
      productId: new FormControl(null),
    });

    this.plotterFilterForm.controls['name'].valueChanges
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        takeUntil(this.unsubscribeSubject),
        filter(searchTerm => searchTerm?.length > 1),
      )
      .subscribe(name => this.fetchLists.emit({ name: name.trim() }));

    this.plotterFilterForm.controls['vendorId'].valueChanges
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        takeUntil(this.unsubscribeSubject),
        filter(searchTerm => searchTerm?.length > 1),
      )
      .subscribe(vendorId => this.fetchLists.emit({ vendorId: vendorId.trim() }));

    this.plotterFilterForm.controls['productId'].valueChanges
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        takeUntil(this.unsubscribeSubject),
        filter(searchTerm => searchTerm?.length > 1),
      )
      .subscribe(productId => this.fetchLists.emit({ productId: productId.trim() }));
  }

  onAddPlotter() {
    this.routeNavigate.emit();
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
