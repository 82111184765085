import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CloseDialogInterface } from '../app-interfaces/close-dialog.interface';
import { HandleErrorsService, PrintableDataService } from '../app-services';
import { PrintableDataPreviewDialogComponent } from '../printable-data-preview-dialog/printable-data-preview-dialog.component';

@Component({
  selector: 'printable-data-preview',
  templateUrl: './printable-data-preview.component.html',
  styleUrls: ['./printable-data-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintableDataPreviewComponent implements OnInit, CloseDialogInterface {
  dialogRef: any;

  constructor(
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    protected handleErrors: HandleErrorsService,
    protected printableDataService: PrintableDataService,
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.params['id'];
    this.printableDataService.getPrintableData(id).subscribe({
      next: result => {
        const config: MatDialogConfig = { data: result.data, width: '60%', disableClose: true };
        this.dialogRef = this.dialog.open(PrintableDataPreviewDialogComponent, config);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  closeDialog() {
    this.dialogRef.close();

    return true;
  }
}
