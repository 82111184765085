import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { ImageCropperComponent } from 'app/image-cropper/image-cropper.component';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'cropper',
  templateUrl: './cropper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropperComponent implements OnChanges, OnDestroy {
  @Input() file: File;
  @Input() cropWidth: number;
  @Input() cropHeight: number;
  @Output() croppImage = new EventEmitter();
  @ViewChild('cropper', { static: true }) cropper: ImageCropperComponent;

  private readonly unsubscribeSubject: Subject<void>;
  constructor() {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
  ngOnChanges() {
    this.cropper.initImageCrop(this.file);

    this.cropper.editable.ready
      .pipe(
        takeUntil(this.unsubscribeSubject),
        switchMap(() => {
          return this.cropper.configChange.pipe(
            debounceTime(300),
            map(() => {
              this.croppImage.emit(new File([this.cropper.getBlob()], this.file.name));
            }),
          );
        }),
      )
      .subscribe();
  }
}
