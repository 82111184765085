<h2>{{ 'cut-log' | translate }}</h2>
<admin-printing-filter
  [userList]="users | async"
  [modelList]="models | async"
  [searchedDates]="searchedDates"
  [searchedModels]="searchedModels$ | async"
  [searchedUsers]="searchedUsers$ | async"
  (printingsFilter)="filter($event)"
  (queryModels)="fetchModelList($event)"
  (queryUsers)="fetchUserList($event)"
></admin-printing-filter>
<printing-list [printings]="printings | async" [offset]="offset$ | async" (tablePagesNavigation)="pageNavigation($event)"></printing-list>
