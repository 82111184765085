import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CloseDialogInterface } from '../app-interfaces/close-dialog.interface';
import { HandleErrorsService, ModelService } from '../app-services';
import { EditSerieDialogComponent } from '../edit-serie-dialog/edit-serie-dialog.component';

@Component({
  selector: 'edit-serie',
  templateUrl: './edit-serie.component.html',
  styleUrls: ['./edit-serie.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSerieComponent implements OnInit, CloseDialogInterface {
  dialogRef: any;

  constructor(
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected modelService: ModelService,
    protected handleErrors: HandleErrorsService,
  ) {}

  ngOnInit() {
    const id: number = this.route.snapshot.params['id'];
    this.modelService.getSerie(id).subscribe({
      next: result => {
        const config: MatDialogConfig = { width: '60%', disableClose: true, data: result.data };
        this.dialogRef = this.dialog.open(EditSerieDialogComponent, config);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  closeDialog() {
    this.dialogRef.close();

    return true;
  }
}
