<h2>{{ 'profile' | translate }}</h2>
<div class="contents form-contents">
  <form (ngSubmit)="save()" [formGroup]="superAdminForm">
    <mat-form-field>
      <mat-label translate="full-name" />
      <input matInput type="text" formControlName="fullName" updateOnBlur />
      @if (superAdminForm.controls['fullName'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label translate="address" />
      <input matInput type="text" formControlName="address" updateOnBlur />
      @if (superAdminForm.controls['address'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
    </mat-form-field>
    <mat-form-field>
      <mat-label translate="email" />
      <input matInput type="email" formControlName="email" updateOnBlur />
      @if (superAdminForm.controls['email'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
      @if (superAdminForm.controls['email'].hasError('email') && !superAdminForm.controls['email'].hasError('required')) {
        <mat-error> {{ 'email-validation' | translate }} </mat-error>
      }
    </mat-form-field>
    <mat-form-field>
      <mat-label translate="phone" />
      <input matInput type="text" formControlName="phone" updateOnBlur />
      @if (superAdminForm.controls['phone'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
      @if (superAdminForm.controls['phone'].hasError('phone')) {
        <mat-error> {{ 'phone-validation' | translate }} </mat-error>
      }
    </mat-form-field>
    <mat-form-field>
      <mat-label translate="language" />
      <mat-select formControlName="language" updateOnBlur>
        @for (language of availableLanguages; track language) {
          <mat-option [value]="language.code">
            {{ language.label }}
          </mat-option>
        }
      </mat-select>
      @if (superAdminForm.controls['language'].hasError('required') && superAdminForm.controls['language'].touched) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
    </mat-form-field>

    @if (superAdminForm.controls['language'].hasError('required') && superAdminForm.controls['language'].touched) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
    <div>
      <a [routerLink]="['/', { outlets: { dialog: ['change-password'] } }]"> {{ 'change-password' | translate }}</a>
    </div>
    <div class="buttons">
      @if (disabled) {
        <mat-spinner></mat-spinner>
      }
      <button mat-raised-button type="submit" color="warn" [disabled]="superAdminForm.invalid || !superAdminForm.dirty || disabled">
        {{ 'save' | translate }}
      </button>
      <button mat-raised-button type="button" color="accent" (click)="cancel()">{{ 'cancel' | translate }}</button>
    </div>
  </form>
</div>
