import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExtendedPrinting } from '../models/printing.model';

@Injectable()
export class PrintingService {
  route = {
    ids: 'ids',
    modelIds: 'model-ids',
    userIds: 'user-ids',
    adminIds: 'admin-ids',
    startDate: 'start-date',
    endDate: 'end-date',
    offset: 'offset',
    limit: 'limit',
    exclude: 'exclude',
    next: 'next',
  };

  constructor(private _http: HttpClient) {}

  getPrintingList(query: printingQuery = {}): Observable<Response> {
    const questionMark = Object.keys(query).length > 0 ? '?' : '';
    const url =
      '/api/printings/list' +
      questionMark +
      Object.keys(query)
        .map(k =>
          Array.isArray(query[k])
            ? query[k].map((value, index) => `${this.route[k]}[${index}]=${value}`).join('&')
            : `${this.route[k]}=${query[k]}`,
        )
        .join('&');

    return this._http.get(url, { observe: 'response' }).pipe(
      map((res: HttpResponse<Response>) => {
        return res.body;
      }),
    );
  }
}

export type printingQuery = {
  ids?: number[];
  modelIds?: number[];
  userIds?: number[];
  adminIds?: number[];
  startDate?: any;
  endDate?: any;
  offset?: number;
  limit?: number;
  exclude?: number[];
  next?: number;
};

export type Response = {
  statusCode: number;
  data: {
    listing: ExtendedPrinting[];
    total: number;
  };
};
