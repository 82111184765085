@if (authService.isSuperAdmin()) {
  <read-only-user-profile [user]="user" [canEdit]="false"></read-only-user-profile>
}
@if (authService.isAdmin()) {
  <editable-user-profile
    [user]="user"
    [canEdit]="canEdit | async"
    (userUpdate)="updateUser($event)"
    (userEdit)="allowEdit($event)"
    (changeCanceling)="cancelUpdate()"
  ></editable-user-profile>
}
