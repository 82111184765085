import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '../models/user.model';

@Component({
  selector: 'read-only-user-profile',
  templateUrl: './read-only-user-profile.component.html',
  styleUrls: ['./read-only-user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadOnlyUserProfileComponent {
  @Input() user: User;
  @Input() canEdit: boolean;
}
