<div class="contents">
  @if (paymentsDocumentList) {
    <div>
      <ngx-datatable
        [rows]="paymentsDocumentList?.listing"
        [footerHeight]="30"
        [columnMode]="'force'"
        [rowHeight]="'auto'"
        (page)="handlePages($event)"
        [limit]="10"
        [externalPaging]="true"
        [count]="paymentsDocumentList?.total"
        [offset]="offset"
        [messages]="messages$ | async"
      >
        <ngx-datatable-column [name]="'admins' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/admin-details', row?.admin?.id, 'admin-profile']">
              {{ row?.admin?.fullName }}
            </a>
            <spinner [route]="'/admin-details/' + row?.id + '/admin-profile'"></spinner>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'reference' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.type }}-{{ row?.createdAt | date: 'dd-MM-yyyy' }}-{{ row?.id }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'type' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.extra.creditType }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'quantity' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template> {{ row?.extra.quantity }} M² </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'pu' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template> {{ row?.unitPrice | number: '1.2-2' }} </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'vat' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template> {{ row?.vat | number: '1.2-2' }}% </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'amount' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.unitPrice * row?.extra.quantity | number: '1.2-2' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'date' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span [matTooltip]="row?.createdAt | date: 'dd/MM/yyyy, HH:mm'">{{ row?.createdAt | date: 'dd/MM, HH:mm' }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'status' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.status | translate }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [cellClass]="'item-cell'"
          [maxWidth]="100"
          [name]="'action' | translate"
          [sortable]="false"
          [draggable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            @if (row.status !== 'payment_intent.succeeded' && row.status !== 'payment_intent.canceled') {
              <button [matTooltip]="'delete' | translate" mat-icon-button color="accent" (click)="cancel(row)">
                <mat-icon svgIcon="ic_delete_24px"></mat-icon>
              </button>
            }
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  } @else {
    <div>
      <mat-spinner></mat-spinner>
    </div>
  }
</div>
