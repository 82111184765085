import { Admin } from './admin.model';

export type PaymentDocument = {
  id: number;
  amount: number;
  customerId?: string;
};

export interface CreditPaymentDocument {
  creditType: 'VITRE' | 'PPF';
  quantity: number;
}

export type ExtendedPaymentDocument = PaymentDocument & {
  Admin?: Admin;
  type: PaymentDocumentType;
};

export type FilterQuery = {
  startDate?: Date;
  endDate?: Date;
  adminIds?: string;
  type?: PaymentDocumentType;
  status?: PaymentDocumentStatus;
};

export type PaymentDocumentQuery = {
  ids?: number[];
  adminIds?: number[];
  startDate?: Date;
  endDate?: Date;
  offset?: number;
  limit?: number;
  exclude?: number[];
  next?: number;
};

export type StripeKey = {
  publishableKey?: string;
  paymentIntentId?: string;
  clientSecret?: string;
};

export type Response = {
  statusCode: number;
  data: {
    listing: ExtendedPaymentDocument[];
    total: number;
  };
};
export enum PaymentDocumentType {
  QT = 'QT',
  INV = 'INV',
}

export enum PaymentDocumentStatus {
  SUCCEEDED = 'payment_intent.succeeded',
  CANCELED = 'payment_intent.canceled',
  FAILED = 'payment_intent.payment_failed',
  REQUIRES_ACTION = 'payment_intent.requires_action',
  PROCESSING = 'payment_intent.processing',
  CREATED = 'payment_intent.created',
  UNPAID = 'unpaid',
}
