<form [formGroup]="adminForm">
  <div class="admin-form-row-1">
    <mat-form-field>
      <mat-label translate="full-name" />
      <input matInput type="text" formControlName="fullName" updateOnBlur />
      @if (adminForm.controls['fullName'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
    </mat-form-field>

    <div class="is-active-toggle">
      <mat-slide-toggle formControlName="isActive"></mat-slide-toggle>
      {{ isActiveMessage | translate }}
    </div>
  </div>

  <address-form
    [address]="{ country: admin.country, address: admin.address }"
    [canEdit]="canEdit"
    (addressValue)="updateAddressValue($event)"
  ></address-form>

  <div class="admin-form-row-3">
    <mat-form-field>
      <mat-label translate="email" />
      <input matInput type="email" formControlName="email" updateOnBlur />
      @if (adminForm.controls['email'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
      @if (adminForm.controls['email'].hasError('email') && !adminForm.controls['email'].hasError('required')) {
        <mat-error> {{ 'email-validation' | translate }} </mat-error>
      }
      @if (adminForm.controls['email'].hasError('unique')) {
        <mat-error> {{ 'isUnique' | translate }} </mat-error>
      }
    </mat-form-field>
    <mat-form-field>
      <mat-label translate="phone" />
      <input matInput type="text" formControlName="phone" updateOnBlur />
      @if (adminForm.controls['phone'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
      @if (adminForm.controls['phone'].hasError('phone')) {
        <mat-error> {{ 'phone-validation' | translate }} </mat-error>
      }
    </mat-form-field>
  </div>

  <div class="admin-form-row-4">
    <mat-form-field>
      <mat-label translate="allowedPPF" />
      <input matInput type="number" min="0" formControlName="allowedPPF" placeholder="Nombre de PPF disponible" updateOnBlur />
      @if (adminForm.controls['allowedPPF'].hasError('required')) {
        <mat-error> {{ 'required-pos-value-PPF' | translate }} </mat-error>
      }
      @if (adminForm.controls['allowedPPF'].hasError('min')) {
        <mat-error>
          {{ 'value' | translate }} {{ adminForm.controls['allowedPPF'].errors?.min.actual }} {{ 'below-minimal' | translate }}
          {{ adminForm.controls['allowedPPF'].errors?.min.min }} {{ 'forPPFCredit' | translate }}
        </mat-error>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label translate="allowedWindows" />
      <input matInput type="number" min="0" formControlName="allowedWindows" placeholder="Nombre de Vitre disponible" updateOnBlur />
      @if (adminForm.controls['allowedWindows'].hasError('required')) {
        <mat-error> {{ 'required-pos-value-Window' | translate }} </mat-error>
      }
      @if (adminForm.controls['allowedWindows'].hasError('min')) {
        <mat-error>
          {{ 'value' | translate }} {{ adminForm.controls['allowedWindows'].errors?.min.actual }} {{ 'below-minimal' | translate }}
          {{ adminForm.controls['allowedWindows'].errors?.min.min }} {{ 'forWindowCredit' | translate }}</mat-error
        >
      }
    </mat-form-field>
    <mat-form-field>
      <mat-label translate="language" />
      <mat-select formControlName="language">
        @for (language of availableLanguages; track language) {
          <mat-option [value]="language.code">
            {{ language.label }}
          </mat-option>
        }
      </mat-select>
      @if (adminForm.controls['language'].hasError('required') && adminForm.controls['language'].touched) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
    </mat-form-field>
  </div>
</form>
