import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, share } from 'rxjs/operators';
import { AuxiliaryRouteClass } from '../app-interfaces/close-dialog.interface';

import { Brand } from 'app/models/brand.model';
import { Piece, PrintableData } from 'app/models/model.model';
import { Serie } from 'app/models/serie.model';
import { HandleErrorsService, ModelService, PrintableDataService } from '../app-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'add-printable-data-dialog',
  templateUrl: './add-printable-data-dialog.component.html',
  styleUrls: ['./add-printable-data-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPrintableDataDialogComponent extends AuxiliaryRouteClass implements OnInit {
  brandList = new ReplaySubject<Brand[]>(1);
  serieList = new ReplaySubject<Serie[]>(1);
  modelList = new ReplaySubject<any>(1);
  parsedSvg = new ReplaySubject<Piece[]>(1);
  loading = new ReplaySubject<boolean>(1);
  brandName: string;
  serieName: string;

  printableData: PrintableData = {
    modelId: null,
    pieces: null,
    isActive: true,
    type: null,
    bbox: { x: 0, y: 0, width: 0, height: 0 },
  };
  queryParams: any;

  loadingBrands = new ReplaySubject<boolean>(1);
  loadingSeries = new ReplaySubject<boolean>(1);
  loadingModels = new ReplaySubject<boolean>(1);

  constructor(
    public router: Router,
    protected route: ActivatedRoute,
    protected snackBar: MatSnackBar,
    protected modelService: ModelService,
    protected printableDataService: PrintableDataService,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
  ) {
    super(router);
  }

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;
    this.getBrands();
  }

  getBrands() {
    this.loadingBrands.next(true);
    this.modelService
      .getBrandList()
      .pipe(distinctUntilChanged(), share())
      .subscribe({
        next: response => {
          this.loadingBrands.next(false);
          this.brandList.next(response.data.listing);
        },
        error: err => {
          this.loadingBrands.next(true);
          this.handleErrors.handleErrors(err);
        },
      });
  }

  getSeries(brandName: string) {
    this.loadingSeries.next(true);
    this.brandName = brandName;
    this.modelService
      .getSerieList({ brandName: brandName })
      .pipe(distinctUntilChanged(), share())
      .subscribe({
        next: response => {
          this.loadingSeries.next(false);
          this.serieList.next(response.data.listing);
        },
        error: err => {
          this.loadingSeries.next(false);
          this.handleErrors.handleErrors(err);
        },
      });
  }

  getModels(serieName: string) {
    this.loadingModels.next(true);
    const query = { brandName: this.brandName, serieName: serieName };
    this.modelService
      .getModelList(query)
      .pipe(distinctUntilChanged(), share())
      .subscribe({
        next: response => {
          this.loadingModels.next(false);
          this.modelList.next(response.data.listing);
        },
        error: err => {
          this.loadingModels.next(false);
          this.handleErrors.handleErrors(err);
        },
      });
  }

  loadSvg(svg: File) {
    this.modelService.loadSVG(svg).subscribe({
      next: result => {
        this.printableData.pieces = result;
        this.parsedSvg.next(result);
      },
    });
  }

  getFormInfo(form: { model: number; type: string }) {
    this.printableData.modelId = form.model;
    this.printableData.type = form.type;
  }

  updateInfo(event) {
    this.printableData.pieces = event.path;
    const box = { ...{ x: event.bbox.x, y: event.bbox.y, height: event.bbox.height, width: event.bbox.width } };
    this.printableData.bbox = box;
  }

  addPrintableData() {
    // Reminder changer l envoie des printable data avec les nouvelles donne collecte durant le parse dans le viewer
    this.loading.next(true);
    this.printableDataService.beforeSendSvgEvent.next(null);

    this.printableDataService.addPrintableData(this.printableData).subscribe({
      next: () => {
        this.leaveDialog(this.queryParams);
        this.translateService.get('trac-message-success').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
      },
      error: err => {
        this.loading.next(false);
        this.handleErrors.handleErrors(err);
      },
    });
  }
}
