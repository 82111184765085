<div class="contents">
  @if (loggings) {
    <div>
      <ngx-datatable
        [rows]="loggings?.listing"
        [footerHeight]="30"
        [columnMode]="'force'"
        [rowHeight]="'auto'"
        (page)="handlePages($event)"
        [limit]="10"
        [externalPaging]="true"
        [count]="loggings?.total"
        [offset]="offset"
        [messages]="messages$ | async"
      >
        <ngx-datatable-column [name]="'admin' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/admin-details', row?.admin.id, 'admin-profile']">
              {{ row?.admin.fullName }}
            </a>
            <spinner [route]="'/admin-details/' + row.id + '/admin-profile'"></spinner>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'credit-type' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.extra.creditType }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'value' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.extra.quantity }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'date' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span [matTooltip]="row?.createdAt | date: 'dd/MM/yyyy, HH:mm'">{{ row?.createdAt | date: 'dd/MM, HH:mm' }}</span>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  } @else {
    <div>
      <mat-spinner></mat-spinner>
    </div>
  }
</div>
