<div class="contents">
  @if (modelList) {
    <div>
      <ngx-datatable
        [rows]="modelList.listing"
        [footerHeight]="30"
        [columnMode]="'force'"
        [rowHeight]="'auto'"
        (page)="handlePages($event)"
        [limit]="10"
        [externalPaging]="true"
        [count]="modelList.total"
        [offset]="offset"
        [messages]="messages$ | async"
      >
        <ngx-datatable-column [name]="'brand' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/management/brand-management']" [queryParams]="{ brandName: row?.serie?.brand?.name, exactSearch: true }">
              {{ row?.serie?.brand?.name }}
            </a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'serie' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              [routerLink]="['/management/serie-management']"
              [queryParams]="{ brandName: row.serie.brand.name, serieName: row.serie.name, exactSearch: true, showParents: true }"
            >
              {{ row.serie.name }}
            </a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'model' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.name }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'trace' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              [routerLink]="['/printable-data-management']"
              [queryParams]="{
                brandName: row.serie.brand.name,
                serieName: row.serie.name,
                modelName: row.name,
                exactSearch: true,
                showParents: true
              }"
            >
              {{ 'show-trace' | translate }}
            </a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [maxWidth]="60" [name]="'edit' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button mat-icon-button color="accent" (click)="navigate(row.id)">
              <mat-icon svgIcon="ic_edit_24px"></mat-icon>
            </button>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [cellClass]="'item-cell'"
          [maxWidth]="100"
          [name]="'delete' | translate"
          [sortable]="false"
          [draggable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button mat-icon-button (click)="deleteModel(row)" color="warn">
              <mat-icon svgIcon="ic_delete_24px"></mat-icon>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  } @else {
    <div>
      <mat-spinner></mat-spinner>
    </div>
  }
</div>
