import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { BannerService, HandleErrorsService } from 'app/app-services';
import { Banner } from 'app/models/banner.model';
import { switchMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'banner-profil-tab',
  templateUrl: './banner-profil-tab.component.html',
  styleUrls: ['./banner-profil-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerProfilTabComponent implements OnInit {
  banner: Banner;
  bannerFromForm: Banner;
  adminId: number;
  resetForm: boolean;

  constructor(
    private readonly bannerService: BannerService,
    private readonly snackBar: MatSnackBar,
    private readonly handleErrors: HandleErrorsService,
    private readonly route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    protected translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.banner = this.route.snapshot.data.adminBanner;
    this.adminId = this.route.parent.snapshot.params['id'];
  }

  updateBanner(banner: Banner) {
    this.bannerFromForm = banner;
  }

  editBanner() {
    const data = {
      ...this.banner,
      picture: this.bannerFromForm.picture,
      adminId: this.adminId,
    };

    const method = this.banner?.id
      ? this.bannerService.editBanner(this.banner.id, { picture: this.bannerFromForm.picture })
      : this.bannerService.addBanner(data);

    method.pipe(switchMap(() => this.bannerService.getBanner(this.adminId))).subscribe({
      next: banner => {
        const message = this.banner?.id ? 'banner-update-success' : 'banner-message-success';
        this.translateService.get(message).subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
        this.banner = banner;
        this.bannerFromForm = undefined;
        this.resetForm = true;
        this.changeDetectorRef.markForCheck();
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }
}
