<h2 mat-dialog-title>{{ 'new-serie' | translate }}</h2>
<mat-dialog-content>
  <serie-form #serieForm [brandList]="brandList | async" [serie]="serie" (formSubmit)="handleFormInfo($event)"></serie-form>
</mat-dialog-content>

<mat-dialog-actions>
  @if (loading | async) {
    <mat-spinner></mat-spinner>
  }
  <button mat-raised-button color="accent" (click)="createSerie()" [disabled]="serieForm.serieForm.invalid || (loading | async)">
    {{ 'add' | translate }}
  </button>
  <button mat-raised-button color="warn" (click)="leaveDialog(queryParams)">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
