import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';

import { AuxiliaryRouteClass } from '../app-interfaces/close-dialog.interface';
import { HandleErrorsService, ModelService } from '../app-services';
import { Brand } from '../models/brand.model';
import { Serie } from '../models/serie.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'add-serie-dialog',
  templateUrl: './add-serie-dialog.component.html',
  styleUrls: ['./add-serie-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSerieDialogComponent extends AuxiliaryRouteClass implements OnInit {
  brandList = new ReplaySubject<Brand[]>(1);
  loading = new ReplaySubject<boolean>(1);

  formInfo: Serie = null;
  serie: Serie = {
    brandId: null,
    name: null,
  };
  queryParams: any;

  constructor(
    public router: Router,
    protected route: ActivatedRoute,
    protected snackBar: MatSnackBar,
    protected modelService: ModelService,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
  ) {
    super(router);
  }

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;

    this.modelService.getBrandList().subscribe({
      next: response => {
        this.brandList.next(response.data.listing);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  handleFormInfo(formInfo: Serie) {
    this.formInfo = formInfo;
  }

  createSerie() {
    this.loading.next(true);
    this.modelService.addSerie({ brandId: this.formInfo.brandId, name: this.formInfo.name.trim() }).subscribe({
      next: () => {
        this.translateService.get('serie-message-success').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
        this.leaveDialog(this.queryParams);
      },
      error: err => {
        this.loading.next(false);
        this.handleErrors.handleErrors(err);
      },
    });
  }
}
