import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { Admin } from '../models/admin.model';
import { User } from '../models/user.model';
import { isEmpty } from 'lodash';

@Component({
  selector: 'global-users-filter',
  templateUrl: './global-users-filter.component.html',
  styleUrls: ['./global-users-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalUsersFilterComponent implements OnChanges, OnDestroy {
  @Input() users: User[];
  @Input() admins: Admin[];
  @Input() searchedUsers: User[];
  @Input() searchedAdmins: Admin[];

  @Output() filterUsers = new EventEmitter();
  @Output() queryAdminsOrUsers = new EventEmitter<FilterQuery>();
  filterForm: FormGroup;
  private readonly unsubscribeSubject: Subject<void>;
  @ViewChild('adminInput') adminInput: ElementRef<HTMLInputElement>;
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  constructor() {
    this.unsubscribeSubject = new Subject<void>();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (!this.filterForm) {
      this.buildForm();
    }

    if (!isEmpty(changes['searchedUsers']?.currentValue)) {
      this.filterForm.get('users').setValue(this.searchedUsers);
      this.searchedUsers = null;
    }

    if (!isEmpty(changes['searchedAdmins']?.currentValue)) {
      this.filterForm.get('admins').setValue(this.searchedAdmins);
      this.searchedAdmins = null;
    }
  }

  private buildForm() {
    this.filterForm = new FormGroup({
      admins: new FormControl(null),
      users: new FormControl(null),
      userSearch: new FormControl(null),
      adminSearch: new FormControl(null),
    });

    this.filterForm
      .get('adminSearch')
      .valueChanges.pipe(
        takeUntil(this.unsubscribeSubject),
        debounceTime(400),
        distinctUntilChanged(),
        filter(admin => admin?.length > 2),
      )
      .subscribe(adminSearch => {
        this.getUsersOrAdmins('admins', adminSearch, this.filterForm.controls['admins'].value);
      });

    this.filterForm
      .get('userSearch')
      .valueChanges.pipe(
        takeUntil(this.unsubscribeSubject),
        debounceTime(400),
        distinctUntilChanged(),
        filter(user => user?.length > 2),
      )
      .subscribe(userSearch => {
        this.getUsersOrAdmins('users', userSearch, this.filterForm.controls['users'].value);
      });
  }

  filterByAdmins() {
    this.filterForm.get('users').setValue(null);
  }

  filterByUsers() {
    this.filterForm.get('admins').setValue(null);
  }

  search(form: { admins: TagInput[]; users: TagInput[] }) {
    this.filterUsers.emit({
      adminIds: form.admins?.length ? form.admins.map(a => a.id).join(',') : null,
      userIds: form.users?.length ? form.users.map(a => a.id).join(',') : null,
    });
  }

  getUsersOrAdmins(type: string, name: string, exclude: TagInput[]) {
    this.queryAdminsOrUsers.emit({
      type: type,
      like: name !== '' ? name : null,
      exclude: exclude?.length ? exclude.map(m => m.id) : null,
    });
  }
  removeUser(user: User): void {
    const users: User[] = this.filterForm.get('users').value;
    this.filterForm.get('users').setValue(users.filter(item => item.id !== user.id));
  }

  selectedUser(event: MatAutocompleteSelectedEvent): void {
    const value: User[] = this.filterForm.get('users').value ?? [];
    this.filterForm.get('users').setValue([...value, event.option.value]);

    this.userInput.nativeElement.value = '';
    this.filterForm.get('userSearch').setValue(null);
    this.users = undefined;
  }
  removeAdmin(admin: Admin): void {
    const admins: Admin[] = this.filterForm.get('admins').value;
    this.filterForm.get('admins').setValue(admins.filter(item => item.id !== admin.id));
  }

  selectedAdmin(event: MatAutocompleteSelectedEvent): void {
    const value: Admin[] = this.filterForm.get('admins').value ?? [];
    this.filterForm.get('admins').setValue([...value, event.option.value]);

    this.adminInput.nativeElement.value = '';
    this.filterForm.get('adminSearch').setValue(null);
    this.admins = undefined;
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}

export type TagInput = {
  id: number;
  value: string;
  fullName: string;
};

type FilterQuery = { type: string; like: string; exclude: number[] };
