import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, MagicTranslateService } from '../app-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected authService: AuthService,
    protected snackBar: MatSnackBar,
    protected translateService: TranslateService,
    private magicTranslateService: MagicTranslateService,
  ) {}

  login(userInfo: { email: string; password: string }) {
    if (!Object.keys(this.route.snapshot.queryParams).length) {
      this.authService.loginAdmin(userInfo);
      this.loginObs(this.authService.loginAdmin(userInfo));
    } else {
      const scope: string = this.route.snapshot.queryParams['scope'];
      let method;
      switch (scope.toLowerCase()) {
        case 'super-admin':
          method = this.authService.loginSuperAdmin(userInfo);
          break;
        case 'model-admin':
          method = this.authService.loginModelAdmin(userInfo);
          break;
        case 'admin':
        default:
          method = this.authService.loginAdmin(userInfo);
          break;
      }
      this.loginObs(method);
    }
  }

  loginObs(method) {
    method.subscribe({
      next: res => {
        const paths = {
          ADMIN: '/users-management',
          SUPER_ADMIN: '/admin-management',
          MODEL_ADMIN: '/printable-data-management',
        };

        this.magicTranslateService.updateUserLanguage(res.data.payload.language);
        this.router.navigate([paths[res.data.payload.role]]);
        this.translateService.get('login-success').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
      },
      error: err => {
        switch (true) {
          case err.statusCode >= 500 && err.statusCode <= 511:
            this.translateService.get('server-error').subscribe(message => {
              this.snackBar.open(message, 'OK', { duration: 7000 });
            });
            break;
          case err.statusCode === 404:
            this.translateService.get('not-found').subscribe(message => {
              this.snackBar.open(message, 'OK', { duration: 7000 });
            });
            this.router.navigate([{ outlets: { dialog: null, primary: ['error-404'] } }]);
            break;
          case err.statusCode === 401:
            this.translateService.get('incorrect-email-password').subscribe(message => {
              this.snackBar.open(message, 'OK', { duration: 7000 });
            });
            break;
          case err.statusCode === 403:
            this.translateService.get('no-acces').subscribe(message => {
              this.snackBar.open(message, 'OK', { duration: 7000 });
            });
            break;
          default:
            this.translateService.get('client-error').subscribe(message => {
              this.snackBar.open(message + ' ' + err.statusCode, 'OK', { duration: 7000 });
            });
            break;
        }
      },
    });
  }

  navigate(scope: string) {
    this.router.navigate(['/request-password-reset', scope]);
  }
}
