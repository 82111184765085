import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ExtendedPrintableData, Piece } from 'app/models/model.model';

@Component({
  selector: 'printable-data-edit-dumb',
  templateUrl: './printable-data-edit-dumb.component.html',
  styleUrls: ['./printable-data-edit-dumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintableDataEditDumbComponent implements OnInit {
  @Input() printableData: ExtendedPrintableData;
  @Input() parsedSvg: Piece[];

  modelName: string;

  ngOnInit() {
    this.modelName =
      this.printableData.model?.serie?.brand?.name + ' / ' + this.printableData.model?.serie?.name + ' / ' + this.printableData.model?.name;
  }
}
