import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '../models/user.model';
import { AuthService } from 'app/app-services';

@Component({
  selector: 'user-details-tabs',
  templateUrl: './user-details-tabs.component.html',
  styleUrls: ['./user-details-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailsTabsComponent {
  @Input() user: User;
  constructor(protected readonly authService: AuthService) {}
}
