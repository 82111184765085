<mat-toolbar>
  <form (ngSubmit)="search()" [formGroup]="plotterFilterForm" novalidate>
    <mat-form-field [subscriptSizing]="'dynamic'">
      <input matInput [placeholder]="'name' | translate" formControlName="name" [matAutocomplete]="autoName" />
    </mat-form-field>
    <mat-autocomplete #autoName="matAutocomplete">
      @for (option of plotterList; track option) {
        <mat-option [value]="option.name">
          {{ option.name }}
        </mat-option>
      }
    </mat-autocomplete>

    <mat-form-field [subscriptSizing]="'dynamic'">
      <input matInput [placeholder]="'vendorId' | translate" formControlName="vendorId" [matAutocomplete]="auto" />
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete">
      @for (option of plotterList; track option) {
        <mat-option [value]="option.vendorId">
          {{ option.vendorId }}
        </mat-option>
      }
    </mat-autocomplete>

    <mat-form-field [subscriptSizing]="'dynamic'">
      <input matInput [placeholder]="'productId' | translate" formControlName="productId" [matAutocomplete]="autoProd" />
    </mat-form-field>
    <mat-autocomplete #autoProd="matAutocomplete">
      @for (option of plotterList; track option) {
        <mat-option [value]="option.productId">
          {{ option.productId }}
        </mat-option>
      }
    </mat-autocomplete>

    <button mat-mini-fab color="accent" type="submit">
      <mat-icon svgIcon="ic_search_24px"></mat-icon>
    </button>
  </form>
  <div class="add-btn">
    <a mat-raised-button color="accent" (click)="onAddPlotter()">
      <mat-icon svgIcon="ic_add_24px"></mat-icon>
      {{ 'add' | translate }}
    </a>
  </div>
</mat-toolbar>
