import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ExtendedPrintableData, Piece } from 'app/models/model.model';
import { ReplaySubject } from 'rxjs';
import { AuxiliaryRouteClass } from '../app-interfaces/close-dialog.interface';

@Component({
  selector: 'printable-data-preview-dialog',
  templateUrl: './printable-data-preview-dialog.component.html',
  styleUrls: ['./printable-data-preview-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintableDataPreviewDialogComponent extends AuxiliaryRouteClass implements OnInit {
  parsedSvg = new ReplaySubject<Piece[]>(1);
  queryParams: any;

  constructor(
    public router: Router,
    protected route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public printableData: ExtendedPrintableData,
  ) {
    super(router);
  }

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;
    this.parsedSvg.next(this.printableData.pieces);
  }
}
