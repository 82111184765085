import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Admin } from '../models/admin.model';

@Component({
  selector: 'admin-users-management',
  templateUrl: './admin-users-management.component.html',
  styleUrls: ['./admin-users-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminUsersManagementComponent implements OnInit {
  adminDetails: Admin;

  constructor(protected route: ActivatedRoute) {}

  ngOnInit() {
    this.adminDetails = this.route.snapshot.data['currentAdminData'];
  }
}
