import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Brand } from '../models/brand.model';
import { Model } from '../models/model.model';
import { Serie } from '../models/serie.model';

@Component({
  selector: 'printable-data-filter',
  templateUrl: './printable-data-filter.component.html',
  styleUrls: ['./printable-data-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintableDataFilterComponent implements OnChanges, OnInit, OnDestroy {
  @Input() modelList: Model[];
  @Input() brandList: Brand[];
  @Input() serieList: Serie[];
  @Input() searchTerms: { brandName: string; serieName: string; modelName: string; type: string };

  @Output() filterPrintableData = new EventEmitter();
  @Output() fetchLists = new EventEmitter<{ type: string; name: string }>();
  @Output() routeNavigate = new EventEmitter();

  filterForm: FormGroup;

  printableTypes = ['TOUS', 'PPF', 'VITRE'];

  private readonly unsubscribeSubject: Subject<void>;
  constructor(protected router: Router) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  ngOnInit() {
    Object.keys(this.filterForm.value)
      .filter(f => f !== 'type')
      .map(k => {
        this.filterForm.controls[k].valueChanges
          .pipe(debounceTime(250), distinctUntilChanged(), takeUntil(this.unsubscribeSubject))
          .subscribe(name => {
            this.fetchLists.emit({ type: k, name: name.trim() });
          });
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.filterForm) {
      this.buildForm();
    }

    if (changes['searchTerms'] && changes['searchTerms'].previousValue === undefined && changes['searchTerms'].currentValue) {
      this.searchTerms['type'] = this.searchTerms['type'] === null ? 'TOUS' : this.searchTerms['type'];
      Object.keys(this.filterForm.value).map(k => {
        this.filterForm.get(k).setValue(this.searchTerms[k]);
      });
    }
  }

  search() {
    const model = this.filterForm.controls['modelName'].value;
    const serie = this.filterForm.controls['serieName'].value;
    const brand = this.filterForm.controls['brandName'].value;
    const type = this.filterForm.controls['type'].value;
    this.filterPrintableData.emit({
      brandName: brand ? brand.trim() : null,
      serieName: serie ? serie.trim() : null,
      modelName: model ? model.trim() : null,
      type: type && type !== 'TOUS' ? (type !== 'VITRE' ? type : 'WINDOW') : null,
      showParents: true,
    });
    this.brandList = undefined;
    this.serieList = undefined;
    this.modelList = undefined;
  }

  buildForm() {
    this.filterForm = new FormGroup({
      brandName: new FormControl(null),
      serieName: new FormControl(null),
      modelName: new FormControl(null),
      type: new FormControl(null),
    });
  }

  navigate() {
    this.routeNavigate.emit(null);
  }
}
