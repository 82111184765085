import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { AuxiliaryRouteClass } from '../app-interfaces/close-dialog.interface';
import { AdminService, AuthService, ModelAdminService, SuperAdminService } from '../app-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordDialogComponent extends AuxiliaryRouteClass {
  passwords: {
    currentPassword: string;
    newPassword: string;
  };
  loading = new ReplaySubject<boolean>(1);

  constructor(
    public router: Router,
    protected snackBar: MatSnackBar,
    protected authService: AuthService,
    protected adminService: AdminService,
    protected superAdminService: SuperAdminService,
    protected modelAdminService: ModelAdminService,
    protected translateService: TranslateService,
  ) {
    super(router);
  }

  handlePasswords(passwords) {
    this.passwords = passwords;
  }

  setPassword() {
    this.loading.next(true);
    let method;
    switch (this.authService.credentials.payload.role) {
      case 'ADMIN':
        method = this.adminService.setPassword(this.passwords);
        break;

      case 'SUPER_ADMIN':
        method = this.superAdminService.setPassword(this.passwords);
        break;

      case 'MODEL_ADMIN':
        method = this.modelAdminService.setPassword(this.passwords);
        break;
      default:
        break;
    }
    method.subscribe({
      next: () => {
        this.leaveDialog();
        this.translateService.get('password-message-succes').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
      },
      error: err => {
        this.loading.next(false);
        switch (true) {
          case err.statusCode >= 500 && err.statusCode <= 511:
            this.translateService.get('server-error').subscribe(message => {
              this.snackBar.open(message, 'OK', { duration: 7000 });
            });
            break;
          case err.statusCode === 404:
            this.translateService.get('not-found').subscribe(message => {
              this.snackBar.open(message, 'OK', { duration: 7000 });
            });
            this.router.navigate([{ outlets: { dialog: null, primary: ['error-404'] } }]);
            break;
          case err.statusCode === 401:
            this.translateService.get('no-auth').subscribe(message => {
              this.snackBar.open(message, 'OK', { duration: 7000 });
            });
            break;
          case err.statusCode === 403:
            this.translateService.get('no-acces').subscribe(message => {
              this.snackBar.open(message, 'OK', { duration: 7000 });
            });
            break;
          case err.statusCode === 400:
            this.translateService.get('incorrect-password"').subscribe(message => {
              this.snackBar.open(message, 'OK', { duration: 7000 });
            });
            break;
          default:
            this.translateService.get('client-error').subscribe(message => {
              this.snackBar.open(message + err.statusCode, 'OK', { duration: 7000 });
            });
            break;
        }
      },
    });
  }
}
