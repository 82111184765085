import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { AuthService, HandleErrorsService, MagicTranslateService, ModelAdminService } from '../app-services';
import { ModelAdmin } from '../models/model-admin.model';
import { AvailableLanguagesEnum } from 'app/models/available-languages.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'model-admin-profile',
  templateUrl: './model-admin-profile.component.html',
  styleUrls: ['./model-admin-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelAdminProfileComponent implements OnInit {
  modelAdmin: ModelAdmin;
  loading = new ReplaySubject<boolean>(1);

  constructor(
    protected modelAdminService: ModelAdminService,
    protected authService: AuthService,
    protected snackBar: MatSnackBar,
    protected route: ActivatedRoute,
    protected translateService: TranslateService,
    protected handleErrors: HandleErrorsService,
    private magicTranslateService: MagicTranslateService,
  ) {}

  ngOnInit() {
    this.modelAdmin = this.route.snapshot.data['currentModelAdmin'];
  }

  cancelEdit() {
    this.modelAdmin = Object.assign({}, this.modelAdmin);
  }

  updateModelAdmin(data: { email: string; language: AvailableLanguagesEnum; fullName: string }) {
    this.loading.next(false);
    this.modelAdminService.updateModelAdmin({ ...data, id: this.modelAdmin.id }).subscribe({
      next: () => {
        this.magicTranslateService.updateUserLanguage(data.language);
        this.loading.next(true);
        this.translateService.get('update-success').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
      },
      error: err => {
        this.loading.next(true);
        this.handleErrors.handleErrors(err);
      },
    });
  }
}
