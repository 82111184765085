import { AbstractControl, ValidatorFn } from '@angular/forms';

export function autocompleteAdminValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'string' && control.value.length > 0) {
      return { invalidAdmin: { value: control.value } };
    }

    return null; /* valid option selected */
  };
}
