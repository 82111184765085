import { throwError as observableThrowError } from 'rxjs';

import { Injectable } from '@angular/core';

import { catchError, map } from 'rxjs/operators';
import { HandleErrorsService, SuperAdminService } from '../app-services';

@Injectable()
export class SuperAdminResolver {
  constructor(
    protected superAdminService: SuperAdminService,
    protected handleErrors: HandleErrorsService,
  ) {}

  resolve() {
    return this.superAdminService.getSuperAdmin().pipe(
      map(s => s.data),
      catchError(err => {
        this.handleErrors.handleErrors(err);

        return observableThrowError(err);
      }),
    );
  }
}
