import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged, filter, Subject, takeUntil } from 'rxjs';
import { omitBy, isEmpty } from 'lodash';
import { Model } from '../models/model.model';
import { QueryEvent } from 'app/models/search-event.model';

@Component({
  selector: 'user-printing-filter',
  templateUrl: './user-printing-filter.component.html',
  styleUrls: ['./user-printing-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPrintingFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() models: Model[];
  @Input() searchedDates: { startDate: Date; endDate: Date };
  @Input() searchedModels: Model[];
  @Output() filterPrintings = new EventEmitter();
  @Output() queryModels = new EventEmitter<QueryEvent>();
  printingFilterForm: FormGroup;

  maxFirstDate = new Date();
  minLastDate: Date = null;
  maxLastDate = new Date();
  @ViewChild('modelInput') modelInput: ElementRef<HTMLInputElement>;
  private readonly unsubscribeSubject: Subject<void>;

  constructor() {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.printingFilterForm) {
      this.buildForm();
    }

    if (changes['searchedModels'] && changes['searchedModels'].previousValue === null && changes['searchedModels'].currentValue) {
      this.printingFilterForm.get('models').setValue(this.searchedModels);
      this.searchedModels = null;
    }

    if (changes['searchedDates'] && changes['searchedDates'].previousValue === undefined && changes['searchedDates'].currentValue) {
      this.printingFilterForm.get('endInterval').setValue(this.searchedDates['endDate'] ? new Date(+this.searchedDates['endDate']) : null);
      this.printingFilterForm
        .get('startInterval')
        .setValue(this.searchedDates['startDate'] ? new Date(+this.searchedDates['startDate']) : null);
    }
  }

  private buildForm() {
    this.printingFilterForm = new FormGroup({
      startInterval: new FormControl(null),
      endInterval: new FormControl(null),
      models: new FormControl(null),
      modelSearch: new FormControl(null),
    });
  }

  ngOnInit() {
    this.printingFilterForm
      .get('modelSearch')
      .valueChanges.pipe(
        takeUntil(this.unsubscribeSubject),
        debounceTime(400),
        distinctUntilChanged(),
        filter(modelSearch => modelSearch?.length > 2),
      )
      .subscribe(modelSearch => {
        this.getModels(modelSearch);
      });

    if (this.printingFilterForm.controls['startInterval'].value != null) {
      this.minLastDate = this.printingFilterForm.controls['startInterval'].value;
    }

    if (this.printingFilterForm.controls['endInterval'].value != null) {
      this.maxFirstDate = this.printingFilterForm.controls['endInterval'].value;
    }

    this.printingFilterForm.controls['startInterval'].valueChanges
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(fDate => (this.minLastDate = fDate));
    this.printingFilterForm.controls['endInterval'].valueChanges
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(lDate => (this.maxFirstDate = lDate));
  }

  getModels(modelName: string) {
    const models = this.printingFilterForm.controls['models'].value || [];
    this.queryModels.emit(
      omitBy(
        {
          modelName: modelName,
          exclude: models.map(m => m.id),
        },
        isEmpty,
      ),
    );
  }

  search() {
    const model = this.printingFilterForm.controls['models'].value || [];
    const startDate = this.printingFilterForm.controls['startInterval'].value;
    const endDate = this.printingFilterForm.controls['endInterval'].value;

    this.filterPrintings.emit({
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
      modelIds: model.length ? model.map(a => a.id).join(',') : null,
    });
  }

  removeModel(model: Model): void {
    const models: Model[] = this.printingFilterForm.get('models').value;
    this.printingFilterForm.get('models').setValue(models.filter(item => item.id !== model.id));
  }

  selectedModel(event: MatAutocompleteSelectedEvent): void {
    const value: Model[] = this.printingFilterForm.get('models').value ?? [];
    this.printingFilterForm.get('models').setValue([...value, event.option.value]);

    this.modelInput.nativeElement.value = '';
    this.printingFilterForm.get('modelSearch').setValue(null);
    this.models = undefined;
  }
  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
