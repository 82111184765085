<h1 mat-dialog-title>
  <span>{{ 'edit-admin-models' | translate }}</span>
</h1>
<mat-dialog-content>
  <model-admin-form #modelAdminForm [modelAdmin]="modelAdmin" (submitValues)="handleModelAdminInfo($event)"> </model-admin-form>
</mat-dialog-content>
<mat-dialog-actions>
  @if (loading | async) {
    <mat-spinner></mat-spinner>
  }
  <button
    mat-raised-button
    [disabled]="modelAdminForm.modelAdminForm.status !== 'VALID' || !updatedModelAdmin || (loading | async)"
    color="accent"
    (click)="updateModelAdmin()"
  >
    {{ 'edit' | translate }}
  </button>
  <button mat-raised-button color="warn" (click)="leaveDialog()">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
