import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HandleErrorsService, UsersService } from 'app/app-services';
import { Admin, adminCreditUpdate } from 'app/models/admin.model';
import { User } from 'app/models/user.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'profile-credit',
  templateUrl: './profile-credit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileCreditComponent implements OnInit {
  admin: Admin;
  user: User;

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly userService: UsersService,
    protected snackBar: MatSnackBar,
    protected router: Router,
    protected translateService: TranslateService,
    protected handleErrors: HandleErrorsService,
  ) {}

  ngOnInit(): void {
    this.admin = this.route.snapshot.data.adminDetails;
    this.user = this.route.parent.snapshot.data.userDetails;
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: false }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  updateCredit(extra: adminCreditUpdate) {
    if (extra.extraPPF || extra.extraWindows) {
      this.userService.updateCredit({ id: this.user.id, ppf: extra.extraPPF, window: extra.extraWindows }).subscribe({
        next: () => {
          this.translateService.get('credit-update-success').subscribe(message => {
            this.snackBar.open(message, 'OK', { duration: 7000 });
          });
          this.reloadCurrentRoute();
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
    }
  }
}
