import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../app-services';

@Injectable()
export class IsSuperAdminGuard {
  constructor(
    protected router: Router,
    protected authService: AuthService,
  ) {}

  canActivate() {
    if (this.authService.isSuperAdmin()) {
      return true;
    } else {
      this.router.navigate(['/']);

      return false;
    }
  }
}
