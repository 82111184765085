<div class="contents" #tableWrapper>
  @if (users) {
    <div>
      <ngx-datatable
        class="material"
        #dataTable
        [rows]="users.listing"
        [footerHeight]="30"
        [columnMode]="'force'"
        [rowHeight]="'auto'"
        (page)="handlePages($event)"
        [limit]="10"
        [externalPaging]="true"
        [count]="users.total"
        [offset]="offset"
        [messages]="messages$ | async"
      >
        <ngx-datatable-column [name]="'user' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/user-details', row.id, 'profile']"> {{ row.fullName }} </a>
            <spinner [route]="'/user-details/' + row.id + '/profile'"></spinner>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'phone' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.phone }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'country' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.country }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="('address' | translate) + ',' + ('ville' | translate)" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.address }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'email' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.email }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [cellClass]="'pieces-cell'"
          [headerClass]="'pieces-cell'"
          [name]="'cut-number' | translate"
          [sortable]="false"
          [draggable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template> P:{{ row.allowedPPF }}/V:{{ row.allowedWindows }} </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [maxWidth]="55" name="Etat" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <mat-slide-toggle [checked]="row.isActive" [disabled]="!isAdmin" (change)="changeStatus(row.id, $event)"></mat-slide-toggle>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  } @else {
    <div>
      <mat-spinner></mat-spinner>
    </div>
  }
</div>
