import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityList } from 'app/models/entity-list.model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExtendedPrintableData, PrintableData } from '../models/model.model';

@Injectable()
export class PrintableDataService {
  update = new Subject<{ operation: string; value: PrintableData[] }>();
  beforeSendSvgEvent = new Subject<any>();

  constructor(private _http: HttpClient) {}

  getPrintableData(id: number): Observable<{ statusCode: number; data: PrintableData }> {
    return this._http.get('/api/printable-data/' + id, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: PrintableData; statusCode: number }>) => {
        return res.body;
      }),
    );
  }

  getPrintableDataList(query: PrintableDataQuery = {}): Observable<{ statusCode: number; data: EntityList<ExtendedPrintableData> }> {
    const route = {
      brandName: 'brand-name',
      serieName: 'serie-name',
      modelName: 'model-name',
      expand: 'expand',
      exactSearch: 'exact-search',
      offset: 'offset',
      limit: 'limit',
      showParents: 'show-parents',
      modelIds: 'model-ids',
      type: 'type',
      entities: 'entities',
      preview: 'preview',
    };
    const questionMark = Object.keys(query).length > 0 ? '?' : '';
    const url =
      '/api/printable-data/list' +
      questionMark +
      Object.keys(query)
        .map(k =>
          Array.isArray(query[k]) ? query[k].map((value, index) => `${route[k]}[${index}]=${value}`).join('&') : `${route[k]}=${query[k]}`,
        )
        .join('&');

    return this._http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<{ data: EntityList<ExtendedPrintableData>; statusCode: number }>) => res.body));
  }

  addPrintableData(printableData: PrintableData): Observable<{ statusCode: number; data: PrintableData }> {
    return this._http.post('/api/printable-data', printableData, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: PrintableData; statusCode: number }>) => {
        this.update.next({ operation: 'addPrintableData', value: [res.body.data] });

        return res.body;
      }),
    );
  }

  deletePrintableData(printableDataId: number) {
    return this._http.delete('/api/printable-data/' + printableDataId, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: PrintableData; statusCode: number }>) => {
        this.update.next({ operation: 'deletePrintableData', value: [] });

        return res.body;
      }),
    );
  }
}

export type PrintableDataQuery = {
  brandName?: string;
  serieName?: string;
  modelName?: string;
  expand?: number[];
  offset?: number;
  limit?: number;
  showParents?: boolean;
  modelIds?: number[];
  type?: string;
  entities?: boolean;
  preview?: boolean;
};
