<div class="contents">
  @if (plotterList$) {
    <div>
      <ngx-datatable
        [rows]="plotterList$?.listing"
        [footerHeight]="30"
        [columnMode]="'force'"
        [rowHeight]="'auto'"
        (page)="handlePages($event)"
        [limit]="10"
        [externalPaging]="true"
        [count]="plotterList$?.total"
        [offset]="offset"
        [messages]="messages$ | async"
      >
        <ngx-datatable-column name="name" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.name }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="vendorId" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.vendorId }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="productId" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.productId }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'date' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span [matTooltip]="row?.createdAt | date: 'dd/MM/yyyy, HH:mm'">{{ row?.createdAt | date: 'dd/MM, HH:mm' }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [cellClass]="'item-cell'"
          [maxWidth]="100"
          [name]="'delete' | translate"
          [sortable]="false"
          [draggable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button mat-icon-button color="warn" (click)="onDelete(row.id)">
              <mat-icon svgIcon="ic_delete_24px"></mat-icon>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  } @else {
    <div>
      <mat-spinner></mat-spinner>
    </div>
  }
</div>
