<h1 mat-dialog-title>
  <span>{{ 'confirm_delete_plotter' | translate }}?</span>
</h1>
<mat-dialog-actions>
  @if (loading | async) {
    <mat-spinner></mat-spinner>
  }
  <button mat-raised-button color="warn" (click)="deleteModel()" [disabled]="loading | async">{{ 'delete' | translate }}</button>
  <button mat-raised-button color="accent" (click)="cancel()">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
