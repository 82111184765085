<h2>{{ 'trac-management' | translate }}</h2>
<printable-data-filter
  [brandList]="brandAutoCompleteList | async"
  [serieList]="serieAutoCompleteList | async"
  [modelList]="modelAutoCompleteList | async"
  [searchTerms]="searchTerms"
  (filterPrintableData)="filterPrintableData($event)"
  (fetchLists)="fetchLists($event)"
  (routeNavigate)="navigate($event)"
>
</printable-data-filter>
<printable-data-list
  [printableDataList]="printableDataList | async"
  [offset]="offset$ | async"
  (tablePagesNavigation)="pageNavigation($event)"
  (openDeletePrintableDataDialog)="openDeletePrintableDataDialog($event)"
  (routeNavigate)="navigate($event)"
>
</printable-data-list>
