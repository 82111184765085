<h3>{{ 'request-reset-email' | translate }}</h3>
<form (ngSubmit)="sendRequest()" [formGroup]="requestResetPasswordForm">
  <mat-form-field>
    <input matInput type="email" formControlName="email" placeholder="Adresse électronique" updateOnBlur />
    @if (requestResetPasswordForm.controls['email'].hasError('required')) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
    @if (requestResetPasswordForm.controls['email'].hasError('email') && !requestResetPasswordForm.controls['email'].hasError('required')) {
      <mat-error> {{ 'email-validation' | translate }} </mat-error>
    }
  </mat-form-field>
  @if (disabled) {
    <mat-spinner></mat-spinner>
  }
  <button mat-raised-button color="accent" type="submit" [disabled]="requestResetPasswordForm.invalid || disabled">
    {{ 'send' | translate }}
  </button>
</form>
