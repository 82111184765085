import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'delete-brand-dialog-dumb',
  templateUrl: './delete-brand-dialog-dumb.component.html',
  styleUrls: ['./delete-brand-dialog-dumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteBrandDialogDumbComponent {
  @Input() serieList: any[];
}
