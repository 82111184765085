<mc-image-cropper
  #cropper
  [src]="file"
  [cropWidth]="cropWidth"
  [cropHeight]="cropHeight"
  [zoom]="1"
  [left]="-200"
  [top]="-200"
></mc-image-cropper>
<mat-slider min="1" max="4" step="0.1" #ngSlider
  ><input matSliderThumb /><input matSliderThumb /><input matSliderThumb /><input matSliderThumb /><input matSliderThumb /><input
    matSliderThumb
    (change)="cropper.setZoom({ source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value }.value.toString())"
    #ngSliderThumb="matSliderThumb"
    value="1"
  />
</mat-slider>
