<div class="logo">
  <img src="/assets/magic-logo.png" />
</div>

<nav mat-tab-nav-bar mat-stretch-tabs="false" [tabPanel]="tabPanel">
  @for (element of menuList; track element; let i = $index) {
    <a mat-tab-link [routerLink]="[element.link]" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">
      {{ element.label | translate }}
    </a>
  }
</nav>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
<div class="header-spinner">
  <spinner [route]="'/admin-profile'"></spinner>
  <spinner [route]="'/model-admin-profile'"></spinner>
  <spinner [route]="'/users-management'"></spinner>
  <spinner [route]="'/plotters'"></spinner>
  <spinner [route]="'/super-admin-profile'"></spinner>
</div>

<div class="logout">
  <button color="primary" mat-button (click)="onLogout()">
    <mat-icon svgIcon="ic_power_settings_new_24px"></mat-icon>
    {{ 'logout' | translate }}
  </button>
</div>

<!-- <mat-tab-nav-panel #tabPanel>
<router-outlet></router-outlet>
</mat-tab-nav-panel> -->
