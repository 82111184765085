<h2>{{ 'payment-document-log' | translate }}</h2>
<admin-payment-document-log-filter
  class="toolbar-body"
  [searchedDates]="searchedDates"
  (filterQuery)="filterEPayment($event)"
></admin-payment-document-log-filter>
<admin-payment-document-log-list
  [paymentsDocumentList]="paymentsDocument$ | async"
  [offset]="offset$ | async"
  (submitFilterOptions)="pageNavigation($event)"
  (checkout)="onCheckout($event)"
>
</admin-payment-document-log-list>
