<h2>{{ 'user-management' | translate }}</h2>
<admin-users-filter
  class="toolbar-body"
  [users]="usersAutoCompleteList | async"
  [isAdmin]="isAdmin"
  [searchedUsers]="searchedUsers$ | async"
  (filterUsers)="filterUsers($event)"
  (queryUsers)="fetchUsers($event)"
  (addNewUser)="addNewUser()"
></admin-users-filter>
<admin-users-list
  [users]="usersList | async"
  [offset]="offset$ | async"
  [isAdmin]="isAdmin"
  (toggleActivation)="setActivation($event)"
  (submitFilterOptions)="pageNavigation($event)"
></admin-users-list>
