<h2>{{ 'profile' | translate }}</h2>
<div class="contents form-contents">
  <form (ngSubmit)="submitForm()" [formGroup]="modelAdminform">
    <mat-form-field>
      <mat-label translate="full-name" />
      <input matInput type="text" formControlName="fullName" updateOnBlur />
      @if (modelAdminform.controls['fullName'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label translate="email" />
      <input matInput type="email" formControlName="email" updateOnBlur />
      @if (modelAdminform.controls['email'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
      @if (modelAdminform.controls['email'].hasError('email')) {
        <mat-error> {{ 'email-validation' | translate }} </mat-error>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label translate="language" />
      <mat-select formControlName="language">
        @for (language of availableLanguages; track language) {
          <mat-option [value]="language.code">
            {{ language.label }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <div>
      <a [routerLink]="['/', { outlets: { dialog: ['change-password'] } }]"> {{ 'change-password' | translate }}</a>
    </div>
    <div class="buttons">
      @if (disabled) {
        <mat-spinner></mat-spinner>
      }
      <button mat-raised-button color="warn" [disabled]="modelAdminform.invalid || !modelAdminform.dirty || disabled" type="submit">
        {{ 'save' | translate }}
      </button>
      <button mat-raised-button color="accent" type="buttorn" (click)="cancel()">{{ 'cancel' | translate }}</button>
    </div>
  </form>
</div>
