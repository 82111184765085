<div class="printable-data-top">
  <form [formGroup]="printableDataForm" novalidate>
    <mat-form-field>
      <mat-label translate="brand" />
      <input type="text" matInput tabindex="-1" formControlName="brand" [matAutocomplete]="brands" />
      @if (loadingBrands) {
        <mat-progress-spinner matSuffix mode="indeterminate"></mat-progress-spinner>
      }
    </mat-form-field>
    <mat-autocomplete #brands="matAutocomplete">
      @for (brand of brandListAutocomplete | async; track brand) {
        <mat-option [matTooltip]="brand.name" [value]="brand.name" (onSelectionChange)="selectBrand($event)">
          {{ brand.name }}
        </mat-option>
      }
    </mat-autocomplete>

    <mat-form-field>
      <mat-label translate="serie" />
      <input type="text" matInput formControlName="serie" [matAutocomplete]="series" />
      @if (loadingSeries) {
        <mat-progress-spinner matSuffix mode="indeterminate"></mat-progress-spinner>
      }
    </mat-form-field>
    <mat-autocomplete #series="matAutocomplete">
      @for (serie of serieListAutocomplete | async; track serie) {
        <mat-option [matTooltip]="serie.name" [value]="serie.name" (onSelectionChange)="selectSerie($event)">
          {{ serie.name }}
        </mat-option>
      }
    </mat-autocomplete>

    <mat-form-field>
      <mat-label translate="model" />
      <input type="text" matInput formControlName="model" [matAutocomplete]="models" />
      @if (loadingModels) {
        <mat-progress-spinner matSuffix mode="indeterminate"></mat-progress-spinner>
      }
    </mat-form-field>
    <mat-autocomplete #models="matAutocomplete" [displayWith]="displayModels">
      @for (model of modelListAutocomplete | async; track model) {
        <mat-option [matTooltip]="model.name" [value]="model" (onSelectionChange)="selectModel($event)">
          {{ model.name }}
        </mat-option>
      }
    </mat-autocomplete>

    <mat-form-field>
      <mat-label translate="type" />
      <mat-select formControlName="type">
        @for (type of types; track type) {
          <mat-option [value]="type">
            {{ type }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </form>
  <div>
    <input #file hidden type="file" accept="image/svg+xml" (change)="getFile($event)" />
    <button mat-raised-button [disabled]="disableBtn" color="accent" (click)="file.click()">
      <mat-icon svgIcon="ic_note_add_24px"></mat-icon>
      {{ 'load-SVG' | translate }}
    </button>
  </div>
</div>
<div class="svg-viewer" (onDropSuccess)="dropFile($event)">
  @if (parsedSvg) {
    <model-viewer (updateParsedSVGAndBbox)="bubbleUpdate($event)" [parsedSVG]="parsedSvg"></model-viewer>
  }
</div>
