<h2>{{ 'profile' | translate }}</h2>
<div class="contents form-contents">
  <form (ngSubmit)="submitForm()" [formGroup]="adminForm">
    <mat-form-field>
      <mat-label translate="full-name" />
      <input matInput type="text" formControlName="fullName" updateOnBlur />
    </mat-form-field>

    <address-form
      [address]="{ country: admin.country, address: admin.address }"
      [canEdit]="true"
      (addressValue)="updateAddressValue($event)"
    ></address-form>

    <mat-form-field>
      <mat-label translate="email" />
      <input matInput type="email" formControlName="email" updateOnBlur />
      @if (adminForm.controls['email'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
      @if (adminForm.controls['email'].hasError('email') && !adminForm.controls['email'].hasError('required')) {
        <mat-error> {{ 'email-validation' | translate }} </mat-error>
      }
      @if (adminForm.controls['email'].hasError('unique')) {
        <mat-error> {{ 'isUnique' | translate }} </mat-error>
      }
    </mat-form-field>
    <mat-form-field>
      <mat-label translate="phone" />
      <input matInput type="text" formControlName="phone" updateOnBlur />
      @if (adminForm.controls['phone'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
      @if (adminForm.controls['phone'].hasError('phone')) {
        <mat-error> {{ 'phone-validation' | translate }} </mat-error>
      }
    </mat-form-field>
    <mat-form-field>
      <mat-label translate="language" />
      <mat-select formControlName="language">
        @for (language of availableLanguages; track language) {
          <mat-option [value]="language.code">
            {{ language.label }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label translate="PPF-credit" />
      <input matInput type="text" formControlName="allowedPPF" />
    </mat-form-field>
    <mat-form-field>
      <mat-label translate="WINDOW-credit" />
      <input matInput type="text" formControlName="allowedWindows" />
    </mat-form-field>
    <div>
      <div>
        <a [routerLink]="['/', { outlets: { dialog: ['change-password'] } }]"> {{ 'change-password' | translate }}</a>
      </div>
      <div class="buttons">
        @if (loading) {
          <mat-spinner></mat-spinner>
        }
        <button color="warn" mat-raised-button type="submit" [disabled]="adminForm.invalid || !adminForm.dirty || loading">
          {{ 'save' | translate }}
        </button>
        <button color="accent" (click)="cancelUpdate()" mat-raised-button type="button">{{ 'cancel' | translate }}</button>
      </div>
    </div>
  </form>
</div>
