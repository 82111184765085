import { of as observableOf, throwError as observableThrowError } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { AuthService, HandleErrorsService, PrintingService } from '../app-services';

@Injectable()
export class HasAccessToPrintingsGuard {
  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected printingService: PrintingService,
    protected handleErrors: HandleErrorsService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const credentials = JSON.parse(sessionStorage.getItem('credentials'));

    if (credentials.scope === 'superAdmin') {
      return observableOf(true);
    } else if (credentials.scope === 'admin') {
      return this.printingService.getPrintingList({ ids: [route.params.id] }).pipe(
        map(adminPrintings => {
          // tslint:disable-next-line:no-unused-expression
          return !!adminPrintings.data.listing.find(printing => printing.id === +route.params['id']);
        }),
        catchError(err => {
          this.handleErrors.handleErrors(err);

          return observableThrowError(err);
        }),
      );
    }
  }
}
