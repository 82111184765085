<form [formGroup]="modelForm">
  <mat-form-field>
    <mat-label translate="serie" />
    <mat-select formControlName="serie">
      @for (serie of serieList | orderBy: 'Brand.name'; track serie) {
        <mat-option [value]="serie.id"> {{ serie.brand.name }}/{{ serie.name }} </mat-option>
      }
    </mat-select>
    @if (modelForm.controls['serie'].hasError('required') && modelForm.controls['serie'].touched) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="model-name" />
    <input matInput type="text" formControlName="name" updateOnBlur />
    @if (modelForm.controls['name'].hasError('required')) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="description" />
    <textarea class="text-area" matInput type="text" formControlName="description" updateOnBlur> </textarea>
    @if (modelForm.controls['description'].hasError('required')) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
  </mat-form-field>

  <input type="file" #picture hidden (change)="addPicture($event)" />
  <button mat-raised-button color="accent" (click)="picture.click()">{{ 'add-image' | translate }}</button>
</form>
<div class="picture">
  @if (showPicture) {
    <img [src]="apiUrl + model.picture" />
  }
</div>
@if (file) {
  <cropper [file]="file" [cropWidth]="cropWidth" [cropHeight]="cropHeight" (croppImage)="handleCroppedImage($event)"></cropper>
}
