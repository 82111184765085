import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HandleErrorsService, MagicTranslateService } from 'app/app-services';
import { EntityList, Messages } from 'app/models/entity-list.model';
import { Plotter } from 'app/models/plotter.model';

@Component({
  selector: 'plotter-log-list',
  templateUrl: './plotter-log-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlotterLogListComponent implements OnChanges, OnInit {
  @Input() plotterList$: EntityList<Plotter>;
  @Input() offset: number;
  @Output() submitFilterOptions = new EventEmitter();
  @Output() plotterDelete = new EventEmitter<number>();
  queryParams: Params;
  messages$: Messages;
  constructor(
    private readonly route: ActivatedRoute,
    protected dialog: MatDialog,
    protected router: Router,
    protected readonly handleErrors: HandleErrorsService,
    private readonly magicTranslateService: MagicTranslateService,
  ) {}

  ngOnChanges() {
    this.queryParams = this.route.snapshot.queryParams;
  }
  ngOnInit() {
    this.messages$ = this.magicTranslateService.getMessage();
  }

  handlePages(event: { count: number; pageSize: number; limit: number; offset: number }) {
    this.submitFilterOptions.emit(event);
  }

  async onDelete(plotterId: number) {
    this.plotterDelete.emit(plotterId);
  }
}
