import { HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Brand } from 'app/models/brand.model';
import { ReplaySubject, Subject } from 'rxjs';
import { AuxiliaryRouteClass } from '../app-interfaces/close-dialog.interface';
import { HandleErrorsService, ModelService } from '../app-services';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'edit-brand-dialog',
  templateUrl: './edit-brand-dialog.component.html',
  styleUrls: ['./edit-brand-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditBrandDialogComponent extends AuxiliaryRouteClass implements OnDestroy, OnInit {
  progress = new ReplaySubject<number>(1);

  newBrand: Brand;
  queryParams: any;

  constructor(
    protected modelService: ModelService,
    public router: Router,
    protected route: ActivatedRoute,
    protected snackBar: MatSnackBar,
    protected dialogRef: MatDialogRef<EditBrandDialogComponent>,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public brand: Brand,
  ) {
    super(router);
    this.unsubscribeSubject = new Subject<void>();
  }
  private readonly unsubscribeSubject: Subject<void>;

  ngOnInit(): void {
    this.queryParams = this.route.snapshot.queryParams;
    this.newBrand = {
      name: this.brand.name,
      logo: this.brand.logo,
    };
  }

  getBrandInfo(brandInfo: { name: string; logo: string }) {
    this.newBrand.name = brandInfo.name;
    this.newBrand.logo = brandInfo.logo;
  }

  editBrand() {
    const data =
      typeof this.newBrand.logo !== 'string'
        ? { name: this.newBrand.name.trim(), file: this.newBrand.logo }
        : { name: this.newBrand.name.trim() };
    this.modelService
      .editBrand(this.brand.id, data)
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe({
        next: event => {
          switch (event.type) {
            case HttpEventType.Sent:
              this.progress.next(0);
              break;

            case HttpEventType.UploadProgress:
              if (event.total) {
                this.progress.next(Math.round((event.loaded / event.total) * 100));
              }
              break;

            case HttpEventType.Response:
              this.progress.next(100);
              this.leaveDialog(this.queryParams);
              this.translateService.get('brand-update-success').subscribe(message => {
                this.snackBar.open(message, 'OK', { duration: 7000 });
              });
              this.modelService.updateBrand.next({ operation: 'editBrand', value: [event.body.data] });
              break;
          }
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }

  cancel() {
    this.leaveDialog(this.queryParams);
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
