import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { AdminService, HandleErrorsService, UsersService } from '../app-services';
import { ConfirmDeletionDialogComponent } from '../confirm-deletion-dialog/confirm-deletion-dialog.component';
import { Admin } from '../models/admin.model';
import { User } from '../models/user.model';
import { SearchEvent } from 'app/models/search-event.model';

@Component({
  selector: 'global-users-management',
  templateUrl: './global-users-management.component.html',
  styleUrls: ['./global-users-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalUsersManagementComponent implements OnInit, OnDestroy {
  users = new ReplaySubject<{ listing: User[]; total: number }>(1);
  admins = new ReplaySubject<Admin[]>(1);
  usersCompleteList = new ReplaySubject<User[]>(1);
  offset$ = of(0);
  searchedUsers$: Observable<User[]>;
  searchedAdmins$: Observable<Admin[]>;

  private readonly unsubscribeSubject: Subject<void>;
  constructor(
    protected usersService: UsersService,
    protected adminService: AdminService,
    protected dialog: MatDialog,
    protected router: Router,
    protected route: ActivatedRoute,
    protected handleErrors: HandleErrorsService,
  ) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(
        switchMap(([params, queryParams]) => {
          const query = {};
          if (queryParams['userIds']) {
            query['userIds'] = queryParams['userIds'].split(',');

            this.searchedUsers$ = this.usersService.getUserList({ userIds: query['userIds'] }).pipe(
              map(result => result.data.listing),
              catchError(error => {
                this.handleErrors.handleErrors(error);

                return EMPTY;
              }),
            );
          }

          if (queryParams['adminIds']) {
            query['adminIds'] = queryParams['adminIds'].split(',');

            this.searchedAdmins$ = this.adminService.getAdminList({ ids: query['adminIds'] }).pipe(
              map(result => result.data.listing),
              catchError(error => {
                this.handleErrors.handleErrors(error);

                return EMPTY;
              }),
            );
          }
          if (params['page']) {
            query['offset'] = (+params['page'] - 1) * 10;
            this.offset$ = of(+params['page'] - 1);
          }

          return this.usersService.getUserList(query);
        }),
        takeUntil(this.unsubscribeSubject),
      )
      .subscribe({
        next: res => {
          this.users.next(res.data);
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }

  getFilterdUsersOrAdmins(result) {
    return result.data.listing;
  }

  openConfirmationDialog(user: User) {
    const config: MatDialogConfig = {
      data: { id: user.id, type: 'user' },
      width: '50%',
      disableClose: true,
    };
    this.dialog.open(ConfirmDeletionDialogComponent, config);
  }

  filterUsers(query: { adminIds: string; userIds: string }) {
    this.router.navigate(['/user-management'], { queryParams: query });
  }

  pageNavigation(event: SearchEvent) {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate(['/user-management', event.offset + 1], { queryParams });
  }

  fetchAdminsOrUsers(event: { type: string; like: string; exclude: number[] }) {
    const query = {};
    Object.keys(event)
      .filter(f => f !== 'type' && event[f])
      .map(m => (query[m] = event[m]));
    switch (event.type) {
      case 'users':
        this.usersService
          .getUserList(query)
          .pipe(distinctUntilChanged())
          .subscribe({
            next: res => {
              this.usersCompleteList.next(res.data.listing);
            },
            error: err => {
              this.handleErrors.handleErrors(err);
            },
          });
        break;
      case 'admins':
        this.adminService
          .getAdminList(query)
          .pipe(distinctUntilChanged())
          .subscribe({
            next: res => {
              this.admins.next(res.data.listing);
            },
            error: err => {
              this.handleErrors.handleErrors(err);
            },
          });
        break;
    }
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
