import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigService } from 'app/services/config/config.service';
import { Subject } from 'rxjs';
import { ExtendedModel } from '../models/model.model';
import { ExtendedSerie } from '../models/serie.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'model-form',
  templateUrl: './model-form.component.html',
  styleUrls: ['./model-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelFormComponent implements OnChanges, OnInit, OnDestroy {
  @Input() serieList: ExtendedSerie[];
  @Input() model: ExtendedModel;

  @Output() modelInfoSubmit = new EventEmitter();
  cropWidth = 360;
  cropHeight = 240;
  showPicture: boolean;

  modelForm: FormGroup;
  file: File;
  picture: string = null;

  editMode = false;

  apiUrl: string;
  private readonly unsubscribeSubject: Subject<void>;

  constructor(private readonly configService: ConfigService) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.buildForm();

    if (changes['model'] && changes['model'].currentValue.picture) {
      this.editMode = true;
    }

    this.modelForm.valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe(form => {
      this.modelInfoSubmit.emit({
        form: form,
        picture: this.model.picture ? this.model.picture : this.picture,
      });
    });
    this.showPicture = !this.file && this.model.picture ? true : false;
  }

  ngOnInit() {
    this.showPicture = !this.file && this.model.picture ? true : false;
    this.apiUrl = this.configService.getApiUrl();
  }

  addPicture(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (!files[0] || files[0] === undefined) {
      this.file = null;
      this.picture = null;
      this.modelInfoSubmit.emit({ form: this.modelForm.value, picture: this.editMode ? this.model.picture : null });
      this.showPicture = this.editMode ? true : false;
    } else {
      this.file = files[0];
      this.showPicture = false;
    }
  }

  handleCroppedImage(picture: string) {
    this.picture = picture;
    this.modelInfoSubmit.emit({ form: this.modelForm.value, picture: picture });
  }

  buildForm() {
    this.modelForm = new FormGroup({
      serie: new FormControl(this.model?.serie?.id, Validators.required),
      name: new FormControl(this.model.name, Validators.required),
      description: new FormControl(this.model.description),
    });
  }
}
