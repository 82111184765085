import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ExtendedLogging } from 'app/models/loggin.model';
import { EntityList, Messages } from 'app/models/entity-list.model';
import { ActivatedRoute, Params } from '@angular/router';
import { MagicTranslateService } from 'app/app-services';
import { SearchEvent } from 'app/models/search-event.model';

@Component({
  selector: 'super-admin-credit-log-list',
  templateUrl: './super-admin-credit-log-list.component.html',
})
export class SuperAdminCreditLogListComponent implements OnChanges, OnInit {
  @Input() loggings: EntityList<ExtendedLogging>;
  @Input() offset: number;

  @Output() submitFilterOptions = new EventEmitter();
  queryParams: Params;
  messages$: Messages;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly magicTranslateService: MagicTranslateService,
  ) {}

  ngOnChanges() {
    this.queryParams = this.route.snapshot.queryParams;
  }

  ngOnInit() {
    this.messages$ = this.magicTranslateService.getMessage();
  }

  handlePages(event: SearchEvent) {
    this.submitFilterOptions.emit(event);
  }
}
