<h2>{{ 'plotters' | translate }}</h2>
<plotter-log-filter
  [plotterList]="plottersAutoComplete$ | async"
  (filterPlotters)="filterPlotter($event)"
  (fetchLists)="fetchPlotter($event)"
  (routeNavigate)="navigate()"
></plotter-log-filter>
<plotter-log-list
  [plotterList$]="plotterList | async"
  [offset]="offset$ | async"
  (submitFilterOptions)="pageNavigation($event)"
  (plotterDelete)="openDeletePlotterDialog($event)"
>
</plotter-log-list>
