import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HandleErrorsService {
  constructor(
    private snackbar: MatSnackBar,
    private router: Router,
    protected translateService: TranslateService,
  ) {}

  handleErrors(err: { statusCode: number; data: any }, redirect?: boolean) {
    switch (true) {
      case err.statusCode >= 500 && err.statusCode <= 511:
        this.translateService.get('server-error').subscribe(message => {
          this.snackbar.open(message, 'OK', { duration: 7000 });
        });
        break;
      case err.statusCode === 404 && !redirect:
        this.translateService.get('not-found').subscribe(message => {
          this.snackbar.open(message, 'OK', { duration: 7000 });
        });
        break;
      case err.statusCode === 404 && redirect:
        this.translateService.get('not-found').subscribe(message => {
          this.snackbar.open(message, 'OK', { duration: 7000 });
        });
        this.router.navigate([{ outlets: { dialog: null, primary: ['error-404'] } }]);
        break;
      case err.statusCode === 401:
        this.translateService.get('no-auth').subscribe(message => {
          this.snackbar.open(message, 'OK', { duration: 7000 });
        });
        break;
      case err.statusCode === 403:
        this.translateService.get('no-acces').subscribe(message => {
          this.snackbar.open(message, 'OK', { duration: 7000 });
        });
        break;
      case err.statusCode === 409:
        this.snackbar.open('Conflit code :' + err.statusCode + '. message: ' + JSON.parse(err.data.error).message, 'OK', {
          duration: 7000,
        });
        break;
      default:
        this.translateService.get('client-error').subscribe(message => {
          this.snackbar.open(message + ' ' + err.statusCode, 'OK', { duration: 7000 });
        });
        break;
    }
  }
}
