import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddPaymentDocumentDialogComponent } from 'app/add-payment-document-dialog/add-payment-document-dialog.component';
import { CloseDialogInterface } from 'app/app-interfaces/close-dialog.interface';

@Component({
  selector: 'add-payment-document',
  templateUrl: './add-payment-document.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPaymentDocumentComponent implements OnInit, CloseDialogInterface {
  dialogRef: any;

  constructor(protected dialog: MatDialog) {}

  ngOnInit() {
    const config: MatDialogConfig = { width: '60%', disableClose: true };
    this.dialogRef = this.dialog.open(AddPaymentDocumentDialogComponent, config);
  }

  closeDialog() {
    this.dialogRef.close();

    return true;
  }
}
