<h2>{{ 'cut-log' | translate }}</h2>
<super-admin-printing-filter
  class="toolbar-body"
  [adminList]="adminList | async"
  [models]="modelList | async"
  [searchedDates]="searchedDates"
  [searchedModels]="searchedModels$ | async"
  [searchedAdmins]="searchedAdmins$ | async"
  (filterQuery)="filterPrintings($event)"
  (queryModels)="fetchModelList($event)"
  (queryAdmins)="fetchAdminList($event)"
>
</super-admin-printing-filter>
<printing-list [printings]="printings | async" [offset]="offset$ | async" (tablePagesNavigation)="pageNavigation($event)"></printing-list>
