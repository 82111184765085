import { Observable, Subject } from 'rxjs';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { omit } from 'lodash';
import { map } from 'rxjs/operators';
import { ModelAdmin } from '../models/model-admin.model';
import { AuthService } from './auth.service';

@Injectable()
export class ModelAdminService {
  update = new Subject<{ operation: string; value: ModelAdmin[] }>();

  constructor(
    private _http: HttpClient,
    protected authService: AuthService,
  ) {}

  getModelAdminList(query: ModelAdminQuery = {}): Observable<{ statusCode: number; data: Data }> {
    const route = { ids: 'ids', offset: 'offset', limit: 'limit' };
    const questionMark = Object.keys(query).length > 0 ? '?' : '';
    const url =
      '/api/model-admin/list' +
      questionMark +
      Object.keys(query)
        .map(k =>
          Array.isArray(query[k]) ? query[k].map((value, index) => `${route[k]}[${index}]=${value}`).join('&') : `${route[k]}=${query[k]}`,
        )
        .join('&');

    return this._http.get(url, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: Data; statusCode: number }>) => {
        return res.body;
      }),
    );
  }

  getModelAdmin(modelAdminId: number): Observable<{ statusCode: number; data: ModelAdmin }> {
    return this._http.get('/api/model-admin/' + modelAdminId, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: ModelAdmin; statusCode: number }>) => {
        return res.body;
      }),
    );
  }

  addModelAdmin(newModelAdmin: ModelAdmin): Observable<{ statusCode: number; data: ModelAdmin }> {
    return this._http.post('/api/model-admin', newModelAdmin, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: ModelAdmin; statusCode: number }>) => {
        this.update.next({ operation: 'addModelAdmin', value: [res.body.data] });

        return res.body;
      }),
    );
  }

  updateModelAdmin(modelAdminData: ModelAdmin): Observable<{ statusCode: number; data: ModelAdmin }> {
    const modelAdmin = this.authService.isSuperAdmin() ? modelAdminData : omit(modelAdminData, ['fullName']);

    return this._http.put('/api/model-admin', modelAdmin, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: ModelAdmin; statusCode: number }>) => {
        this.update.next({ operation: 'updateModelAdmin', value: [res.body.data] });

        return res.body;
      }),
    );
  }

  deleteModelAdmin(modelAdminId: number): Observable<{ statusCode: number }> {
    return this._http.delete('/api/model-admin/' + modelAdminId, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: ModelAdmin; statusCode: number }>) => {
        this.update.next({ operation: 'deleteModelAdmin', value: [] });

        return res.body;
      }),
    );
  }

  setPassword(passwords: { currentPassword: string; newPassword: string }): Observable<{ statusCode: number }> {
    return this._http.put('/api/model-admin/set-password', passwords, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: ModelAdmin; statusCode: number }>) => {
        return res.body;
      }),
    );
  }
}
export type Data = {
  listing: ModelAdmin[];
  total: number;
};

export type ModelAdminQuery = {
  ids?: number[];
  limit?: number;
  offset?: number;
};
