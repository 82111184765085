import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { fieldsMatch } from '../validators/fields-match.validator';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordFormComponent implements OnInit, OnDestroy {
  @Output() formSubmit = new EventEmitter<any>();

  passwordForm: FormGroup;

  private readonly unsubscribeSubject: Subject<void>;

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
  constructor() {
    this.unsubscribeSubject = new Subject<void>();
  }
  ngOnInit() {
    this.passwordForm = new FormGroup(
      {
        currentPassword: new FormControl(null, Validators.required),
        newPassword: new FormControl(null, Validators.required),
        confirmPassword: new FormControl(null, Validators.required),
      },
      fieldsMatch('newPassword', 'confirmPassword'),
    );

    this.passwordForm.valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe(() => {
      this.formSubmit.emit({
        currentPassword: this.passwordForm.controls['currentPassword'].value,
        newPassword: this.passwordForm.controls['newPassword'].value,
      });
    });
  }
}
