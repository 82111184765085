<div class="contents">
  @if (serieList) {
    <div>
      <ngx-datatable
        [rows]="serieList.listing"
        [footerHeight]="30"
        [columnMode]="'force'"
        [rowHeight]="'auto'"
        (page)="handlePages($event)"
        [limit]="10"
        [externalPaging]="true"
        [count]="serieList.total"
        [offset]="offset"
        [messages]="messages$ | async"
      >
        <ngx-datatable-column [name]="'brand' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/management/brand-management']" [queryParams]="{ brandName: row?.brand?.name, exactSearch: true }">
              {{ row?.brand?.name }}
            </a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'serie' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.name }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'models' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              [routerLink]="['/management/model-management']"
              [queryParams]="{ brandName: row?.brand?.name, serieName: row?.name, exactSearch: true, showParents: true }"
            >
              {{ 'show-models' | translate }}
            </a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [maxWidth]="60" [name]="'edit' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button mat-icon-button color="accent" (click)="navigate(row.id)">
              <mat-icon svgIcon="ic_edit_24px"></mat-icon>
            </button>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [cellClass]="'item-cell'"
          [maxWidth]="100"
          [name]="'delete' | translate"
          [sortable]="false"
          [draggable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button mat-icon-button (click)="deleteSerie(row)" color="warn">
              <mat-icon svgIcon="ic_delete_24px"></mat-icon>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  } @else {
    <div>
      <mat-spinner></mat-spinner>
    </div>
  }
</div>
