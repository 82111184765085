<form [formGroup]="form">
  <div class="admin-form-row-1">
    <mat-form-field>
      <mat-label translate="full-name" />
      <input matInput type="text" formControlName="fullName" updateOnBlur />
      @if (form.controls['fullName'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
    </mat-form-field>

    <div class="is-active-toggle">
      <mat-slide-toggle formControlName="isActive"></mat-slide-toggle>
      {{ isActiveMessage | translate }}
    </div>
  </div>
  <address-form [canEdit]="true" (addressValue)="updateAddressValue($event)"></address-form>
  <div class="admin-form-row-3">
    <mat-form-field>
      <mat-label translate="email" />
      <input matInput type="email" formControlName="email" updateOnBlur />
      @if (form.controls['email'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
      @if (!form.controls['email'].hasError('required') && form.controls['email'].hasError('email')) {
        <mat-error> {{ 'email-validation' | translate }} </mat-error>
      }
      @if (form.controls['email'].hasError('unique')) {
        <mat-error> {{ 'used' | translate }} </mat-error>
      }
    </mat-form-field>
    <mat-form-field>
      <mat-label translate="phone" />
      <input matInput type="text" formControlName="phone" updateOnBlur />
      @if (form.controls['phone'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
      @if (form.controls['phone'].hasError('phone')) {
        <mat-error> {{ 'phone-validation' | translate }} </mat-error>
      }
    </mat-form-field>
  </div>
  <div class="admin-form-row-3">
    <mat-form-field>
      <mat-label translate="language" />
      <mat-select formControlName="language" updateOnBlur>
        @for (language of availableLanguages; track language) {
          <mat-option [value]="language.code">
            {{ language.label }}
          </mat-option>
        }
      </mat-select>
      @if (form.controls['language'].hasError('required') && form.controls['language'].touched) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
    </mat-form-field>
  </div>
</form>
