<h1 mat-dialog-title>{{ 'select-admin' | translate }}</h1>
<mat-dialog-content>
  <transfer-users-form #transferForm [adminList]="otherAdmins" (selectedAdmin)="selectAdmin($event)"></transfer-users-form>
</mat-dialog-content>
<mat-dialog-actions>
  @if (loading | async) {
    <mat-spinner></mat-spinner>
  }
  <button mat-raised-button [disabled]="transferForm.transferUsersForm.invalid || (loading | async)" color="warn" (click)="deleteAdmin()">
    {{ 'confirm' | translate }}
  </button>
  <button mat-raised-button color="accent" (click)="cancel()">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
