<h1 mat-dialog-title>
  <span>{{ 'new-brand' | translate }}</span>
</h1>
<mat-dialog-content>
  <brand-form [brand]="newBrand" #addForm (brandInfoSubmit)="getBrandInfo($event)"></brand-form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-raised-button
    color="accent"
    [disabled]="addForm.brandForm.invalid || !newBrand.logo || (progress | async)"
    (click)="addBrand()"
  >
    {{ (progress | async) ? (progress | async) + '%' : ('add' | translate) }}
  </button>
  <button mat-raised-button color="warn" (click)="cancel()">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
