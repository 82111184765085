<h2>{{ 'profile' | translate }}</h2>
<div class="editable-user-profile-contents">
  <user-form #userForm [user]="user" [canEdit]="canEdit" (userActive)="handleUserInfo($event)"></user-form>
  <div class="buttons">
    @if (!canEdit) {
      <button (click)="allowEdit()" color="warn" mat-raised-button type="submit">{{ 'edit' | translate }}</button>
    } @else {
      <button color="accent" (click)="emitUserUpdate()" mat-raised-button type="submit" [disabled]="userForm.userForm.invalid || !userInfo">
        {{ 'save' | translate }}
      </button>
      <button color="warn" (click)="cancelUpdate()" mat-raised-button type="submit">{{ 'cancel' | translate }}</button>
    }
  </div>
</div>
