<h2 mat-dialog-title>{{ 'edit-serie' | translate }}: {{ serie.name }}</h2>
<mat-dialog-content>
  <serie-form #serieForm [brandList]="brandList | async" [serie]="serie" (formSubmit)="handleFormInfo($event)"></serie-form>
</mat-dialog-content>

<mat-dialog-actions>
  @if (loading | async) {
    <mat-spinner></mat-spinner>
  }
  <button
    mat-raised-button
    color="accent"
    (click)="updateSerie()"
    [disabled]="serieForm.serieForm.invalid || !serieForm.serieForm.dirty || (loading | async)"
  >
    {{ 'edit' | translate }}
  </button>
  <button mat-raised-button color="warn" (click)="leaveDialog(queryParams)">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
