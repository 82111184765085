<h2>{{ 'profile' | translate }}</h2>
<div class="admin-profile-contents">
  <admin-detail-profile-form
    #profilForm
    (adminIsActive)="handleAdminInfo($event)"
    [admin]="admin"
    [canEdit]="canEdit | async"
  ></admin-detail-profile-form>
  <div class="buttons">
    @if (loading | async) {
      <mat-spinner></mat-spinner>
    }
    @if (!(canEdit | async)) {
      <button (click)="allowEdit()" color="warn" mat-raised-button type="submit">{{ 'edit' | translate }}</button>
    } @else {
      <button
        color="accent"
        (click)="updateAdmin()"
        mat-raised-button
        type="submit"
        [disabled]="profilForm.adminForm.status !== 'VALID' || !adminInfo || (loading | async)"
      >
        {{ 'save' | translate }}
      </button>
      <button color="warn" (click)="cancelUpdate()" mat-raised-button type="submit">{{ 'cancel' | translate }}</button>
    }
  </div>
</div>
