import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { fieldsMatch } from '../validators/fields-match.validator';

@Component({
  selector: 'reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordFormComponent implements OnChanges, OnInit {
  @Input() loading: boolean;
  @Output() passwordReset = new EventEmitter();
  resetPasswordForm: FormGroup;
  disabled = false;

  ngOnInit() {
    this.buildForm();
  }

  ngOnChanges() {
    this.disabled = this.loading === false ? true : false;
  }

  buildForm() {
    this.resetPasswordForm = new FormGroup(
      {
        newPassword: new FormControl(null, Validators.required),
        confirmPassword: new FormControl(null, Validators.required),
      },
      fieldsMatch('newPassword', 'confirmPassword'),
    );
  }

  resetPassword() {
    this.passwordReset.emit(this.resetPasswordForm.controls['newPassword'].value);
  }
}
