import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Banner } from 'app/models/banner.model';
import { ConfigService } from 'app/services/config/config.service';
import { debounceTime, distinctUntilChanged, map, Subject, takeUntil } from 'rxjs';
import { omitBy, isNil } from 'lodash';

@Component({
  selector: 'banner-form',
  templateUrl: './banner-form.component.html',
  styleUrls: ['./banner-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() banner: Banner;
  @Input() resetForm: boolean;
  @Output() bannerInfoSubmit = new EventEmitter();
  cropWidth = 2100;
  cropHeight = 118;

  showPicture: boolean;
  bannerForm: FormGroup;
  file: File;
  private readonly unsubscribeSubject: Subject<void>;

  apiUrl: string;
  constructor(
    private readonly configService: ConfigService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.resetForm?.firstChange && changes.resetForm.currentValue) {
      this.file = undefined;
      this.showPicture = true;
    }
  }

  ngOnInit() {
    this.apiUrl = this.configService.getApiBanner();
    this.bannerForm = this.formBuilder.group({
      id: [this.banner?.id],
      picture: [this.banner?.picture, Validators.required],
    });

    this.bannerForm.valueChanges
      .pipe(
        takeUntil(this.unsubscribeSubject),
        debounceTime(300),
        distinctUntilChanged(),
        map(value => omitBy(value, isNil)),
      )
      .subscribe({
        next: value => {
          this.bannerInfoSubmit.emit(value);
        },
      });

    this.showPicture = !this.file && this.banner?.picture ? true : false;
  }

  addBanner(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    this.file = files[0];
    this.showPicture = false;
    this.bannerForm.get('picture').setValue(files[0]);
  }

  handleCroppedImage(picture: File) {
    this.bannerForm.get('picture').setValue(picture);
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
