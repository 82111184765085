import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'brand-serie-model-management',
  templateUrl: './brand-serie-model-management.component.html',
  styleUrls: ['./brand-serie-model-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandSerieModelManagementComponent {
  links = [
    {
      url: '/management/brand-management',
      label: 'brand-management',
    },
    {
      url: '/management/serie-management',
      label: 'serie-management',
    },
    {
      url: '/management/model-management',
      label: 'models-management',
    },
  ];
}
