import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CloseDialogInterface } from '../app-interfaces/close-dialog.interface';

@Injectable()
export class DialogCloseGuard {
  constructor(protected dialog: MatDialog) {}

  canDeactivate(component: CloseDialogInterface) {
    return component.closeDialog();
  }
}
