<mat-toolbar>
  <form [formGroup]="filterForm" novalidate>
    <mat-form-field class="form-field" [subscriptSizing]="'dynamic'">
      <mat-chip-grid class="chips-list" formControlName="admins" #adminsList aria-label="Admins selection">
        @for (admin of filterForm.get('admins').value; track admin) {
          <mat-chip-row class="chip" [value]="admin" (removed)="removeAdmin(admin)">
            {{ admin.fullName }}
            <button matChipRemove>
              <mat-icon svgIcon="ic_clear_24px"></mat-icon>
            </button>
          </mat-chip-row>
        }
        <input
          matInput
          #adminInput
          formControlName="adminSearch"
          [placeholder]="'admins' | translate"
          [matAutocomplete]="adminAuto"
          [matChipInputFor]="adminsList"
        />
      </mat-chip-grid>
      <mat-autocomplete #adminAuto="matAutocomplete" (optionSelected)="selectedAdmin($event)">
        @for (admin of admins; track admin) {
          <mat-option [value]="admin">
            {{ admin.fullName }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="form-field" [subscriptSizing]="'dynamic'">
      <mat-chip-grid class="chips-list" formControlName="users" #usersList aria-label="Users selection">
        @for (user of filterForm.get('users').value; track user) {
          <mat-chip-row class="chip" [value]="user" (removed)="removeUser(user)">
            {{ user.fullName }}
            <button matChipRemove>
              <mat-icon svgIcon="ic_clear_24px"></mat-icon>
            </button>
          </mat-chip-row>
        }
        <input
          matInput
          #userInput
          formControlName="userSearch"
          [placeholder]="'users' | translate"
          [matAutocomplete]="auto"
          [matChipInputFor]="usersList"
        />
      </mat-chip-grid>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedUser($event)">
        @for (user of users; track user) {
          <mat-option [value]="user">
            {{ user.fullName }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    <button mat-mini-fab color="accent" (click)="search(filterForm.value)">
      <mat-icon svgIcon="ic_search_24px"></mat-icon>
    </button>
  </form>
</mat-toolbar>
