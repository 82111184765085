import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Address } from 'app/models/address.model';
import { City, Country, ICity, ICountry, IState, State } from 'country-state-city';
import { Observable, Subject, filter, map, startWith, takeUntil } from 'rxjs';

@Component({
  selector: 'address-form',
  templateUrl: './address-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFormComponent implements OnChanges, OnInit, OnDestroy {
  @Input() canEdit: boolean;
  @Input() address: Address;
  @Output() addressValue = new EventEmitter<Address>();
  countries = Country.getAllCountries();
  states: IState[];
  cities: ICity[];
  filteredCountry: Observable<ICountry[]>;
  filteredState: Observable<IState[]>;
  filteredCity: Observable<ICity[]>;

  addressForm = new FormGroup({
    address: new FormControl('', Validators.required),
    country: new FormControl(null, Validators.required),
    state: new FormControl(null, Validators.required),
    city: new FormControl(null),
  });

  private unsubscribeSubject = new Subject<void>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.canEdit) {
      changes.canEdit.currentValue ? this.addressForm.enable() : this.addressForm.disable();
    }
  }

  ngOnInit() {
    if (this.address?.address) {
      this.initAdress();
    }

    this.filteredCountry = this.addressForm.get('country').valueChanges.pipe(
      startWith(''),
      map(value => this.countries.filter(country => country.name.toLowerCase().includes(value?.toString().toLowerCase()))),
    );

    this.filteredState = this.addressForm.get('state').valueChanges.pipe(
      startWith(''),
      map(value => this.states?.filter(state => state.name.toLowerCase().includes(value?.toString().toLowerCase()))),
    );

    this.filteredCity = this.addressForm.get('city').valueChanges.pipe(
      startWith(''),
      map(value => this.cities?.filter(city => city.name.toLowerCase().includes(value?.toString().toLowerCase()))),
    );

    this.addressForm.valueChanges
      .pipe(
        takeUntil(this.unsubscribeSubject),
        filter(() => this.addressForm.valid),
      )
      .subscribe({
        next: value => {
          this.addressValue.emit({
            address: `${value.address}, ${value.city?.name ?? ''}, ${value.state?.name}`,
            country: value.country?.isoCode,
          });
        },
      });
  }

  private initAdress() {
    const splittedAddress = this.address.address.split(', ');
    const [city, state] = splittedAddress.slice(-2);
    const address = splittedAddress.slice(0, -2).join(', ');
    this.addressForm.get('address').setValue(address);
    const countryForm = this.addressForm.get('country');
    const stateForm = this.addressForm.get('state');

    countryForm.setValue(this.countries.find(country => country.isoCode === this.address?.country));

    this.states = State.getStatesOfCountry(countryForm.value?.isoCode);
    stateForm.setValue(this.states.find(iState => iState.name === state));

    this.cities = City.getCitiesOfState(countryForm.value?.isoCode, stateForm.value?.isoCode);
    this.addressForm.get('city').setValue(this.cities.find(iCity => iCity.name === city));
  }

  countrySelected(selectedCountry: ICountry) {
    this.states = State.getStatesOfCountry(selectedCountry.isoCode);
    this.addressForm.get('state').reset();
    this.addressForm.get('city').reset();
  }
  stateSelected(selectedState: IState) {
    this.cities = City.getCitiesOfState(this.addressForm.get('country').value.isoCode, selectedState.isoCode);
    this.addressForm.get('city').reset('');
  }

  displayWithName(value: ICountry | ICity | IState): string {
    return value && value.name ? value.name : '';
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
