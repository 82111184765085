<h2 mat-dialog-title>{{ 'new-payment-document' | translate }}</h2>
<mat-dialog-content>
  <payment-document-form
    (paymentDocumentValue)="getPaymentDocumentInfo($event)"
    #adminForm
    [allAdmin]="adminList$ | async"
  ></payment-document-form>
</mat-dialog-content>

<mat-dialog-actions>
  @if (loading | async) {
    <mat-spinner></mat-spinner>
  }
  <button
    mat-raised-button
    color="accent"
    (click)="addPaymentDocument()"
    [disabled]="adminForm.paymentDocumentForm.invalid || (loading | async)"
  >
    {{ 'add' | translate }}
  </button>
  <button mat-raised-button color="warn" (click)="leaveDialog(queryParams)">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
