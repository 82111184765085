import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CloseDialogInterface } from '../app-interfaces/close-dialog.interface';

import { AddModelDialogComponent } from '../add-model-dialog/add-model-dialog.component';

@Component({
  selector: 'add-model',
  templateUrl: './add-model.component.html',
  styleUrls: ['./add-model.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddModelComponent implements OnInit, CloseDialogInterface {
  dialogRef: any;

  constructor(protected dialog: MatDialog) {}

  ngOnInit() {
    const config: MatDialogConfig = { width: '70%', height: '73%', disableClose: true };
    this.dialogRef = this.dialog.open(AddModelDialogComponent, config);
  }

  closeDialog() {
    this.dialogRef.close();

    return true;
  }
}
