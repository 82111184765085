import { HttpClient, HttpResponse } from '@angular/common/http';
import { ExtendedLogging, LoggingQuery } from '../models/loggin.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class LoggingService {
  constructor(private _http: HttpClient) {}

  getLoggingList(query: LoggingQuery = {}): Observable<Response> {
    const route = {
      ids: 'ids',
      userIds: 'user-ids',
      adminIds: 'admin-ids',
      startDate: 'start-date',
      endDate: 'end-date',
      offset: 'offset',
      limit: 'limit',
      exclude: 'exclude',
      next: 'next',
    };
    const questionMark = Object.keys(query).length > 0 ? '?' : '';
    const url =
      '/api/admin-logs' +
      questionMark +
      Object.keys(query)
        .map(k =>
          Array.isArray(query[k]) ? query[k].map((value, index) => `${route[k]}[${index}]=${value}`).join('&') : `${route[k]}=${query[k]}`,
        )
        .join('&');

    return this._http.get(url, { observe: 'response' }).pipe(
      map((res: HttpResponse<Response>) => {
        return res.body;
      }),
    );
  }

  getAdminLoggingList(query: LoggingQuery = {}): Observable<Response> {
    const route = {
      ids: 'ids',
      userIds: 'user-ids',
      adminIds: 'admin-ids',
      startDate: 'start-date',
      endDate: 'end-date',
      offset: 'offset',
      limit: 'limit',
      exclude: 'exclude',
      next: 'next',
      type: 'type',
    };
    const questionMark = Object.keys(query).length > 0 ? '?' : '';
    const url =
      '/api/user-logs' +
      questionMark +
      Object.keys(query)
        .map(k =>
          Array.isArray(query[k]) ? query[k].map((value, index) => `${route[k]}[${index}]=${value}`).join('&') : `${route[k]}=${query[k]}`,
        )
        .join('&');

    return this._http.get(url, { observe: 'response' }).pipe(
      map((res: HttpResponse<Response>) => {
        return res.body;
      }),
    );
  }
}

export type Response = {
  statusCode: number;
  data: {
    listing: ExtendedLogging[];
    total: number;
  };
};
