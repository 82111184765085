import { HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { AuxiliaryRouteClass } from '../app-interfaces/close-dialog.interface';
import { HandleErrorsService, ModelService } from '../app-services';
import { TranslateService } from '@ngx-translate/core';
import { Model } from '../models/model.model';
import { Serie } from '../models/serie.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'edit-model-dialog',
  templateUrl: './edit-model-dialog.component.html',
  styleUrls: ['./edit-model-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditModelDialogComponent extends AuxiliaryRouteClass implements OnInit, OnDestroy {
  progress = new ReplaySubject<number>(1);

  private readonly unsubscribeSubject: Subject<void>;

  serieList = new ReplaySubject<Serie[]>(1);
  formInfo: Model;
  queryParams: any;

  constructor(
    public router: Router,
    protected route: ActivatedRoute,
    protected modelService: ModelService,
    protected snackBar: MatSnackBar,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public model: Model,
  ) {
    super(router);
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;

    this.formInfo = {
      name: this.model.name,
      serieId: this.model.serieId,
      description: this.model.description,
      picture: this.model.picture,
    };

    this.modelService
      .getSerieList({ showParents: true })
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe({
        next: res => {
          this.serieList.next(res.data.listing);
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  handleModelInfo(modelInfo: { form: { description: string; name: string; serie: number }; picture: string }) {
    this.formInfo.name = modelInfo.form.name;
    this.formInfo.serieId = modelInfo.form.serie;
    this.formInfo.description = modelInfo.form.description;
    this.formInfo.picture = modelInfo.picture;
  }

  updateModel() {
    const data =
      typeof this.formInfo.picture !== 'string'
        ? {
            name: this.formInfo.name.trim(),
            serieId: this.formInfo.serieId,
            description: this.formInfo.description,
            file: this.formInfo.picture,
          }
        : {
            name: this.formInfo.name.trim(),
            serieId: this.formInfo.serieId,
            description: this.formInfo.description,
          };

    this.modelService
      .updateModel(this.model.id, data)
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe({
        next: event => {
          switch (event.type) {
            case HttpEventType.Sent:
              this.progress.next(0);
              break;

            case HttpEventType.UploadProgress:
              if (event.total) {
                this.progress.next(Math.round((event.loaded / event.total) * 100));
              }
              break;

            case HttpEventType.Response:
              this.progress.next(100);
              this.translateService.get('model-update-success').subscribe(message => {
                this.snackBar.open(message, 'OK', { duration: 7000 });
              });
              this.leaveDialog(this.queryParams);
              this.modelService.updateModels.next({ operation: 'editModel', value: [event.body.data] });
              break;
          }
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }
}
