import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Serie } from 'app/models/serie.model';
import { ReplaySubject } from 'rxjs';
import { HandleErrorsService, ModelService } from '../app-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'delete-brand-dialog',
  templateUrl: './delete-brand-dialog.component.html',
  styleUrls: ['./delete-brand-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteBrandDialogComponent implements OnInit {
  serieList = new ReplaySubject<Serie[]>(1);
  loading = new ReplaySubject<boolean>(1);
  disabled = true;

  constructor(
    protected modelService: ModelService,
    protected snackBar: MatSnackBar,
    protected dialogRef: MatDialogRef<DeleteBrandDialogComponent>,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public brandId: number,
  ) {}

  ngOnInit() {
    this.modelService.getSerieList({ brandIds: [this.brandId] }).subscribe({
      next: response => {
        this.disabled = response.data.listing.length ? true : false;
        this.serieList.next(response.data.listing);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  confirm() {
    this.loading.next(true);
    if (!this.disabled) {
      this.modelService.deleteBrand(this.brandId).subscribe({
        next: () => {
          this.dialogRef.close();
          this.translateService.get('brand-delete-success').subscribe(message => {
            this.snackBar.open(message, 'OK', { duration: 7000 });
          });
        },
        error: err => {
          this.loading.next(false);
          this.handleErrors.handleErrors(err);
        },
      });
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
