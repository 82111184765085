<form [formGroup]="brandForm">
  <mat-form-field>
    <mat-label translate="brand-name" />
    <input matInput type="text" formControlName="name" updateOnBlur />
    @if (brandForm.controls['name'].hasError('required')) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
  </mat-form-field>
  <input type="file" #logo hidden (change)="addLogo($event)" />
  <button mat-raised-button color="accent" (click)="logo.click()">{{ 'logo' | translate }}</button>
</form>

@if (showLogo) {
  <img [src]="apiUrl + brand.logo" />
}
@if (file) {
  <cropper [file]="file" [cropWidth]="cropWidth" [cropHeight]="cropHeight" (croppImage)="handleCroppedImage($event)"></cropper>
}
