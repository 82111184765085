<h2>{{ 'user-management' | translate }}</h2>
<global-users-filter
  [users]="usersCompleteList | async"
  [admins]="admins | async"
  [searchedUsers]="searchedUsers$ | async"
  [searchedAdmins]="searchedAdmins$ | async"
  (filterUsers)="filterUsers($event)"
  (queryAdminsOrUsers)="fetchAdminsOrUsers($event)"
></global-users-filter>
<super-admin-user-list
  [userList]="users | async"
  [offset]="offset$ | async"
  (openConfirmationDialog)="openConfirmationDialog($event)"
  (submitFilterOptions)="pageNavigation($event)"
></super-admin-user-list>
