import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityList } from 'app/models/entity-list.model';
import { ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { HandleErrorsService, ModelService } from '../app-services';
import { DeleteSerieDialogComponent } from '../delete-serie-dialog/delete-serie-dialog.component';
import { Brand } from '../models/brand.model';
import { ExtendedSerie, Serie } from '../models/serie.model';
import { BrandQuery, SerieQuery } from 'app/app-services/model.service';
import { SearchEvent } from 'app/models/search-event.model';

@Component({
  selector: 'serie-management',
  templateUrl: './serie-management.component.html',
  styleUrls: ['./serie-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SerieManagementComponent implements OnInit, OnDestroy {
  serieList = new ReplaySubject<EntityList<ExtendedSerie>>(1);
  serieListComplete = new ReplaySubject<Serie[]>(1);
  brandList = new ReplaySubject<Brand[]>(1);
  searchTerms = { brandName: null, serieName: null };
  offset$ = of(0);

  private readonly unsubscribeSubject: Subject<void>;
  constructor(
    protected modelService: ModelService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected handleErrors: HandleErrorsService,
    protected router: Router,
  ) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams, this.modelService.updateSeries.pipe(startWith(null))])
      .pipe(
        switchMap(([params, queryParams]) => {
          const query = {
            limit: 10,
            offset: 0,
          };

          if (params['page']) {
            query['offset'] = (+params['page'] - 1) * 10;
            this.offset$ = of(+params['page'] - 1);
          }

          if (!Object.keys(queryParams).length) {
            query['showParents'] = true;
          } else {
            if (queryParams['serieName'] && !queryParams['brandName']) {
              query['showParents'] = true;
            }
            if (queryParams['exactSearch']) {
              query['exactSearch'] = queryParams['exactSearch'];
            }
          }

          Object.keys(queryParams).map(k => {
            query[k] = queryParams[k];
            this.searchTerms[k] = queryParams[k];
          });

          return this.modelService.getSerieList(query);
        }),
        takeUntil(this.unsubscribeSubject),
      )
      .subscribe({
        next: res => {
          this.serieList.next(res.data);
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }

  fetchBrands(query: BrandQuery) {
    this.modelService.getBrandList(query).subscribe({
      next: response => {
        this.brandList.next(response.data.listing);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  fetchSeries(query: SerieQuery) {
    this.modelService.getSerieList(query).subscribe({
      next: response => {
        this.serieListComplete.next(response.data.listing);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  openDeleteSerieDialog(serie: Serie) {
    const config: MatDialogConfig = { data: serie, width: '60%', disableClose: true };
    this.dialog.open(DeleteSerieDialogComponent, config);
  }

  filterSeries(queryParams: { brandName: string; serieName: string; showParents: boolean }) {
    this.router.navigate(['/management/serie-management'], { queryParams });
  }

  pageNavigation(event: SearchEvent) {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate(['/management/serie-management', event.offset + 1], { queryParams });
  }

  navigate(serieId: number) {
    const queryParams = this.route.snapshot.queryParams;
    const link = serieId ? ['/', { outlets: { dialog: ['edit-serie', serieId] } }] : ['/', { outlets: { dialog: ['add-serie'] } }];
    this.router.navigate(link, { queryParams });
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
