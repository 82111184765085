<form [formGroup]="modelAdminForm">
  <mat-form-field>
    <mat-label translate="full-name" />
    <input matInput type="text" formControlName="fullName" updateOnBlur />
    @if (modelAdminForm.controls['fullName'].hasError('required')) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
  </mat-form-field>
  <mat-form-field>
    <mat-label translate="email" />
    <input matInput type="text" formControlName="email" placeholder="Adresse électronique" updateOnBlur />
    @if (modelAdminForm.controls['email'].hasError('required')) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
    @if (modelAdminForm.controls['email'].hasError('email') && !modelAdminForm.controls['email'].hasError('required')) {
      <mat-error> {{ 'email-validation' | translate }} </mat-error>
    }
    @if (modelAdminForm.controls['email'].hasError('unique')) {
      <mat-error> {{ 'used' | translate }} </mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="language" />
    <mat-select formControlName="language">
      @for (language of availableLanguages; track language) {
        <mat-option [value]="language.code">
          {{ language.label }}
        </mat-option>
      }
    </mat-select>
    @if (modelAdminForm.controls['language'].hasError('required') && modelAdminForm.controls['language'].touched) {
      <mat-error class="select-error"> {{ 'required' | translate }} </mat-error>
    }
  </mat-form-field>
</form>
