<div class="contents">
  @if (adminList) {
    <div>
      <ngx-datatable
        [rows]="adminList.listing"
        [footerHeight]="30"
        [columnMode]="'force'"
        [rowHeight]="'auto'"
        (page)="handlePages($event)"
        [limit]="10"
        [externalPaging]="true"
        [count]="adminList.total"
        [offset]="offset"
        [messages]="messages$ | async"
      >
        <ngx-datatable-column [name]="'admins' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/admin-details', row.id, 'admin-profile']">
              {{ row.fullName }}
            </a>
            <spinner [route]="'/admin-details/' + row.id + '/admin-profile'"></spinner>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'phone' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.phone }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'country' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.country | countryName }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'address' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.address }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'email' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.email }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [maxWidth]="55" [name]="'state' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <mat-slide-toggle (change)="changeStatus(row.id, $event)" [checked]="row.isActive"></mat-slide-toggle>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [headerClass]="'delete-item'"
          [cellClass]="'delete-item'"
          [maxWidth]="100"
          [name]="'delete' | translate"
          [sortable]="false"
          [draggable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button mat-icon-button color="warn" (click)="deleteAdmin(row)">
              <mat-icon svgIcon="ic_delete_24px"></mat-icon>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  } @else {
    <div>
      <mat-spinner></mat-spinner>
    </div>
  }
</div>
