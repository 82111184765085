import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent implements OnInit {
  @Output() userData = new EventEmitter<any>();
  @Output() scopeSubmit = new EventEmitter();

  loginForm: FormGroup;

  constructor(protected route: ActivatedRoute) {}

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
    });
  }

  submitUserData() {
    this.userData.emit(this.loginForm.value);
  }

  submitScope() {
    if (!Object.keys(this.route.snapshot.queryParams).length) {
      this.scopeSubmit.emit('Admin');
    } else {
      switch (this.route.snapshot.queryParams['scope']) {
        case 'super-admin':
          this.scopeSubmit.emit('SuperAdmin');
          break;
        case 'admin':
          this.scopeSubmit.emit('Admin');
          break;
        case 'model-admin':
          this.scopeSubmit.emit('ModelAdmin');
          break;
      }
    }
  }
}
