import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Country, ICity, ICountry, IState } from 'country-state-city';
import { filter, Subscription } from 'rxjs';
import { AuthService } from '../app-services';
import { phoneValidator } from '../validators/phone.validator';
import { Role } from 'app/models/loggin.model';
import { AvailableLanguages } from 'app/models/available-languages.const';
import { Address } from 'app/models/address.model';

@Component({
  selector: 'admin-form',
  templateUrl: './admin-form.component.html',
  styleUrls: ['./admin-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminFormComponent implements OnInit {
  @Output() adminValue = new EventEmitter<any>();
  availableLanguages = AvailableLanguages;
  isActiveMessage = 'admin-enabled';
  ngUnsubscribe: Subscription[] = [];

  countries = Country.getAllCountries();
  states: IState[];
  cities: ICity[];
  selectedCountry: ICountry;
  form: FormGroup;

  constructor(protected authService: AuthService) {}

  ngOnInit() {
    this.initForm();
    this.ngUnsubscribe.push(
      this.form.valueChanges.pipe(filter(() => this.form.valid)).subscribe({
        next: value => {
          this.adminValue.emit(value);
        },
      }),
    );

    this.ngUnsubscribe.push(
      this.form.controls['isActive'].valueChanges.subscribe({
        next: result => {
          result ? (this.isActiveMessage = 'admin-enabled') : (this.isActiveMessage = 'admin-disabled');
        },
      }),
    );
  }

  initForm() {
    this.form = new FormGroup({
      fullName: new FormControl('', Validators.required),
      phone: new FormControl(null, [Validators.required, phoneValidator]),
      country: new FormControl(null, Validators.required),
      address: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email], this.authService.isUniqueEmailValidator(Role.ADMIN)),
      language: new FormControl('', Validators.required),
      isActive: new FormControl(true),
    });
  }
  updateAddressValue(value: Address) {
    this.form.patchValue(value);
  }
}
