import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { AuxiliaryRouteClass } from '../app-interfaces/close-dialog.interface';

import { MatSnackBar } from '@angular/material/snack-bar';
import { HandleErrorsService, ModelAdminService } from '../app-services';
import { ModelAdmin } from 'app/models/model-admin.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'add-model-admin-dialog',
  templateUrl: './add-model-admin-dialog.component.html',
  styleUrls: ['./add-model-admin-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddModelAdminDialogComponent extends AuxiliaryRouteClass {
  newModelAdmin: ModelAdmin;
  loading = new ReplaySubject<boolean>(1);

  constructor(
    public router: Router,
    protected modelAdminService: ModelAdminService,
    protected snackBar: MatSnackBar,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
  ) {
    super(router);
  }

  handleModelAdminInfo(modelAdminInfo: ModelAdmin) {
    this.newModelAdmin = modelAdminInfo;
  }

  addModelAdmin() {
    this.loading.next(true);
    this.modelAdminService.addModelAdmin(this.newModelAdmin).subscribe({
      next: () => {
        this.translateService.get('model-admin-message-success').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
        this.leaveDialog();
      },
      error: err => {
        this.loading.next(false);
        this.handleErrors.handleErrors(err);
      },
    });
  }
}
