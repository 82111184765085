<mat-toolbar>
  <form (ngSubmit)="search()" [formGroup]="filterForm">
    <mat-form-field [subscriptSizing]="'dynamic'">
      <mat-lab translate="brand" />
      <input type="text" matInput formControlName="brandName" [matAutocomplete]="brandAuto" />
    </mat-form-field>

    <mat-form-field [subscriptSizing]="'dynamic'">
      <mat-lab translate="serie" />
      <input type="text" matInput formControlName="serieName" [matAutocomplete]="serieAuto" />
    </mat-form-field>

    <mat-form-field [subscriptSizing]="'dynamic'">
      <mat-lab translate="model" />
      <input type="text" matInput formControlName="modelName" [matAutocomplete]="modelAuto" />
    </mat-form-field>

    <mat-autocomplete #brandAuto="matAutocomplete">
      @for (option of brandList; track option) {
        <mat-option [value]="option.name">
          {{ option.name }}
        </mat-option>
      }
    </mat-autocomplete>

    <mat-autocomplete #serieAuto="matAutocomplete">
      @for (option of serieList; track option) {
        <mat-option [value]="option.name">
          {{ option.name }}
        </mat-option>
      }
    </mat-autocomplete>

    <mat-autocomplete #modelAuto="matAutocomplete">
      @for (option of modelList; track option) {
        <mat-option [value]="option.name">
          {{ option.name }}
        </mat-option>
      }
    </mat-autocomplete>

    <button mat-mini-fab color="accent" type="submit">
      <mat-icon svgIcon="ic_search_24px"></mat-icon>
    </button>
  </form>
  <div class="add-btn">
    <a mat-raised-button color="accent" (click)="navigate()">
      <mat-icon svgIcon="ic_note_add_24px"></mat-icon>
      {{ 'add' | translate }}
    </a>
  </div>
</mat-toolbar>
