import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, ResolveEnd, ResolveStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent implements OnInit {
  @Input() route: any;
  loading: Observable<boolean>;

  constructor(protected router: Router) {}

  ngOnInit() {
    this.loading = this.router.events.pipe(
      filter(event => event instanceof NavigationStart || event instanceof NavigationEnd),
      map((ev: ResolveEnd | ResolveStart) => {
        return ev.url === this.route && ev instanceof NavigationStart ? true : false;
      }),
      startWith(false),
    );
  }
}
